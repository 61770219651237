import { APIHandler, UnauthedAPIHandler } from "../../base";
import { PresignedUrlsRequest } from "../../../common/interfaces/admin/presigned-urls-request.interface";
import { DeletableFiles } from "../../../common/interfaces/admin/deletable-files.interface";
import { ProviderRequest } from "../../../common/interfaces/admin/provider-request.interface";

export const fetchProviders = (companyId: number) =>
    APIHandler.get(`/admin/${companyId}/contentItemProviders`);

export const fetchContentFolders = (companyId: number, providerId: number) =>
    APIHandler.post(`/admin/${companyId}/contentItemProviders/folders`, {
        contentItemProviderId: providerId
    });

export const fetchLearningObjects = (companyId: number, courseId: string, query?: string) =>
    APIHandler.get(`/admin/${companyId}/course/${courseId}/learningObjects${query ?? ""}`);

export const deleteLearningObjects = (companyId: number, courseId: string, loItemIds: string[]) =>
    APIHandler.delete(`/admin/${companyId}/course/${courseId}/learningObjects`, {
        data: { loItemIds }
    });

export const fetchLearningObject = (
    companyId: number,
    courseId: string,
    learningObjectId: string
) => APIHandler.get(`/admin/${companyId}/course/${courseId}/learningObjects/${learningObjectId}`);

export const fetchFiles = (companyId: number, providerId: number, query?: string) =>
    APIHandler.get(`/admin/${companyId}/contentItemProviders/${providerId}/files${query ?? ""}`);

export const fetchPresignedUrls = (
    companyId: number,
    providerId: number,
    data: PresignedUrlsRequest
) => APIHandler.post(`/admin/${companyId}/contentItemProviders/${providerId}/files`, data);

export const uploadFile = (url: string, file: File, options?: object) =>
    UnauthedAPIHandler.put(url, file, options);

export const confirmFile = (companyId: number, providerId: number, fileId: string) =>
    APIHandler.post(
        `/admin/${companyId}/contentItemProviders/${providerId}/files/${fileId}/confirm`
    );

export const cancelFile = (companyId: number, providerId: number, fileId: string) =>
    APIHandler.post(
        `/admin/${companyId}/contentItemProviders/${providerId}/files/${fileId}/cancel`
    );

export const deleteFiles = (companyId: number, providerId: number, fileData: DeletableFiles) =>
    APIHandler.delete(`/admin/${companyId}/contentItemProviders/${providerId}/files`, {
        data: fileData
    });

export const addContentItemProvider = (companyId: number, data: ProviderRequest) =>
    APIHandler.post(`/admin/${companyId}/contentItemProviders`, data);

export const deleteContentItemProvider = (companyId: number, contentItemProviderId: number) =>
    APIHandler.delete(`/admin/${companyId}/contentItemProviders/${contentItemProviderId}`);
