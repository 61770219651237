export const setDataToLocalStorage = (dataKey: string, dataToSet: any) => {
    try {
        if (dataToSet !== null && typeof dataToSet === "object") {
            localStorage.setItem(dataKey, JSON.stringify(dataToSet));
        } else {
            localStorage.setItem(dataKey, dataToSet);
        }
    } catch (error) {
        console.log(error);
    }
};

export const removeDataToLocalStorage = (dataKey: string) => {
    try {
        return localStorage.removeItem(dataKey);
    } catch (error) {
        console.log(error);
    }
};
