import { useEffect, useRef } from "react";
import lottie from "lottie-web";

import ListeningAnimation from "../../assets/listeningAnimation.json";

export const ListeningIndicator = ({ size, className }: { size: number; className?: string }) => {
    const sizeProp = `${size}px`;
    const lottieContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!lottieContainer.current) return;

        const instance = lottie.loadAnimation({
            container: lottieContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: ListeningAnimation
        });

        return () => instance.destroy();
    }, []);

    return (
        <div
            ref={lottieContainer}
            className={className}
            style={{ position: "absolute", width: sizeProp, height: sizeProp }}
        />
    );
};
