import { Fragment, useState, useCallback, MouseEvent } from "react";
import { Button, Menu } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import "./style.scss";
import { ReactComponent as CheckboxIcon } from "../../assets/checkboxIcon.svg";
import { ReactComponent as CheckboxCheckedIcon } from "../../assets/checkboxCheckedIcon.svg";
import { MenuItem } from "../../common/interfaces/comps/menu-item.interface";

export interface Action {
    name: string;
    action: () => void;
}

export const ActionMenu = ({
    label,
    actions,
    disabled = false,
    rightPosition = false,
    minWidth
}: {
    label: string;
    actions: Action[];
    disabled?: boolean;
    rightPosition?: boolean;
    minWidth?: number;
}) => {
    const menuStyles = minWidth ? { minWidth: `${minWidth}px` } : {};
    const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
    const menuOpen = Boolean(menuAnchor);

    const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleMenuItem = (action: () => void) => {
        action();
        handleMenuClose();
    };

    return (
        <Fragment>
            <Button
                className="menu-button"
                variant="text"
                endIcon={
                    <SvgIcon
                        component={menuOpen ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
                        inheritViewBox
                    />
                }
                disabled={disabled}
                onClick={handleMenuClick}
            >
                <span className="menu-button__label">{label}</span>
            </Button>
            <Menu
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        className: "dropdown-menu",
                        sx: menuStyles
                    }
                }}
                transformOrigin={{ horizontal: rightPosition ? "right" : "left", vertical: "top" }}
                anchorOrigin={{ horizontal: rightPosition ? "right" : "left", vertical: "bottom" }}
            >
                {actions.map(action => (
                    <div
                        key={action.name}
                        className="dropdown-menu-item"
                        onClick={() => handleMenuItem(action.action)}
                    >
                        {action.name}
                    </div>
                ))}
            </Menu>
        </Fragment>
    );
};

export const CheckboxMenuFilter = ({
    label,
    list,
    checkedList,
    setCheckedList,
    showCounter = false,
    rightPosition = false,
    minWidth
}: {
    label: string;
    list: string[];
    checkedList: string[];
    setCheckedList: (arg1: string[]) => void;
    showCounter?: boolean;
    rightPosition?: boolean;
    minWidth?: number;
}) => {
    const menuStyles = minWidth ? { minWidth: `${minWidth}px` } : {};
    const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
    const menuOpen = Boolean(menuAnchor);

    const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleChangeCheckedItem = useCallback(
        (item: string) => {
            const newList = [...checkedList];
            const index = newList.indexOf(item);
            index !== -1 ? newList.splice(index, 1) : newList.push(item);
            setCheckedList(newList);
        },
        [checkedList, setCheckedList]
    );

    return (
        <Fragment>
            <Button
                className="menu-button"
                variant="text"
                endIcon={
                    <SvgIcon
                        component={menuOpen ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
                        inheritViewBox
                    />
                }
                onClick={handleMenuClick}
            >
                <span className="menu-button__label">{label}</span>
                {showCounter && <div className="menu-button__counter">{checkedList.length}</div>}
            </Button>
            <Menu
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        className: "dropdown-menu",
                        sx: menuStyles
                    }
                }}
                transformOrigin={{ horizontal: rightPosition ? "right" : "left", vertical: "top" }}
                anchorOrigin={{ horizontal: rightPosition ? "right" : "left", vertical: "bottom" }}
            >
                {list.map(item => (
                    <div key={item} className="dropdown-menu-item__checkbox">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkedList.includes(item)}
                                    onChange={() => handleChangeCheckedItem(item)}
                                    icon={<CheckboxIcon />}
                                    checkedIcon={<CheckboxCheckedIcon />}
                                />
                            }
                            label={item}
                        />
                    </div>
                ))}
            </Menu>
        </Fragment>
    );
};

export const CheckboxMenuFilterT = <T,>({
    label,
    list,
    checkedList,
    setCheckedList,
    showCounter = false,
    rightPosition = false,
    minWidth
}: {
    label: string;
    list: MenuItem<T>[];
    checkedList: MenuItem<T>[];
    setCheckedList: (arg1: MenuItem<T>[]) => void;
    showCounter?: boolean;
    rightPosition?: boolean;
    minWidth?: number;
}) => {
    const menuStyles = minWidth ? { minWidth: `${minWidth}px` } : {};
    const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
    const menuOpen = Boolean(menuAnchor);

    const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleChangeCheckedItem = useCallback(
        (item: MenuItem<T>) => {
            const newList = [...checkedList];
            const index = newList.indexOf(item);
            index !== -1 ? newList.splice(index, 1) : newList.push(item);
            setCheckedList(newList);
        },
        [checkedList, setCheckedList]
    );

    return (
        <Fragment>
            <Button
                className="menu-button"
                variant="text"
                endIcon={
                    <SvgIcon
                        component={menuOpen ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
                        inheritViewBox
                    />
                }
                onClick={handleMenuClick}
            >
                <span className="menu-button__label">{label}</span>
                {showCounter && <div className="menu-button__counter">{checkedList.length}</div>}
            </Button>
            <Menu
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        className: "dropdown-menu",
                        sx: menuStyles
                    }
                }}
                transformOrigin={{ horizontal: rightPosition ? "right" : "left", vertical: "top" }}
                anchorOrigin={{ horizontal: rightPosition ? "right" : "left", vertical: "bottom" }}
            >
                {list.map(item => (
                    <div key={item.key} className="dropdown-menu-item__checkbox">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkedList.includes(item)}
                                    onChange={() => handleChangeCheckedItem(item)}
                                    icon={<CheckboxIcon />}
                                    checkedIcon={<CheckboxCheckedIcon />}
                                />
                            }
                            label={item.key}
                        />
                    </div>
                ))}
            </Menu>
        </Fragment>
    );
};

export const SelectionFilter = ({
    list,
    selectedItem,
    setSelectedItem,
    rightPosition = false,
    minWidth
}: {
    list: string[];
    selectedItem: string;
    setSelectedItem: (arg1: string) => void;
    rightPosition?: boolean;
    minWidth?: number;
}) => {
    const menuStyles = minWidth ? { minWidth: `${minWidth}px` } : {};
    const [selectAnchor, setSelectAnchor] = useState<Element | null>(null);
    const selectOpen = Boolean(selectAnchor);

    const handleSelectClick = (event: MouseEvent<HTMLElement>) => {
        setSelectAnchor(event.currentTarget);
    };

    const handleSelectClose = () => {
        setSelectAnchor(null);
    };

    const handleSelectItem = (item: string) => {
        setSelectedItem(item);
        handleSelectClose();
    };

    return (
        <Fragment>
            <Button
                className="menu-button"
                variant="text"
                endIcon={
                    <SvgIcon
                        component={selectOpen ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
                        inheritViewBox
                    />
                }
                onClick={handleSelectClick}
            >
                <span className="menu-button__label">{selectedItem}</span>
            </Button>
            <Menu
                anchorEl={selectAnchor}
                open={selectOpen}
                onClose={handleSelectClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        className: "dropdown-menu",
                        sx: menuStyles
                    }
                }}
                transformOrigin={{ horizontal: rightPosition ? "right" : "left", vertical: "top" }}
                anchorOrigin={{ horizontal: rightPosition ? "right" : "left", vertical: "bottom" }}
            >
                {list.map(item => (
                    <div
                        key={item}
                        className={`dropdown-menu-item${item === selectedItem ? " dropdown-menu-item__selected" : ""}`}
                        onClick={() => handleSelectItem(item)}
                    >
                        {item}
                    </div>
                ))}
            </Menu>
        </Fragment>
    );
};
