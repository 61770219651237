import { useState, ChangeEvent } from "react";
import { Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import "./style.scss";

export const ChatMessageReviewBackdrop = ({
    onClick,
    open
}: {
    onClick: React.MouseEventHandler<HTMLElement>;
    open: boolean;
}) => {
    return (
        <div
            onClick={onClick}
            className={`chat-message-review-backdrop chat-message-review-backdrop--${open ? "visible" : "hidden"}`}
        ></div>
    );
};

export const ChatMessageReview = ({
    closeAction,
    submitAction
}: {
    closeAction: () => void;
    submitAction: (arg1: string) => void;
}) => {
    const [comment, setComment] = useState<string>("");

    const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value);
    };

    const handleCloseAction = () => {
        closeAction();
    };

    const handleSubmitAction = () => {
        submitAction(comment);
        closeAction();
    };

    return (
        <div className="chat-message-review">
            <IconButton className="chat-message-review-button" onClick={handleCloseAction}>
                <CloseIcon />
            </IconButton>
            <div className="chat-message-review-title">Why wasn&apos;t this helpful?</div>
            <div className="chat-message-review-content">
                <textarea
                    onChange={handleTextChange}
                    placeholder="Add more details here"
                    value={comment}
                />
            </div>
            <div className="chat-message-review-action">
                <Button variant="outlined" onClick={handleSubmitAction} disabled={!comment}>
                    Submit
                </Button>
            </div>
        </div>
    );
};
