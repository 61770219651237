import { useEffect } from "react";

import "./App.scss";
import { getAppRoutes, getAuthContainer } from "./common/utilities/routes/routes.util";
import { ThemeContainer } from "./common/containers/theme/theme.container";
import { ToastProvider } from "./components/toast/toast.comp";
import { Theme } from "./common/enums/theme.enum";

function App() {
    const AppRoutes = getAppRoutes();
    const AuthContainer = getAuthContainer();
    const { theme } = ThemeContainer.useContainer();

    useEffect(() => {
        document.documentElement.setAttribute("data-theme", theme);
        document.title = theme === Theme.comet ? "Tiva Comet" : "Tiva Knowledge Base";

        const favicon = document.querySelector("link[rel~='icon']");
        if (favicon && favicon instanceof HTMLLinkElement) {
            favicon.href = theme === Theme.comet ? "favicon-comet.ico" : "favicon-tiva.ico";
        }
    }, [theme]);

    return (
        <AuthContainer.Provider>
            <ToastProvider>
                <AppRoutes />
            </ToastProvider>
        </AuthContainer.Provider>
    );
}

export default App;
