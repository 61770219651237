export enum UploadableFileStatus {
    start,
    loading,
    complete,
    cancel,
    error,
    unsupported,
    tooLarge
}

export interface UploadableFile {
    uid: string;
    url?: string;
    header?: object;
    fileData: File;
    loadingPercent: number;
    loadingStatus: UploadableFileStatus;
    controller?: AbortController;
}
