import { APIHandler } from "../../base";
import {
    RRSaveRequest,
    RRUpdateRequest
} from "../../../common/interfaces/admin/rr-request.interface";

export const fetchRecognitionResult = (companyId: number, uid: string) =>
    APIHandler.get(`/admin/${companyId}/recognitionResult/${uid}`);

export const updateRecognitionResult = (companyId: number, uid: string, data: RRUpdateRequest) =>
    APIHandler.put(`/admin/${companyId}/recognitionResult/${uid}`, data);

export const saveRecognitionResult = (companyId: number, uid: string, data: RRSaveRequest) =>
    APIHandler.put(`/admin/${companyId}/recognitionResult/${uid}/save`, data);

export const publishRecognitionResult = (companyId: number, uid: string, isPublish: boolean) =>
    APIHandler.patch(`/admin/${companyId}/recognitionResult/${uid}/publish`, { isPublish });
