import { useRef, useState, useEffect, useCallback, ChangeEvent, KeyboardEvent } from "react";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";

import "./style.scss";
import { ReactComponent as ArrowUpIcon } from "../../assets/arrowUpIcon.svg";
import useAutosizeTextArea from "../../common/utilities/autosize-textarea/autosize-textarea.util";
import { RecognitionButton, RecognitionMode } from "../recognition-button/recognition-button.comp";
import { ToastContainer } from "../toast/toast.comp";
import { isMobileDevice } from "../../common/utilities/platform/platform.util";

const Footer = ({ setNewMessage }: { setNewMessage?: (arg1: string) => void }) => {
    const [question, setQuestion] = useState("");
    const [placeholder, setPlaceholder] = useState("Type something");
    const [speechListening, setSpeechListening] = useState(false);
    const [speechTranscript, setSpeechTranscript] = useState("");
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const { showToast } = ToastContainer.useContainer();

    useAutosizeTextArea(textAreaRef.current, question);

    useEffect(() => {
        if (speechListening) {
            setQuestion(speechTranscript);
        }
    }, [speechListening, speechTranscript]);

    useEffect(() => {
        setPlaceholder(speechListening ? "I'm listening..." : "Type something");
    }, [speechListening]);

    const handleTextChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        const val = evt.target?.value;
        setQuestion(val);
    };

    const handleTextQuestion = () => {
        if (question) {
            handleQuestion(question);
        }
    };

    const handleQuestion = useCallback(
        (recognizedQuestion: string) => {
            setQuestion("");
            if (setNewMessage && recognizedQuestion) {
                setNewMessage(recognizedQuestion);
            }
        },
        [setNewMessage]
    );

    const onEnterPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.code === "Enter" && e.shiftKey === false) {
            e.preventDefault();
            handleTextQuestion();
        }
    };

    const handleErrorMessage = (message: string) => {
        showToast(message);
    };

    return (
        <div
            className={`footer ${isMobileDevice() ? "footer-mobile " : ""} ${speechListening ? "footer-listening" : ""}`}
        >
            <div className="footer-text">
                <div className="footer-text-input">
                    <textarea
                        onKeyDown={onEnterPress}
                        onChange={handleTextChange}
                        placeholder={placeholder}
                        ref={textAreaRef}
                        rows={1}
                        value={question}
                        disabled={speechListening}
                    />
                    {!!question && !speechListening && (
                        <IconButton
                            className="footer-text-input__button"
                            onClick={handleTextQuestion}
                        >
                            <SvgIcon component={ArrowUpIcon} inheritViewBox />
                        </IconButton>
                    )}
                    {(!question || speechListening) && (
                        <RecognitionButton
                            size={isMobileDevice() ? 90 : 128}
                            className="footer-mic"
                            onRecognized={handleQuestion}
                            setSpeechListening={setSpeechListening}
                            setSpeechTranscript={setSpeechTranscript}
                            handleErrorMessage={handleErrorMessage}
                            mode={RecognitionMode.COMBINED}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Footer;
