import { IconButton } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";

import "./style.scss";
import { ReactComponent as CrossIcon } from "../../assets/crossIcon.svg";

export enum ProgressBarType {
    start,
    loading,
    success,
    cancel,
    error,
    warning
}

export const ProgressBar = ({
    height,
    type = ProgressBarType.start,
    title = "",
    successTitle = "Uploaded",
    cancelTitle = "Upload canceled",
    errorTitle = "Error Uploading",
    warningTitle = "Something went wrong",
    percentValue,
    showPercent = true,
    showClose = false,
    handleClose,
    className = ""
}: {
    height: number;
    type?: ProgressBarType;
    title?: string;
    successTitle?: string;
    cancelTitle?: string;
    errorTitle?: string;
    warningTitle?: string;
    percentValue: number;
    showPercent?: boolean;
    showClose?: boolean;
    handleClose?: () => void;
    className?: string;
}) => {
    const styleName = (type: ProgressBarType) => {
        switch (type) {
            case ProgressBarType.success:
                return "complete";
            case ProgressBarType.error:
                return "error";
            case ProgressBarType.warning:
                return "warning";
            default:
                return "default";
        }
    };

    const getTitle = (type: ProgressBarType) => {
        switch (type) {
            case ProgressBarType.success:
                return successTitle;
            case ProgressBarType.cancel:
                return cancelTitle;
            case ProgressBarType.error:
                return errorTitle;
            case ProgressBarType.warning:
                return warningTitle;
            default:
                return title;
        }
    };

    const handleCancelClick = () => {
        if (handleClose) {
            handleClose();
        }
    };

    return (
        <div
            className={`progress-bar progress-bar--${styleName(type)} ${className}`}
            style={{ height: height, borderRadius: height / 2 }}
        >
            {type === ProgressBarType.loading && (
                <div
                    className="progress-bar-loading-level"
                    style={{ width: `${percentValue}%` }}
                ></div>
            )}
            {type !== ProgressBarType.loading && (
                <span className="progress-bar-title">{getTitle(type)}</span>
            )}
            {showPercent &&
                (type === ProgressBarType.loading ||
                    (type === ProgressBarType.start && !title)) && (
                    <span className="progress-bar-percent">{percentValue}%</span>
                )}
            {showClose &&
                (type === ProgressBarType.start ||
                    type === ProgressBarType.loading ||
                    type === ProgressBarType.warning) && (
                    <IconButton className="progress-bar-cancel" onClick={handleCancelClick}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                )}
        </div>
    );
};
