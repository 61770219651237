export const formatDate = (date: Date, format: string) => {
    const padZero = (value: number) => (value < 10 ? `0${value}` : `${value}`);
    const parts = {
        yyyy: date.getFullYear().toString(),
        MM: padZero(date.getMonth() + 1),
        dd: padZero(date.getDate()),
        HH: padZero(date.getHours()),
        hh: padZero(date.getHours() > 12 ? date.getHours() - 12 : date.getHours()),
        mm: padZero(date.getMinutes()),
        ss: padZero(date.getSeconds()),
        tt: date.getHours() < 12 ? "AM" : "PM"
    };

    return format.replace(/yyyy|MM|dd|HH|hh|mm|ss|tt/g, match => (parts as any)[match]);
};

export const formatStringDate = (date: string, format: string) => {
    return formatDate(new Date(Date.parse(date)), format);
};
