import { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import "./style.scss";
import { FirebaseErrorCode } from "../../../../common/enums/firebase-error-code";
import { FirebaseAuthContainer } from "../../../../common/containers/auth/firebase-auth-container.util";
import { emailRegex } from "../../../../common/utilities/constants/constants";
import { useTimer } from "../../../../common/utilities/timer/use-timer";
import { AuthTemplate } from "../../../../components/admin/auth-template/auth-template.comp";
import { Loader } from "../../../../components/loader/loader.comp";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const { sendPasswordReset } = FirebaseAuthContainer.useContainer();
    const [login, setLogin] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const [resetSent, setResetSent] = useState<boolean>(false);
    const [sendResetnWaitingTime, setSendResetWaitingTime] = useState<number>();
    const { timerStarted, timerTimeLeft, startTimer, stopTimer } = useTimer();

    useEffect(() => {
        return () => {
            stopTimer();
        };
    }, [stopTimer]);

    useEffect(() => {
        setSendResetWaitingTime(timerStarted ? timerTimeLeft : undefined);
    }, [timerStarted, timerTimeLeft]);

    const handleCancelClick = () => {
        navigate("/login");
    };

    const handleLoginChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const val = evt.target?.value;
        setLogin(val ?? "");
        setErrorMessage("");
        setIsInvalid(false);
    };

    const handleSendAgain = () => {
        if (!login) return;

        sendPasswordReset(login).then(() => {
            startTimer(60);
        });
    };

    const handleResetClick = () => {
        if (!login) return;

        if (!login.match(emailRegex)) {
            setIsInvalid(true);
            setErrorMessage("Invalid email format");
            return;
        }

        setIsLoading(true);
        sendPasswordReset(login)
            .then(() => {
                startTimer(60);
                setResetSent(true);
            })
            .catch(error => {
                switch (error.code as FirebaseErrorCode) {
                    case FirebaseErrorCode.emailAlreadyInUse:
                        setIsInvalid(true);
                        setErrorMessage("This email address is already in use");
                        return;
                    default:
                        setErrorMessage("Something went wrong. Please try again later");
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <AuthTemplate
            height={316}
            title="Admin Panel"
            description="Welcome to TIVA Knowledge Base"
            showClose={true}
            handleClose={handleCancelClick}
        >
            <div className="forgot-password">
                <div className="forgot-password-title">
                    {!resetSent ? "Forgot password?" : "Confirm email"}
                </div>
                <div className="forgot-password-description">
                    {!resetSent ? (
                        <>
                            <div className="forgot-password-description-line">
                                <span className="forgot-password-description-line__text">
                                    Enter your email to reset password.
                                </span>
                            </div>
                            <div className="forgot-password-description-line">
                                <span className="forgot-password-description-line__text">
                                    You will get verification email to continue.
                                </span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="forgot-password-description-line">
                                <span className="forgot-password-description-line__text">
                                    We have sent the email to
                                </span>
                            </div>
                            <div className="forgot-password-description-line">
                                <span className="forgot-password-description-line__email">
                                    {login}
                                </span>
                                <span className="forgot-password-description-line__text">
                                    . Click on the link
                                </span>
                            </div>
                            <div className="forgot-password-description-line">
                                <span className="forgot-password-description-line__text">
                                    inside of the email to confirm it
                                </span>
                            </div>
                        </>
                    )}
                </div>
                {!resetSent ? (
                    <div className="forgot-password-content">
                        <div className="forgot-password-field">
                            <input
                                tabIndex={1}
                                type="text"
                                placeholder="Your Email"
                                onChange={handleLoginChange}
                                value={login}
                            />
                        </div>
                        {!isInvalid ? (
                            <div className="forgot-password-login">
                                <span className="forgot-password-login__text">
                                    If you&apos;ve remembered your password, you can
                                </span>
                                <a href="/login" className="forgot-password-login__link">
                                    Log in
                                </a>
                            </div>
                        ) : (
                            <div className="forgot-password-message">
                                <span>{errorMessage}</span>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="forgot-password-content">
                        <div className="forgot-password-content-line">
                            <span className="forgot-password-content-line__text">
                                Didn&apos;t get confirmation email? Check spam.
                            </span>
                        </div>
                        <div className="forgot-password-content-line">
                            <span className="forgot-password-content-line__text">or</span>
                            <Button
                                className="forgot-password-content-line__link"
                                onClick={handleSendAgain}
                                disabled={!!sendResetnWaitingTime}
                            >
                                Send again
                            </Button>
                            <span className="forgot-password-content-line__text">
                                {sendResetnWaitingTime ? `(in ${sendResetnWaitingTime}s)` : ""}
                            </span>
                        </div>
                    </div>
                )}
                <div className="forgot-password-action">
                    {!resetSent && (
                        <Button
                            variant="text"
                            onClick={handleResetClick}
                            disabled={!login || isLoading || isInvalid}
                        >
                            {!isLoading ? (
                                <span>Continue</span>
                            ) : (
                                <Loader size={24} borderWidth={4} />
                            )}
                        </Button>
                    )}
                </div>
            </div>
        </AuthTemplate>
    );
};

export default ForgotPassword;
