import { FC, SVGProps, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";

import "./style.scss";
import { ReactComponent as HelpIcon } from "../../../assets/helpIcon.svg";
import { ReactComponent as UploadIcon } from "../../../assets/cloudUploadIcon.svg";
import { ReactComponent as TivaIcon } from "../../../assets/tivaIcon.svg";
import { ReactComponent as RocketIcon } from "../../../assets/rocketIcon.svg";
import { FirebaseAuthContainer } from "../../../common/containers/auth/firebase-auth-container.util";

const HomeButton = ({
    className = "",
    icon,
    title,
    description,
    disabled = false,
    disabledText,
    handleClick
}: {
    className?: string;
    icon: FC<SVGProps<SVGSVGElement>>;
    title: string;
    description: string;
    disabled?: boolean;
    disabledText?: string;
    handleClick: () => void;
}) => {
    return (
        <Button
            className={`home-button ${className}`}
            variant="outlined"
            onClick={handleClick}
            disabled={disabled}
        >
            <SvgIcon className="home-button-icon" component={icon} inheritViewBox />
            <div className="home-button-text">
                <span className="home-button-text__title">{title}</span>
                <span className="home-button-text__description">{description}</span>
                {disabled && <span className="home-button-text__info">{disabledText}</span>}
            </div>
        </Button>
    );
};

const TrialHomeView = () => {
    const navigate = useNavigate();
    const { knowledgeBases } = FirebaseAuthContainer.useContainer();
    const [knowledgeBaseAvailable, setKnowledgeBaseAvailable] = useState<boolean>(false);

    useEffect(() => {
        if (!knowledgeBases.length) return;

        // TODO: get from page path params. User should choose it on Knowledge Bases page
        const knowledgeBase = knowledgeBases[0];
        setKnowledgeBaseAvailable(knowledgeBase.status === "active");
    }, [knowledgeBases]);

    const handleOpenUploadFiles = () => {
        navigate("/uploaded-files");
    };

    const handleUpgradeToFullVersion = () => {
        navigate("/feedback");
    };

    const handleTryKnowledgeBase = () => {
        const host = window.location.host.replace("admin.", "");
        window.open(`${window.location.protocol}//${host}`, "_self");
    };

    return (
        <div className="home-view">
            <div className="home-content">
                <span className="home-content-title">Welcome!</span>
                <span className="home-content-description">
                    This is TIVA Knowledge Base sandbox, where you can get a glimpse of how it works
                </span>
                <div className="home-content-buttons">
                    <HomeButton
                        className="home-button--upload"
                        icon={UploadIcon}
                        title="Upload files"
                        description="Knowledge Base will learn and will be able to assist"
                        handleClick={handleOpenUploadFiles}
                    />
                    <HomeButton
                        className="home-button--tiva"
                        icon={TivaIcon}
                        title="Try your Knowledge Base"
                        description="Ask any questions regarding uploaded documents"
                        disabledText="It may take from 5 min. up to an hour for AI to process your files."
                        disabled={!knowledgeBaseAvailable}
                        handleClick={handleTryKnowledgeBase}
                    />
                    <HomeButton
                        className="home-button--gradient"
                        icon={RocketIcon}
                        title="Upgrade to full version"
                        description="Contact our team to launch your Knowledge Base"
                        handleClick={handleUpgradeToFullVersion}
                    />
                </div>
            </div>
            <div className="home-footer">
                <div className="home-footer-help">
                    <SvgIcon
                        className="home-footer-help__icon"
                        component={HelpIcon}
                        inheritViewBox
                    />
                    <span className="home-footer-help__text">Get Help & Assistance</span>
                </div>
            </div>
        </div>
    );
};

export default TrialHomeView;
