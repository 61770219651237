import { useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";

import "./style.scss";
import { openCenteredPopup } from "../../common/utilities/popup/popup-open.util";
import { ReactComponent as GoogleIcon } from "../../assets/googleIcon.svg";

const GoogleSignIn = () => {
    const [searchParams] = useSearchParams();
    const invite = searchParams.get("invite");
    const from =
        window.location.hostname === "localhost"
            ? (invite ?? "debug")
            : (invite ?? process.env.REACT_APP_ENVIRONMENT);
    const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/google/${from}`;

    function handleTargetPopup() {
        openCenteredPopup("", "Popup_Window", 500, 550);
    }

    return (
        <div className="signin-page">
            <form
                className="signin-content"
                method="post"
                target="Popup_Window"
                action={url}
                onSubmit={handleTargetPopup}
            >
                <Button
                    className="button__google"
                    variant="outlined"
                    type="submit"
                    startIcon={<SvgIcon component={GoogleIcon} inheritViewBox />}
                >
                    Sign In with Google
                </Button>
            </form>
        </div>
    );
};

export default GoogleSignIn;
