import "./style.scss";
import FolderIcon from "../../../assets/folderIcon.svg";
import { Folder } from "../../../common/interfaces/admin/folder.interface";

const CoursesTree = ({
    className,
    list,
    selectedCourse,
    selectCourse
}: {
    className?: string;
    list: Folder[];
    selectedCourse?: string;
    selectCourse: (arg1: string) => void;
}) => {
    const handleCourseClick = (courseId: string) => {
        selectCourse(courseId);
    };

    return (
        <div className={`course-tree ${className ?? ""}`}>
            {list.map(course => (
                <div
                    key={course.courseId}
                    className="course-tree-course"
                    onClick={() => handleCourseClick(course.courseId)}
                >
                    <div
                        className={`course-tree-course__name${selectedCourse === course.courseId ? " course-tree-course__name-selected" : ""}`}
                    >
                        <img src={FolderIcon} alt="" />
                        <span>{course.name}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CoursesTree;
