const addEaseOutElastic = function (el: HTMLElement, factor = 1) {
    const defaultTransition = getComputedStyle(el).transition;
    const durationMouseMove = 100;
    const durationMouseEnter = 125;
    const durationMouseLeave = 300;
    let duration = 0;

    const screenTop = (el: HTMLElement, initialTop?: number): number => {
        const top = initialTop ? initialTop + el.offsetTop : el.offsetTop;
        if (el.offsetParent) {
            return screenTop(el.offsetParent as HTMLElement, top);
        }
        return top;
    };

    const screenLeft = (el: HTMLElement, initialLeft?: number): number => {
        const left = initialLeft ? initialLeft + el.offsetLeft : el.offsetLeft;
        if (el.offsetParent) {
            return screenLeft(el.offsetParent as HTMLElement, left);
        }
        return left;
    };

    const moveItem = (e: MouseEvent) => {
        const cursor = {
            x: e.clientX,
            y: e.clientY
        };

        const width = el.offsetWidth;
        const height = el.offsetHeight;

        const elPos = {
            x: screenLeft(el) + width / 2,
            y: screenTop(el) + height / 2
        };

        const x = cursor.x - elPos.x;
        const y = cursor.y - elPos.y;

        el.style.transform = `translate(${x / factor}px, ${y / factor}px)`;
    };

    const onMouseMove = (e: MouseEvent) => {
        moveItem(e);

        if (duration > 0) {
            duration -= 4;
            el.style.transition =
                duration > 0
                    ? `${defaultTransition}, transform ${duration}ms ease`
                    : defaultTransition;
        }
    };

    const onMouseEnter = (e: MouseEvent) => {
        el.style.transition = `${defaultTransition}, transform ${durationMouseEnter}ms ease-in`;
        moveItem(e);
        setTimeout(() => {
            duration = durationMouseMove;
            el.style.transition = `${defaultTransition}, transform ${duration}ms ease`;
            el.addEventListener("mousemove", onMouseMove);
        }, durationMouseEnter);
    };

    const onMouseLeave = () => {
        el.removeEventListener("mousemove", onMouseMove);
        el.style.transition = `${defaultTransition}, transform ${durationMouseLeave}ms cubic-bezier(0.4, 2, 0.2, 2)`;
        el.style.transform = "translate(0, 0)";
    };

    el.addEventListener("mouseenter", onMouseEnter);
    el.addEventListener("mouseleave", onMouseLeave);
};

export { addEaseOutElastic };
