import { useEffect, useRef, useState } from "react";

export function useTokenExpiration(onTokenRefreshRequired: () => void) {
    const clearAutomaticRefresh = useRef<number>();
    const [tokenExpiration, setTokenExpiration] = useState<number>();

    useEffect(() => {
        if (tokenExpiration) {
            clearAutomaticRefresh.current = window.setInterval(() => {
                console.log("~~~ run token refresh ~~~");
                onTokenRefreshRequired();
            }, tokenExpiration);
        }

        return () => {
            clearTokenRefreshInterval();
        };
    }, [onTokenRefreshRequired, tokenExpiration]);

    const clearAutomaticTokenRefresh = () => {
        clearTokenRefreshInterval();
        setTokenExpiration(undefined);
    };

    function clearTokenRefreshInterval() {
        window.clearInterval(clearAutomaticRefresh.current);
    }

    return {
        clearAutomaticTokenRefresh,
        setTokenExpiration
    };
}
