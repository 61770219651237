import { useState } from "react";
import { createContainer } from "unstated-next";

import { Theme } from "../../enums/theme.enum";
import { getAppTheme } from "../../utilities/routes/routes.util";

function useTheme() {
    const [theme, setTheme] = useState<Theme>(getAppTheme());

    return {
        theme,
        setTheme
    };
}

export const ThemeContainer = createContainer(useTheme);
