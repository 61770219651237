import { useState, useEffect, useRef } from "react";
import { createContainer } from "unstated-next";

import "./style.scss";

interface ToastParams {
    message: string;
    duration: number;
}

export const ToastContainer = createContainer(function () {
    const [toastParams, setToastParams] = useState<ToastParams | undefined>();

    const showToast = (message: string, duration = 2000) => {
        setToastParams({ message, duration });
    };

    return {
        toastParams,
        setToastParams,
        showToast
    };
});

export const ToastProvider = ToastContainer.Provider;

const Toast = () => {
    const { toastParams, setToastParams } = ToastContainer.useContainer();
    const [classes, setClasses] = useState("");
    const messageTimeout = useRef<number>();

    useEffect(() => {
        if (toastParams) {
            setClasses("is-visible");
            messageTimeout.current = window.setTimeout(() => {
                setClasses("is-hidden");
                setTimeout(() => {
                    setToastParams(undefined);
                }, 300);
            }, toastParams.duration);
        }

        return () => {
            window.clearTimeout(messageTimeout.current);
        };
    }, [toastParams, setToastParams]);

    return (
        <div className="toasts-container">
            {toastParams?.message && (
                <div className={`toast ${classes}`}>{toastParams.message}</div>
            )}
        </div>
    );
};

export default Toast;
