import { useState, MouseEvent, FunctionComponent, useEffect, Fragment } from "react";
import { useLocation, useNavigate, useParams, Params, Link } from "react-router-dom";
import { IconButton, SvgIcon, Menu } from "@mui/material";

import "./style.scss";
import { FirebaseAuthContainer } from "../../../common/containers/auth/firebase-auth-container.util";
import { ReactComponent as MenuIcon } from "../../../assets/menuIcon.svg";
import { ReactComponent as FolderIcon } from "../../../assets/folderIcon.svg";
import { ReactComponent as DriveIcon } from "../../../assets/driveIcon.svg";
import { ReactComponent as CloudIcon } from "../../../assets/cloudIcon.svg";
import { ReactComponent as CloudUploadIcon } from "../../../assets/cloudUploadIcon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/plusIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/editIcon.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/refreshIcon.svg";
import {
    forceUpdate,
    subscribeForceUpdate
} from "../../../api/admin/knowledge-base/endpoints-referrers";
import { ProviderType } from "../../../common/enums/provider-type.enum";
import { Provider } from "../../../common/interfaces/admin/provider.interface";
import { ForceUpdateMessage } from "../../../common/interfaces/admin/force-update-message.interface";
import ConfirmationPopup from "../../confirmation-popup/confirmation-popup.comp";

interface MenuItem {
    icon: FunctionComponent<React.SVGProps<SVGSVGElement>>;
    title: string;
    link: string;
}

const MenuLink = ({
    item,
    isSelected = false,
    handleClick
}: {
    item: MenuItem;
    isSelected?: boolean;
    handleClick: () => void;
}) => {
    return (
        <Link
            className={`admin-menu-action${isSelected ? " admin-menu-action__selected" : ""}`}
            onClick={handleClick}
            to={item.link}
        >
            <SvgIcon className="admin-menu-action__icon" component={item.icon} inheritViewBox />
            <span className="admin-menu-action__title">{item.title}</span>
        </Link>
    );
};

const MenuPanel = ({
    providers,
    showPromptTuning = false,
    defaultTitle = "",
    className
}: {
    providers: Provider[];
    showPromptTuning?: boolean;
    defaultTitle?: string;
    className?: string;
}) => {
    const providerPathname = "knowledge-base";
    const location = useLocation();
    const navigate = useNavigate();
    const { providerId } = useParams<Params<string>>();
    const { user, knowledgeBases } = FirebaseAuthContainer.useContainer();
    const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
    const menuOpen = Boolean(menuAnchor);
    const [title, setTitle] = useState<string>("");
    const [currentMenuItem, setCurrentMenuItem] = useState<MenuItem>();
    const [providerList, setProviderList] = useState<MenuItem[]>([]);
    const [actionPageList] = useState<MenuItem[]>([
        { icon: CloudUploadIcon, title: "Upload Manually", link: "/files-upload" },
        { icon: PlusIcon, title: "Link Google Drive", link: "/link-google-drive" }
    ]);
    const [forceSyncConfirmationOpen, setForceSyncConfirmationOpen] = useState<boolean>(false);
    const forceSyncDescription = `Use forced synchronization only if you urgently need to update the knowledge base.
    Otherwise, automatic sync will still occur as scheduled.`;

    useEffect(() => {
        if (!user) return;

        setProviderList(
            providers
                .sort((a, b) => a.id - b.id)
                .map(provider => ({
                    icon:
                        provider.name === ProviderType.googleDrive
                            ? DriveIcon
                            : provider.name === ProviderType.fileUploader
                              ? CloudIcon
                              : FolderIcon,
                    title: provider.displayName,
                    link: `/${providerPathname}/${provider.id}`
                }))
        );
    }, [user, providers]);

    useEffect(() => {
        setTitle(currentMenuItem?.title ?? defaultTitle);
    }, [currentMenuItem, defaultTitle]);

    useEffect(() => {
        const menuItem = location.pathname.includes(providerPathname)
            ? providerList.find(item => `/${providerPathname}/${providerId}` === item.link)
            : actionPageList.find(item => location.pathname === item.link);
        setCurrentMenuItem(menuItem);
    }, [location, providerList, actionPageList, providerId]);

    useEffect(() => {
        if (!user) return;

        const eventSource = subscribeForceUpdate(user.companyId);

        eventSource.onopen = () => {
            console.log("Force Sync event source opend");
        };

        eventSource.onmessage = ({ data }) => {
            const message: ForceUpdateMessage = JSON.parse(data);
            console.log(message);
        };

        eventSource.onerror = () => {
            console.log("error when listening to Force Sync event source");
        };

        return () => {
            console.log("Force Sync event source closed");
            eventSource.close();
        };
    }, [user]);

    const isCurrentMenu = (item: MenuItem) => {
        return currentMenuItem?.link === item.link;
    };

    const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleForceSyncConfirmationOpen = () => {
        setForceSyncConfirmationOpen(true);
    };

    const handleForceSyncConfirmationClose = () => {
        setForceSyncConfirmationOpen(false);
        handleMenuClose();
    };

    const handleForceSyncConfirmationAction = () => {
        if (!user || !knowledgeBases.length) return;
        // TODO: get from page path params. User should choose it on Knowledge Bases page
        const knowledgeBase = knowledgeBases[0];

        forceUpdate(user.companyId, knowledgeBase.uid);
        handleForceSyncConfirmationClose();
    };

    const handleForceSyncClick = () => {
        handleForceSyncConfirmationOpen();
    };

    const handlePromptTuning = () => {
        navigate("/prompt-tuning");
    };

    return (
        <div className={`admin-menu-panel ${className ?? ""}`}>
            <IconButton
                edge="end"
                aria-label="close"
                className="admin-menu__button"
                onClick={handleMenuClick}
            >
                <SvgIcon component={MenuIcon} inheritViewBox />
            </IconButton>
            <Menu
                id="admin-menu-panel"
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        className: "admin-menu"
                    }
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
                <div className="admin-menu-actions">
                    {providerList.map(item => (
                        <Fragment key={crypto.randomUUID()}>
                            <MenuLink
                                item={item}
                                isSelected={isCurrentMenu(item)}
                                handleClick={handleMenuClose}
                            />
                        </Fragment>
                    ))}
                    {!!providerList.length && <div className="admin-menu-separator"></div>}
                    {actionPageList.map(item => (
                        <Fragment key={crypto.randomUUID()}>
                            <MenuLink
                                item={item}
                                isSelected={isCurrentMenu(item)}
                                handleClick={handleMenuClose}
                            />
                        </Fragment>
                    ))}
                    <div className="admin-menu-separator"></div>
                    {showPromptTuning && (
                        <div className="admin-menu-action" onClick={handlePromptTuning}>
                            <SvgIcon
                                className="admin-menu-action__icon"
                                component={EditIcon}
                                inheritViewBox
                            />
                            <span className="admin-menu-action__title">Prompt Tuning</span>
                        </div>
                    )}
                    <div className="admin-menu-action" onClick={handleForceSyncClick}>
                        <SvgIcon
                            className="admin-menu-action__icon"
                            component={RefreshIcon}
                            inheritViewBox
                        />
                        <span className="admin-menu-action__title">Force Sync</span>
                    </div>
                    <ConfirmationPopup
                        open={forceSyncConfirmationOpen}
                        title="Force Sync"
                        description={forceSyncDescription}
                        actionName="Proceed"
                        handleAction={handleForceSyncConfirmationAction}
                        handleClose={handleForceSyncConfirmationClose}
                    />
                </div>
            </Menu>
            {title && <span className="admin-menu_title">{title}</span>}
        </div>
    );
};

export default MenuPanel;
