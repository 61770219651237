import { APIHandler, UnauthedAPIHandler } from "../base";
import { TokenData } from "../../common/interfaces/auth/token-data.interface";

export const refreshTokens = (tokenData: TokenData) =>
    UnauthedAPIHandler.post("/auth/refresh_token", tokenData);

export const login = (email: string, password: string) =>
    APIHandler.post("/auth/login", { email, password });

export const logout = (tokenData: TokenData) => APIHandler.post("/auth/logout", tokenData);

export const getAzureSpeachToken = () => APIHandler.get("/azurespeach/token");

export const register = (data: object) => APIHandler.post("/auth/register", data);

export const registerFirebase = (data: object) =>
    APIHandler.post("/admin/auth/registerFirebase", data);
