import { useEffect, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";

import "../recognition-result/style.scss";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/arrowLeftIcon.svg";
import { fetchPrompt, patchPrompt } from "../../../api/admin/prompt/endpoints-referrers";
import { LoaderView } from "../../../components/loader/loader.comp";

const PromptTuningView = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPrompt, setCurrentPrompt] = useState<string>();
    const [promptText, setPromptText] = useState<string>("");

    useEffect(() => {
        setIsLoading(true);

        fetchPrompt()
            .then(response => {
                const prompt = response.data;
                setCurrentPrompt(prompt);
                setPromptText(prompt ?? "");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        handleBackClick();
    };

    const handleSaveClick = () => {
        setIsLoading(true);
        patchPrompt(promptText)
            .then(() => {
                handleBackClick();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setPromptText(event.target.value);
    };

    return (
        <div className="recognition-result-view">
            <div className="recognition-result-header">
                <IconButton
                    className="recognition-result-header__button-back"
                    onClick={handleBackClick}
                >
                    <SvgIcon component={ArrowLeftIcon} inheritViewBox />
                </IconButton>
                <div className="recognition-result-header-title">
                    <span className="recognition-result-header-title__text-name">
                        Prompt Tuning
                    </span>
                </div>
            </div>
            <div className="recognition-result-content">
                <textarea
                    className="recognition-result-content__textarea recognition-result-content__textarea--trial"
                    onChange={handleTextChange}
                    value={promptText}
                />
            </div>
            <div className="recognition-result-footer">
                <Button
                    className="recognition-result-footer__button recognition-result-footer__button--compare"
                    variant="outlined"
                    onClick={handleCancelClick}
                >
                    Cancel
                </Button>
                <Button
                    className="recognition-result-footer__button recognition-result-footer__button--save"
                    variant="outlined"
                    disabled={!promptText || currentPrompt === promptText}
                    onClick={handleSaveClick}
                >
                    Save
                </Button>
            </div>
            <LoaderView show={isLoading} />
        </div>
    );
};

export default PromptTuningView;
