import { Fragment, ReactNode } from "react";

import "./style.scss";

import Header from "../header/header.comp";
import Toast from "../toast/toast.comp";

export const Layout = ({ children }: { children: ReactNode }) => {
    return (
        <Fragment>
            <Header />
            <div className="layout">{children}</div>
            <Toast />
        </Fragment>
    );
};
