import { useEffect, useState, useCallback } from "react";

import "./style.scss";
import TivaPlaceholder from "../../../assets/placeholderTiva.svg";
import CometPlaceholder from "../../../assets/placeholderComet.svg";
import { ThemeContainer } from "../../../common/containers/theme/theme.container";
import { FirebaseAuthContainer } from "../../../common/containers/auth/firebase-auth-container.util";
import { Theme } from "../../../common/enums/theme.enum";
import { SortType } from "../../../common/enums/sort-type.enum";
import { FileType } from "../../../common/enums/file-type.enum";
import { FileStatus } from "../../../common/enums/file-status.enum";
import { ProviderType } from "../../../common/enums/provider-type.enum";
import { Provider } from "../../../common/interfaces/admin/provider.interface";
import { UploadedFile } from "../../../common/interfaces/admin/uploaded-file.interface";
import {
    fetchFiles,
    deleteFiles,
    fetchProviders
} from "../../../api/admin/content-providers/endpoints-referrers";
import { LoaderView } from "../../../components/loader/loader.comp";
import MenuPanel from "../../../components/admin/menu-panel/menu-panel.comp";
import FileTable from "../../../components/admin/file-table/file-table.comp";
import {
    CheckboxMenuFilter,
    SelectionFilter,
    ActionMenu
} from "../../../components/menu-filter/menu-filter.comp";

const UploadedFilesView = () => {
    const defaultPageIndex = 1;
    const defaultPageSize = 50;
    const { user } = FirebaseAuthContainer.useContainer();
    const { theme } = ThemeContainer.useContainer();
    const [isCometTheme] = useState<boolean>(theme === Theme.comet);
    const [isDefaultAdminTheme] = useState<boolean>(theme === Theme.tivaAdmin);
    const [providerId, setProviderId] = useState<number>();
    const [providers, setProviders] = useState<Provider[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [files, setFiles] = useState<UploadedFile[]>([]);
    const [checkedFiles, setCheckedFiles] = useState<UploadedFile[]>([]);
    const [checkedTypes, setCheckedTypes] = useState<string[]>([]);
    const [checkedStatuses, setCheckedStatuses] = useState<string[]>([]);
    const [selectedSort, setSelectedSort] = useState<string>(SortType.newestFirst);
    const [sortList] = useState<string[]>(Object.values(SortType));
    const [types] = useState<string[]>(Object.values(FileType));
    const [statuses] = useState<string[]>(Object.values(FileStatus));
    const [totalCount, setTotalCount] = useState<number>();
    const [pageIndex, setPageIndex] = useState<number>(defaultPageIndex);

    useEffect(() => {
        if (!user) return;

        fetchProviders(user.companyId).then(response => {
            setProviders(response.data);

            const provider = response.data.find(
                (item: Provider) => item.name === ProviderType.fileUploader
            );
            if (provider) {
                setProviderId(provider.id);
            }
        });
    }, [user]);

    useEffect(() => {
        if (!user || !providerId) return;

        loadFiles(
            user.companyId,
            providerId,
            createQuery(
                defaultPageIndex,
                defaultPageSize,
                checkedTypes,
                checkedStatuses,
                selectedSort
            )
        );
    }, [user, providerId, checkedTypes, checkedStatuses, selectedSort]);

    const createQuery = (
        page: number,
        pageSize: number,
        checkedTypes: string[],
        checkedStatuses: string[],
        selectedSort: string
    ) => {
        const queryParts: string[] = [];

        queryParts.push(`page=${page}`);
        queryParts.push(`pageSize=${pageSize}`);

        if (checkedTypes.length > 0) {
            queryParts.push(`type=${checkedTypes.join(",")}`);
        }

        if (checkedStatuses.length > 0) {
            queryParts.push(`status=${checkedStatuses.join(",")}`);
        }

        const sortMap = {
            [SortType.newestFirst]: "sortBy=createdAt&sortOrder=desc",
            [SortType.oldestFirst]: "sortBy=createdAt&sortOrder=asc",
            [SortType.az]: "sortBy=fileName&sortOrder=asc",
            [SortType.za]: "sortBy=fileName&sortOrder=desc"
        };

        if (selectedSort && sortMap[selectedSort as SortType]) {
            queryParts.push(sortMap[selectedSort as SortType]);
        }

        return `?${queryParts.join("&")}`;
    };

    const loadFiles = (companyId: number, providerId: number, query?: string) => {
        setIsLoading(true);
        fetchFiles(companyId, providerId, query).then(response => {
            const {
                data: { count, items }
            } = response;
            setTotalCount(count);
            setFiles(items);
            setPageIndex(defaultPageIndex);
            setIsLoading(false);
        });
    };

    const appendFiles = (companyId: number, providerId: number, query?: string) => {
        fetchFiles(companyId, providerId, query).then(response => {
            const {
                data: { count, items }
            } = response;
            setTotalCount(count);
            setFiles(prevFiles => [...prevFiles, ...items]);
            setPageIndex(prevIndex => prevIndex + 1);
        });
    };

    const handleAppendFiles = useCallback(() => {
        if (!user || !providerId) return;

        appendFiles(
            user.companyId,
            providerId,
            createQuery(pageIndex + 1, defaultPageSize, checkedTypes, checkedStatuses, selectedSort)
        );
    }, [user, providerId, pageIndex, checkedTypes, checkedStatuses, selectedSort]);

    const handleDeleteFiles = useCallback(
        (uids: string[]) => {
            if (!user || !providerId) return;

            deleteFiles(user.companyId, providerId, { deletingFilesUids: uids }).then(() => {
                setFiles(prevFiles =>
                    prevFiles.filter(file => !uids.find(uid => uid === file.uid))
                );
                setCheckedFiles([]);
            });
        },
        [user, providerId]
    );

    const handleDeleteCheckedFiles = () => {
        const uids = checkedFiles.map((item: UploadedFile) => item.uid);
        if (uids.length > 0) {
            handleDeleteFiles(uids);
        }
    };

    return (
        <div className="uploaded-files-view">
            <MenuPanel
                providers={providers}
                showPromptTuning={isDefaultAdminTheme}
                defaultTitle="Uploaded Files"
                className="uploaded-files-header"
            />
            <div className="uploaded-files-filters">
                <div className="file-table-filter file-table-filter__actions">
                    <ActionMenu
                        label="Action"
                        disabled={checkedFiles.length === 0}
                        actions={[{ name: "Delete", action: handleDeleteCheckedFiles }]}
                    />
                </div>
                <div className="file-table-filter file-table-filter__types">
                    <CheckboxMenuFilter
                        label="Type"
                        list={types}
                        checkedList={checkedTypes}
                        setCheckedList={setCheckedTypes}
                        showCounter={true}
                    />
                </div>
                <div className="file-table-filter file-table-filter__status">
                    <CheckboxMenuFilter
                        label="Status"
                        list={statuses}
                        checkedList={checkedStatuses}
                        setCheckedList={setCheckedStatuses}
                    />
                </div>
                <div className="file-table-filter file-table-filter__sorting">
                    <SelectionFilter
                        list={sortList}
                        selectedItem={selectedSort}
                        setSelectedItem={setSelectedSort}
                        rightPosition={true}
                    />
                </div>
            </div>
            <div className="uploaded-files-table">
                <FileTable
                    totalCount={totalCount}
                    files={files}
                    checkedFiles={checkedFiles}
                    setCheckedFiles={setCheckedFiles}
                    handleAppendFiles={handleAppendFiles}
                    handleDelete={handleDeleteFiles}
                    placeholder={isCometTheme ? CometPlaceholder : TivaPlaceholder}
                />
                <LoaderView show={isLoading} />
            </div>
        </div>
    );
};

export default UploadedFilesView;
