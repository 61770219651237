import { useState, useEffect, useCallback, UIEvent } from "react";
import { Button, IconButton } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import Checkbox from "@mui/material/Checkbox";

import "./style.scss";
import { ReactComponent as CheckboxIcon } from "../../../assets/checkboxIcon.svg";
import { ReactComponent as CheckboxCheckedIcon } from "../../../assets/checkboxCheckedIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/deleteIcon.svg";
import { FileStatus } from "../../../common/enums/file-status.enum";
import { UploadedFile } from "../../../common/interfaces/admin/uploaded-file.interface";
import { Loader } from "../../../components/loader/loader.comp";

const FileTable = ({
    totalCount,
    files,
    checkedFiles,
    setCheckedFiles,
    handleAppendFiles,
    handleDelete,
    showAIResult = false,
    placeholder
}: {
    totalCount?: number;
    files: UploadedFile[];
    checkedFiles: UploadedFile[];
    setCheckedFiles: (arg1: UploadedFile[]) => void;
    handleAppendFiles?: () => void;
    handleDelete?: (arg1: string[]) => void;
    showAIResult?: boolean;
    placeholder: string;
}) => {
    const scrollDelata = 250;
    const [appendAvailable, setAppendAvailable] = useState<boolean>(true);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    useEffect(() => {
        setShowLoader(!!handleAppendFiles && !!totalCount && totalCount !== files.length);
    }, [files, totalCount, handleAppendFiles]);

    const parseFileName = (fileName: string) => {
        if (!fileName) return "";
        const parts = fileName.split("/");
        return parts[parts.length - 1];
    };

    const handleChangeSelectAll = useCallback(() => {
        setCheckedFiles(files.length === checkedFiles.length ? [] : files);
    }, [files, checkedFiles, setCheckedFiles]);

    const handleChangeSelect = useCallback(
        (item: UploadedFile) => {
            const newFiles = [...checkedFiles];
            const index = newFiles.indexOf(item);
            index !== -1 ? newFiles.splice(index, 1) : newFiles.push(item);
            setCheckedFiles(newFiles);
        },
        [checkedFiles, setCheckedFiles]
    );

    const handleAIResultClick = (item: UploadedFile) => {
        // TODO functional will be added
        console.info(item);
    };

    const handleDeleteClick = (item: UploadedFile) => {
        if (handleDelete) {
            handleDelete([item.uid]);
        }
    };

    const fileStatusColor = (status: string) => {
        switch (status as FileStatus) {
            case FileStatus.uploaded:
                return "green";
            case FileStatus.pending:
                return "yellow";
            case FileStatus.error:
            case FileStatus.unpublished:
                return "red";
            default:
                return "default";
        }
    };

    const fileStatusName = (status: string) => {
        switch (status as FileStatus) {
            case FileStatus.uploaded:
                return "Uploaded";
            case FileStatus.pending:
                return "Pending";
            case FileStatus.error:
                return "Error";
            case FileStatus.unpublished:
                return "Unpublished";
            default:
                return status;
        }
    };

    const handleListScroll = (event: UIEvent<HTMLElement>) => {
        if (!handleAppendFiles || (totalCount && totalCount === files.length)) return;

        const scrollBottom =
            event.currentTarget.scrollHeight -
            (event.currentTarget.scrollTop + event.currentTarget.offsetHeight);

        if (appendAvailable && scrollBottom < scrollDelata) {
            setAppendAvailable(false);
            handleAppendFiles();
        } else if (scrollBottom >= scrollDelata) {
            setAppendAvailable(true);
        }
    };

    return (
        <div className="file-table-view">
            {files.length > 0 ? (
                <div className="file-table">
                    <div className="file-table-header">
                        <div className="file-table-header-cell file-table-cell__select">
                            <Checkbox
                                checked={files.length === checkedFiles.length}
                                onChange={handleChangeSelectAll}
                                icon={<CheckboxIcon />}
                                checkedIcon={<CheckboxCheckedIcon />}
                            />
                        </div>
                        <div className="file-table-header-cell file-table-cell__name">
                            File Name
                        </div>
                        <div className="file-table-header-cell file-table-cell__status">Status</div>
                        {showAIResult && (
                            <div className="file-table-header-cell file-table-cell__result"></div>
                        )}
                        <div className="file-table-header-cell file-table-cell__delete"></div>
                    </div>
                    <div className="file-table-body" onScroll={handleListScroll}>
                        {files.map(item => (
                            <div key={item.uid} className="file-table-row">
                                <div className="file-table-cell file-table-cell__select">
                                    <Checkbox
                                        checked={checkedFiles.includes(item)}
                                        onChange={() => handleChangeSelect(item)}
                                        icon={<CheckboxIcon />}
                                        checkedIcon={<CheckboxCheckedIcon />}
                                    />
                                </div>
                                <div className="file-table-cell file-table-cell__name">
                                    <span className="table-file-name">
                                        {parseFileName(item.fileName)}
                                    </span>
                                </div>
                                <div className="file-table-cell file-table-cell__status">
                                    <div
                                        className={`table-file-status table-file-status__${fileStatusColor(item.status)}`}
                                    >
                                        <div className="table-file-status__circle"></div>
                                        <span className="table-file-status__title">
                                            {fileStatusName(item.status)}
                                        </span>
                                    </div>
                                </div>
                                {showAIResult && (
                                    <div className="file-table-cell file-table-cell__result">
                                        <Button
                                            className="table-file__button-result"
                                            variant="outlined"
                                            onClick={() => handleAIResultClick(item)}
                                        >
                                            AI Result
                                        </Button>
                                    </div>
                                )}
                                <div className="file-table-cell file-table-cell__delete">
                                    <IconButton
                                        className="table-file__button-delete"
                                        onClick={() => handleDeleteClick(item)}
                                    >
                                        <SvgIcon component={DeleteIcon} inheritViewBox />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                        {showLoader && (
                            <div className="file-table-row file-table-row__loader">
                                <Loader size={24} borderWidth={4} />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="file-table-placeholder">
                    <img src={placeholder} alt="" />
                </div>
            )}
        </div>
    );
};

export default FileTable;
