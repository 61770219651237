import { useState, ChangeEvent, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";

import "./style.scss";
import { FirebaseAuthContainer } from "../../../common/containers/auth/firebase-auth-container.util";
import { ReactComponent as CloudErrorIcon } from "../../../assets/cloudErrorIcon.svg";
import { addContentItemProvider } from "../../../api/admin/content-providers/endpoints-referrers";
import { ModalTemplate } from "../../../components/modal-template/modal-template.comp";
import { Loader } from "../../../components/loader/loader.comp";
import { LoaderStep } from "../../../common/enums/loader-step.enum";
import { ProviderType } from "../../../common/enums/provider-type.enum";

const LinkGoogleDriveView = () => {
    const navigate = useNavigate();
    const { user, knowledgeBases } = FirebaseAuthContainer.useContainer();
    const [driveLink, setDriveLink] = useState<string>("");
    const [driveName, setDriveName] = useState<string>("");
    const [step, setStep] = useState<LoaderStep>(LoaderStep.start);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const loadingDescription = `You can now close this window.
    Google Drive will appear when connection is complete.`;
    const completeDescription = `Files from this drive will appear soon.
    Depending on the size and quantity in may take some time.`;

    const handleCloseClick = () => {
        navigate("/knowledge-base");
    };

    const handleTryAgainClick = () => {
        setStep(LoaderStep.start);
    };

    const handleDriveLinkChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const val = evt.target?.value;
        setDriveLink(val ?? "");
    };

    const handleDriveNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const val = evt.target?.value;
        setDriveName(val ?? "");
    };

    const handleConnectClick = () => {
        if (!user || !driveName || !driveLink || !knowledgeBases.length) return;

        // TODO: get from page path params. User should choose it on Knowledge Bases page
        const knowledgeBase = knowledgeBases[0];

        setStep(LoaderStep.loading);
        addContentItemProvider(user.companyId, {
            name: ProviderType.googleDrive,
            displayName: driveName,
            googleDriveUrl: driveLink,
            knowledgeBaseId: knowledgeBase.uid
        })
            .then(response => {
                console.info(response);
                setStep(LoaderStep.complete);
            })
            .catch(error => {
                switch (error.code) {
                    case "ERR_NETWORK":
                        setErrorMessage("Network error.");
                        break;
                    case "ERR_BAD_REQUEST":
                        setErrorMessage(`There was a problem connecting Google Drive “${driveName}”.
                        Check its permissions for external access.`);
                        break;
                    default:
                        setErrorMessage("Something went wrong. Please try again later.");
                }
                setStep(LoaderStep.error);
            });
    };

    return (
        <ModalTemplate height={357} handleClose={handleCloseClick}>
            <div className="link-google-drive">
                {step === LoaderStep.start && (
                    <>
                        <div className="link-google-drive-title">
                            <span>Connect Google Drive</span>
                        </div>
                        <div className="link-google-drive-data">
                            <input
                                tabIndex={1}
                                type="text"
                                placeholder="Copy link to google drive here"
                                onChange={handleDriveLinkChange}
                                value={driveLink}
                            />
                            <input
                                tabIndex={2}
                                type="text"
                                placeholder="Name this drive (can be changed later)"
                                onChange={handleDriveNameChange}
                                value={driveName}
                            />
                        </div>
                    </>
                )}
                {step === LoaderStep.loading && (
                    <div className="link-google-drive-loading">
                        <div className="link-google-drive-loading__spinner">
                            <Loader size={40} borderWidth={5} />
                        </div>
                        <div className="link-google-drive-loading__title">
                            Connection in Progress
                        </div>
                        <div className="link-google-drive-loading__description">
                            {loadingDescription}
                        </div>
                    </div>
                )}
                {step === LoaderStep.complete && (
                    <div className="link-google-drive-connected">
                        <span className="link-google-drive-connected__title">
                            Google Drive Connected
                        </span>
                        <span className="link-google-drive-connected__description">
                            {completeDescription}
                        </span>
                    </div>
                )}
                {step === LoaderStep.error && (
                    <div className="link-google-drive-error">
                        <span className="link-google-drive-error__icon">
                            <SvgIcon component={CloudErrorIcon} inheritViewBox />
                        </span>
                        <span className="link-google-drive-error__title">Connection Error</span>
                        <span className="link-google-drive-error__description">{errorMessage}</span>
                    </div>
                )}
                <div className="link-google-drive-action">
                    {(step === LoaderStep.start || step === LoaderStep.loading) && (
                        <Button
                            variant="text"
                            onClick={handleConnectClick}
                            disabled={!driveLink || !driveName || step === LoaderStep.loading}
                        >
                            {step === LoaderStep.start ? "Connect Google Drive" : "Please wait"}
                        </Button>
                    )}
                    {step === LoaderStep.complete && (
                        <Button variant="text" onClick={handleCloseClick}>
                            Close
                        </Button>
                    )}
                    {step === LoaderStep.error && (
                        <Button
                            variant="text"
                            className="link-google-drive-action__button--error"
                            onClick={handleTryAgainClick}
                        >
                            Try Again
                        </Button>
                    )}
                </div>
            </div>
        </ModalTemplate>
    );
};

export default LinkGoogleDriveView;
