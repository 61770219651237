import { APIHandler, EventSourceHandler } from "../../base";

export const fetchKnowledgeBases = (companyId: number) =>
    APIHandler.get(`/admin/${companyId}/knowledgeBases`);

export const forceUpdate = (companyId: number, kbUid: string) =>
    APIHandler.put(`/admin/${companyId}/knowledgeBases/${kbUid}/forceUpdate`);

export const subscribeForceUpdate = (companyId: number) =>
    EventSourceHandler(`/admin/${companyId}/sse`);

export const fetchKnowledgeBaseInvites = (companyId: number, kbUid: string) =>
    APIHandler.get(`/admin/${companyId}/knowledgeBases/${kbUid}/invites`);
