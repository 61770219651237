import { useRef, useState, useEffect, useCallback } from "react";

export function useTimer() {
    const timer = useRef<number>();
    const [timerStarted, setTimerStarted] = useState<boolean>(false);
    const [timerTimeLeft, setTimerTimeLeft] = useState<number>(0);

    const startTimer = useCallback((time: number) => {
        clearTimer();
        setTimerTimeLeft(time);
        setTimerStarted(true);
        timer.current = window.setInterval(() => {
            setTimerTimeLeft(prevTime => prevTime - 1);
        }, 1000);
    }, []);

    const stopTimer = useCallback(() => {
        clearTimer();
        setTimerStarted(false);
    }, []);

    useEffect(() => {
        if (timerTimeLeft <= 0) {
            stopTimer();
        }
    }, [timerTimeLeft, stopTimer]);

    function clearTimer() {
        window.clearInterval(timer.current);
    }

    return {
        timerStarted,
        timerTimeLeft,
        startTimer,
        stopTimer
    };
}
