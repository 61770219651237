import { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import "./style.scss";
import { FirebaseAuthContainer } from "../../../common/containers/auth/firebase-auth-container.util";
import { ModalTemplate } from "../../../components/modal-template/modal-template.comp";

const FeedbackView = () => {
    const navigate = useNavigate();
    const { user } = FirebaseAuthContainer.useContainer();
    const [message, setMessage] = useState<string>("");
    const [description, setDescription] = useState<string>();
    const title = `Ready to upgrade to full
    version or want to know more?`;

    useEffect(() => {
        if (!user) return;

        setDescription(`${user.firstName}, If you wish to comment or ask anything, please do.
                        We will contact you shortly!`);
    }, [user]);

    const handleCloseClick = () => {
        navigate(-1);
    };

    const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    };

    const handleSendRequestClick = () => {
        // TODO functionality will be added
    };

    return (
        <ModalTemplate height={455} handleClose={handleCloseClick}>
            <div className="feedback">
                <div className="feedback-title">{title}</div>
                <div className="feedback-description">{description}</div>
                <textarea
                    className="feedback-message"
                    onChange={handleTextChange}
                    placeholder="Message"
                    value={message}
                />
                <Button
                    className="feedback-action"
                    variant="outlined"
                    onClick={handleSendRequestClick}
                >
                    Send Request
                </Button>
            </div>
        </ModalTemplate>
    );
};

export default FeedbackView;
