import { useCallback, useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { AxiosError } from "axios";
import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    signOut,
    User as FirebaseUser
} from "firebase/auth";

import { useFirebaseToken } from "./use-firebase-token.util";
import { firebaseConfig } from "../../utilities/constants/constants";
import { useTimer } from "../../utilities/timer/use-timer";
import { stringGetter } from "../../utilities/localstorage-dealer/localstorage-getters.util";
import {
    setDataToLocalStorage,
    removeDataToLocalStorage
} from "../../utilities/localstorage-dealer/localstorage-setter.util";
import { AuthEvents } from "../../enums/auth-events.enum";
import { CompanyType } from "../../enums/company-type.enum";
import { User } from "../../interfaces/comps/user.interface";
import { KnowledgeBase } from "../../interfaces/admin/knowledge-base.interface";
import { fetchFirebaseUser, updateFirebaseUser } from "../../../api/users/endpoints-referrers";
import { fetchKnowledgeBases } from "../../../api/admin/knowledge-base/endpoints-referrers";
import { registerFirebase } from "../../../api/auth/endpoints-referrers";

function useFirebaseAuth() {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const [isTrial, setIsTrial] = useState<boolean>(true);
    const [user, setUser] = useState<User | null>(null);
    const [email, setEmail] = useState<string>();
    const [emailVerified, setEmailVerified] = useState<boolean>();
    const [sendVerificationWaitingTime, setSendVerificationWaitingTime] = useState<number>();
    const [knowledgeBases, setKnowledgeBases] = useState<KnowledgeBase[]>([]);
    const { isAuthenticated, setTokens, clearToken } = useFirebaseToken(useCallback(clearUser, []));
    const { timerStarted, timerTimeLeft, startTimer, stopTimer } = useTimer();

    const getUser = useCallback(async () => {
        const {
            data: { ...user }
        } = await fetchFirebaseUser();
        setUser(user);
        await getKnowledgeBases(user.companyId);
    }, [setUser]);

    const getKnowledgeBases = async (companyId: number) => {
        const data = await fetchKnowledgeBases(companyId);
        // Total count: data.data.count
        setKnowledgeBases(data.data.items);
    };

    const loadUserData = useCallback(
        (user: FirebaseUser | null) => {
            const isVerified = user?.emailVerified ?? false;
            setEmail(user?.email ?? undefined);
            setEmailVerified(isVerified);
            if (isVerified) {
                getUser();
            } else {
                const now = new Date().getTime();
                const sendingTime = stringGetter(AuthEvents.VERIFICATION_SENDING_TIME);
                if (sendingTime && now - new Date(sendingTime).getTime() < 60000) {
                    startTimer(60 - Math.trunc((now - new Date(sendingTime).getTime()) / 1000));
                }
            }
        },
        [getUser, startTimer]
    );

    useEffect(() => {
        setIsTrial(user?.companyType === CompanyType.trial);
    }, [user]);

    useEffect(() => {
        if (isAuthenticated()) {
            auth.authStateReady().then(() => {
                loadUserData(auth.currentUser);
            });
        }
    }, [auth, isAuthenticated, loadUserData]);

    useEffect(() => {
        const handleStorageEventListener = async (event: WindowEventMap["storage"]) => {
            if (event.key === AuthEvents.LOGOUT) {
                setUser(null);
                clearToken();
                stopTimer();
            } else if (
                (event.key === AuthEvents.LOGIN ||
                    event.key === AuthEvents.VERIFICATION_SENDING_TIME ||
                    event.key === AuthEvents.EMAIL_CONFIRMED_TIME) &&
                isAuthenticated()
            ) {
                location.reload();
            }
        };

        window.addEventListener("storage", handleStorageEventListener);

        return () => {
            window.removeEventListener("storage", handleStorageEventListener);
        };
    }, [loadUserData, isAuthenticated, clearToken, stopTimer]);

    useEffect(() => {
        setSendVerificationWaitingTime(timerStarted ? timerTimeLeft : undefined);
    }, [timerStarted, timerTimeLeft]);

    const signIn = async (email: string, password: string) => {
        try {
            const response = await signInWithEmailAndPassword(auth, email, password);
            const firebaseUser = response.user;
            const token = await firebaseUser.getIdToken();
            setTokens({ accessToken: token, refreshToken: firebaseUser.refreshToken });
            setEmailVerified(firebaseUser.emailVerified);
            setEmail(firebaseUser.email ?? undefined);

            if (!firebaseUser.emailVerified) {
                await sendVerification();
            } else {
                await getUser();
                setDataToLocalStorage(AuthEvents.LOGIN, new Date().toISOString());
            }

            return Promise.resolve(response);
        } catch (error) {
            logout();
            return Promise.reject(error);
        }
    };

    const signUp = async (
        email: string,
        password: string,
        invite: string | null,
        name?: string,
        companyName?: string,
        position?: string
    ) => {
        try {
            const response = await createUserWithEmailAndPassword(auth, email, password);
            const firebaseUser = response.user;
            const token = await firebaseUser.getIdToken();
            setTokens({ accessToken: token, refreshToken: firebaseUser.refreshToken });
            setEmailVerified(firebaseUser.emailVerified);
            setEmail(firebaseUser.email ?? undefined);

            await registerFirebaseUser(
                firebaseUser.uid,
                email,
                password,
                invite,
                name,
                companyName,
                position
            );
            await sendVerification();

            return Promise.resolve(response);
        } catch (error) {
            logout();
            return Promise.reject(error);
        }
    };

    const registerFirebaseUser = async (
        firebaseId: string,
        email: string,
        password: string,
        invite: string | null,
        name?: string,
        companyName?: string,
        position?: string
    ) => {
        const signupData = {
            email,
            password,
            from: invite,
            firebaseId,
            firstName: name,
            companyName: companyName ?? crypto.randomUUID(),
            position
        };
        try {
            await registerFirebase(signupData);
        } catch (error) {
            if (error instanceof AxiosError && error.response?.status === 409) {
                await updateFirebaseUser(firebaseId);
            } else {
                throw error;
            }
        }
    };

    const sendVerification = async () => {
        try {
            if (!auth.currentUser) return Promise.resolve(false);

            setDataToLocalStorage(AuthEvents.VERIFICATION_SENDING_TIME, new Date().toISOString());
            startTimer(60);
            await sendEmailVerification(auth.currentUser, {
                url: `${window.location.origin}/registration-success`
            });

            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const sendPasswordReset = async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email, {
                url: `${window.location.origin}/login`
            });

            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const logout = useCallback(() => {
        signOut(auth).finally(() => {
            clearToken();
            stopTimer();
            setDataToLocalStorage(AuthEvents.LOGOUT, new Date().toISOString());
            removeDataToLocalStorage(AuthEvents.VERIFICATION_SENDING_TIME);
        });
    }, [auth, clearToken, stopTimer]);

    function clearUser() {
        setUser(null);
        setEmail(undefined);
        setEmailVerified(undefined);
    }

    return {
        isAuthenticated,
        user,
        isTrial,
        email,
        emailVerified,
        sendVerificationWaitingTime,
        knowledgeBases,
        setUser,
        signIn,
        signUp,
        sendVerification,
        sendPasswordReset,
        logout
    };
}

export const FirebaseAuthContainer = createContainer(useFirebaseAuth);
