import { Navigate, useLocation } from "react-router-dom";

import { FirebaseAuthContainer } from "../../../common/containers/auth/firebase-auth-container.util";

const UnverifiedProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const { isAuthenticated, emailVerified } = FirebaseAuthContainer.useContainer();
    const location = useLocation();

    return isAuthenticated() ? (
        !emailVerified ? (
            children
        ) : (
            <Navigate to={location.state?.from || "/"} />
        )
    ) : (
        <Navigate to="/login" state={{ from: location }} />
    );
};

export default UnverifiedProtectedRoute;
