import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, IconButton, SvgIcon } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import "./style.scss";
import { Chat } from "../../common/interfaces/chats/chat.interface";
import {
    SettingMenu,
    SettingMenuType,
    SettingItem,
    SettingItemType
} from "../../components/setting-menu/setting-menu.comp";

const ChatList = ({
    className = "",
    chats,
    settings,
    selectedChatId,
    popupMode,
    toggleChatList,
    handleChatCreating,
    handleChatDeleting
}: {
    className: string;
    chats: Chat[];
    settings: SettingItem[];
    selectedChatId?: string;
    popupMode?: boolean;
    toggleChatList?: (arg1: boolean) => void;
    handleChatCreating: () => void;
    handleChatDeleting: (arg1: Chat) => void;
}) => {
    let lastSectionDate: Date | null = null;

    const formatStringDate = (date: Date) => {
        const today = new Date();

        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
            ? "Today"
            : date.getDate() + 1 === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear()
              ? "Yesterday"
              : date.toLocaleString("en-us", { day: "numeric", month: "short" });
    };

    const handleChatCreatingClick = () => {
        handleCloseChatListClick();
        handleChatCreating();
    };

    const handleCloseChatListClick = () => {
        if (toggleChatList) {
            toggleChatList(false);
        }
    };

    return (
        <div className={`chat-list ${className ?? ""}`}>
            <div className="chat-list-header">
                <Button
                    className="chat-list-header__button--create"
                    variant="outlined"
                    startIcon={<SvgIcon component={AddIcon} inheritViewBox />}
                    onClick={handleChatCreatingClick}
                >
                    <span>New Chat</span>
                </Button>
                <SettingMenu className="chat-list-header__button--setting" actions={settings} />
                {popupMode && (
                    <>
                        <div className="chat-list-header__space"></div>
                        <IconButton
                            edge="end"
                            aria-label="close"
                            className="chat-list-header__button--close"
                            onClick={handleCloseChatListClick}
                        >
                            <SvgIcon component={CloseIcon} inheritViewBox />
                        </IconButton>
                    </>
                )}
            </div>
            <div className="chat-list-items">
                {chats.map(chat => {
                    const currentDate = new Date(Date.parse(chat.createdAt));
                    const isSectionAvailable =
                        !lastSectionDate || lastSectionDate.getDate() !== currentDate.getDate();
                    if (isSectionAvailable) {
                        lastSectionDate = currentDate;
                    }
                    return (
                        <Fragment key={chat.roomId}>
                            {isSectionAvailable && (
                                <div className="chat-list-section">
                                    <span className="chat-list-section__title">
                                        {formatStringDate(currentDate)}
                                    </span>
                                </div>
                            )}
                            <div
                                className={`chat-list-item${selectedChatId === chat.roomId ? " chat-list-item-selected" : ""}`}
                            >
                                <Link
                                    className={`chat-list-item__link${selectedChatId === chat.roomId ? " chat-list-item__link-selected" : ""}`}
                                    onClick={handleCloseChatListClick}
                                    to={`/chat/${chat.roomId}`}
                                >
                                    {chat.title}
                                    <div className="chat-list-item__link-gradient"></div>
                                </Link>
                                <SettingMenu
                                    className="chat-list-item__more"
                                    type={SettingMenuType.more}
                                    actions={[
                                        {
                                            name: "Remove",
                                            type: SettingItemType.default,
                                            handler: () => {
                                                handleChatDeleting(chat);
                                            }
                                        }
                                    ]}
                                />
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default ChatList;
