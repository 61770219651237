import { useState, ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";

import "./style.scss";
import "../admin/auth/signin/style.scss";
import { ReactComponent as EyeIcon } from "../../assets/eyeIcon.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/eyeOffIcon.svg";
import { ReactComponent as GoogleIcon } from "../../assets/googleIcon.svg";
import { AuthContainer } from "../../common/containers/auth/auth-container.util";
import { emailRegex } from "../../common/utilities/constants/constants";
import { openCenteredPopup } from "../../common/utilities/popup/popup-open.util";
import { AuthTemplate } from "../../components/admin/auth-template/auth-template.comp";
import { Loader } from "../../components/loader/loader.comp";

const SignIn = () => {
    const { signIn } = AuthContainer.useContainer();
    const [login, setLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordType, setPasswordType] = useState<string>("password");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const invite = searchParams.get("invite");
    const from =
        window.location.hostname === "localhost"
            ? (invite ?? "debug")
            : (invite ?? process.env.REACT_APP_ENVIRONMENT);
    const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/google/${from}`;
    const registrationLink = !invite ? "/registration" : `/registration?invite=${invite}`;

    function handleTargetPopup() {
        openCenteredPopup("", "Popup_Window", 500, 550);
    }

    const handleLoginChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const val = evt.target?.value;
        setLogin(val ?? "");
        setErrorMessage("");
        setIsInvalid(false);
    };

    const handlePasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const val = evt.target?.value;
        setPassword(val ?? "");
        setErrorMessage("");
    };

    const handleEyeClick = () => {
        setPasswordType(prevValue => (prevValue === "text" ? "password" : "text"));
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            handleSignInClick();
        }
    };

    const handleSignInClick = () => {
        if (!login || !password || isLoading || isInvalid) return;

        if (!login.match(emailRegex)) {
            setIsInvalid(true);
            setErrorMessage("Invalid email format");
            return;
        }

        setIsLoading(true);
        signIn(login, password)
            .catch(() => {
                setErrorMessage("Invalid username or password, try again");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <AuthTemplate height={428} title="Knowledge Base Assistant">
            <div className="admin-signin">
                <div className="admin-signin-title">Log in</div>
                <div className="admin-signin-description">
                    <span className="admin-signin-description__text">
                        Don&apos;t have an account?
                    </span>
                    <a href={registrationLink} className="admin-signin-description__link">
                        Sign up
                    </a>
                </div>
                <div className="admin-signin-content">
                    <div className="admin-signin-field">
                        <input
                            tabIndex={1}
                            type="text"
                            placeholder="Your Email"
                            onChange={handleLoginChange}
                            onKeyDown={handleKeyDown}
                            value={login}
                        />
                    </div>
                    <div className="admin-signin-field">
                        <input
                            className="admin-signin-field__input--eye"
                            tabIndex={2}
                            type={passwordType}
                            placeholder="Your Password"
                            onChange={handlePasswordChange}
                            onKeyDown={handleKeyDown}
                            value={password}
                        />
                        <SvgIcon
                            className="admin-signin-field__eye"
                            component={passwordType === "text" ? EyeIcon : EyeOffIcon}
                            inheritViewBox
                            onClick={handleEyeClick}
                        />
                    </div>
                    <div className="admin-signin-or">or</div>
                    <form
                        className="admin-signin-form"
                        method="post"
                        target="Popup_Window"
                        action={url}
                        onSubmit={handleTargetPopup}
                    >
                        <Button
                            className="button__google admin-signin-button--google"
                            variant="outlined"
                            type="submit"
                            startIcon={<SvgIcon component={GoogleIcon} inheritViewBox />}
                        >
                            Continue with Google
                        </Button>
                    </form>
                    <div className="admin-signin-message">
                        <span>{errorMessage}</span>
                    </div>
                </div>
                <div className="admin-signin-action">
                    <Button
                        variant="text"
                        onClick={handleSignInClick}
                        disabled={!login || !password || isLoading || isInvalid}
                    >
                        {!isLoading ? <span>Log in</span> : <Loader size={24} borderWidth={4} />}
                    </Button>
                </div>
            </div>
        </AuthTemplate>
    );
};

export default SignIn;
