import { useState, useEffect, useRef, useCallback, UIEvent } from "react";
import { Params, useLocation, useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";

import "./style.scss";
import { ReactComponent as ArrowExpandIcon } from "../../assets/arrowExpandIcon.svg";
import { Theme } from "../../common/enums/theme.enum";
import { ChatEvents } from "../../common/enums/chat-events.enum";
import { User } from "../../common/interfaces/comps/user.interface";
import { Message } from "../../common/interfaces/chats/message.interface";
import { SummaryProps } from "../../common/interfaces/chats/summary.interface";
import { FeedbackProps } from "../../common/interfaces/chats/feedback.interface";
import {
    ChatBubble,
    ChatBubbleType,
    Feedback
} from "../../components/chat-bubble/chat-bubble.comp";
import { ChatBackground } from "../../components/chat-background/chat-background.comp";
import { LoaderView } from "../../components/loader/loader.comp";
import {
    ChatMessageReview,
    ChatMessageReviewBackdrop
} from "../../components/chat-message-review/chat-message-review.comp";
import {
    listenAnswers,
    addQuestion,
    fetchChatHistory,
    summarizeVideo,
    feedback
} from "../../api/chats/endpoints-referrers";
import { ThemeContainer } from "../../common/containers/theme/theme.container";
import { AuthContainer } from "../../common/containers/auth/auth-container.util";
import { isMobileDevice } from "../../common/utilities/platform/platform.util";
import {
    stringGetter,
    objGetter
} from "../../common/utilities/localstorage-dealer/localstorage-getters.util";
import {
    setDataToLocalStorage,
    removeDataToLocalStorage
} from "../../common/utilities/localstorage-dealer/localstorage-setter.util";

export interface ChatMessage {
    id?: number;
    uid?: string;
    text: string;
    isLoading: boolean;
    type: ChatBubbleType;
    isExpanded?: boolean;
    metadata: any;
    feedback?: FeedbackProps;
}

export interface ChatMessagePair {
    question?: ChatMessage;
    answer?: ChatMessage;
}

export const ChatMessages = ({
    newMessage,
    setNewMessage,
    placeholder,
    showDashboard = false
}: {
    newMessage: string | null;
    setNewMessage: (arg1: string | null) => void;
    placeholder: string;
    showDashboard?: boolean;
}) => {
    interface QuestionEvent {
        chatId: string;
        message: ChatMessage;
    }

    const answerTime = 60000;
    const activeQuestionId = "activeQuestionId";
    const activeAnswerId = "activeAnswerId";
    const location = useLocation();
    const { chatId } = useParams<Params<string>>();
    const { user, accessToken }: { isTrial: boolean; user: User | null; accessToken?: string } =
        AuthContainer.useContainer();
    const { theme } = ThemeContainer.useContainer();
    const [isCometTheme] = useState<boolean>(theme === Theme.comet);
    const chatScrollRef = useRef<HTMLDivElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const reviewRef = useRef<HTMLDivElement>(null);
    const [messages, setMessages] = useState<ChatMessagePair[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [activeMessage, setActiveMessage] = useState<number>();
    const [reviewScroll, setReviewScroll] = useState<boolean>(false);
    const [reviewScrollTop, setReviewScrollTop] = useState<number>(0);
    const [reviewHeight, setReviewHeight] = useState<number>(0);
    const [isScrolledDown, setScrolledDown] = useState<boolean>(true);
    const availableScrollDownType = useRef<ScrollBehavior | null>(null);

    const isActiveMessage = (message?: ChatMessage) => {
        return !!message?.id && message.id === activeMessage;
    };

    const scrollToBottom = (type: ScrollBehavior) => {
        messagesEndRef.current?.scrollIntoView({ behavior: type });
    };

    const scrollToReviewMessage = () => {
        reviewRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    };

    const typingMessage = (): ChatMessage => {
        return {
            text: "typing",
            isLoading: true,
            type: ChatBubbleType.typing,
            metadata: null
        };
    };

    const approveSentMessage = useCallback((uid: string, id: number) => {
        setMessages(prevMessages =>
            prevMessages
                .map(item =>
                    item.answer?.type === ChatBubbleType.typing
                        ? { ...item, answer: undefined }
                        : item
                )
                .map(item =>
                    item.question?.uid === uid
                        ? {
                              question: { ...item.question, id: id, isLoading: false },
                              answer: typingMessage()
                          }
                        : item
                )
        );
    }, []);

    const setHistoryMessages = useCallback((items: Message[]) => {
        setMessages(
            items
                .map((item, index) => {
                    const question = item.question
                        ? {
                              id: item.id,
                              text: item.question,
                              isLoading: false,
                              type: ChatBubbleType.question,
                              metadata: null
                          }
                        : undefined;
                    const answer = item.answer
                        ? {
                              id: item.id,
                              text: item.answer,
                              isLoading: false,
                              type: item.question ? ChatBubbleType.answer : ChatBubbleType.summary,
                              metadata: item.metadata
                          }
                        : index === items.length - 1 &&
                            new Date().getTime() - new Date(item.createdAt).getTime() < answerTime
                          ? typingMessage()
                          : undefined;

                    return { question, answer };
                })
                .reverse()
        );
    }, []);

    useEffect(() => {
        if (availableScrollDownType.current) {
            scrollToBottom(availableScrollDownType.current);
            availableScrollDownType.current = null;
        }
    }, [messages]);

    useEffect(() => {
        const handleStorageEventListener = (event: WindowEventMap["storage"]) => {
            switch (event.key) {
                case ChatEvents.QUESTION: {
                    const chatEvent = objGetter(ChatEvents.QUESTION) as QuestionEvent;
                    if (chatEvent?.chatId === chatId) {
                        availableScrollDownType.current = "smooth";
                        setMessages(prevMessages => [
                            ...prevMessages.map(item =>
                                item.answer?.type === ChatBubbleType.typing
                                    ? { ...item, answer: undefined }
                                    : item
                            ),
                            {
                                question: { ...chatEvent.message, isLoading: false },
                                answer: typingMessage()
                            }
                        ]);
                    }
                    break;
                }
            }
        };

        window.addEventListener("storage", handleStorageEventListener);

        return () => {
            window.removeEventListener("storage", handleStorageEventListener);
        };
    }, [chatId]);

    useEffect(() => {
        if (newMessage && user && chatId) {
            const messageUid = crypto.randomUUID();
            const message = {
                uid: messageUid,
                text: newMessage,
                isLoading: true,
                type: ChatBubbleType.question,
                metadata: null
            };
            availableScrollDownType.current = "smooth";
            setMessages(prevMessages => [...prevMessages, { question: message }]);
            addQuestion(user.uid, chatId, { uuid: messageUid, question: newMessage }).then(
                response => {
                    approveSentMessage(messageUid, response.data.messageId);
                    setDataToLocalStorage(ChatEvents.QUESTION, {
                        chatId: chatId,
                        message: message
                    });
                }
            );

            setNewMessage(null);
        }
    }, [newMessage, user, chatId, setNewMessage, approveSentMessage]);

    useEffect(() => {
        if (!user || !chatId) return;

        if (!location.state?.isNew || !stringGetter("createNewChatKey")) {
            setIsLoading(true);
            fetchChatHistory(user.uid, chatId).then(response => {
                availableScrollDownType.current = "auto";
                setHistoryMessages(response.data.items);
                setIsLoading(false);
            });
        } else {
            removeDataToLocalStorage("createNewChatKey");
        }
        closeReview();
    }, [user, chatId, location.state, setHistoryMessages]);

    useEffect(() => {
        if (!user || !chatId) return;

        const eventSource = listenAnswers(user.uid, chatId);

        eventSource.onopen = () => {
            console.log("~~~ event source opend ~~~");
        };

        eventSource.onmessage = ({ data }) => {
            const message = JSON.parse(data);
            if (message.id || message.error) {
                if (!message.isStreaming) {
                    console.log("New message", JSON.parse(data));
                }

                setMessages(prevMessages => {
                    const list = prevMessages.map(item =>
                        item.answer?.type === ChatBubbleType.typing /// TODO can/will be remove
                            ? { ...item, answer: undefined }
                            : item
                    );
                    const currentMessage = prevMessages.find(
                        item =>
                            item.answer?.id === message.id &&
                            (item.answer?.type === ChatBubbleType.answer ||
                                item.answer?.type === ChatBubbleType.summary ||
                                item.answer?.type === ChatBubbleType.error)
                    );
                    if (currentMessage) {
                        return list.map(item => {
                            const isCurrentMessage =
                                item.answer?.id === message.id &&
                                (item.answer?.type === ChatBubbleType.answer ||
                                    item.answer?.type === ChatBubbleType.summary ||
                                    item.answer?.type === ChatBubbleType.error);
                            if (isCurrentMessage && item.answer) {
                                return message.isWaiting
                                    ? {
                                          ...item,
                                          answer: {
                                              ...item.answer,
                                              text: message.isStreaming
                                                  ? item.answer.text + message.answer
                                                  : message.answer
                                          }
                                      }
                                    : {
                                          ...item,
                                          answer: {
                                              ...item.answer,
                                              text: message.isStreaming
                                                  ? item.answer.text + message.answer
                                                  : message.answer,
                                              type: message.error
                                                  ? ChatBubbleType.error
                                                  : message.question
                                                    ? ChatBubbleType.answer
                                                    : ChatBubbleType.summary,
                                              metadata: message.metadata
                                          }
                                      };
                            }
                            return item;
                        });
                    } else if (!!message.answer || !!message.error) {
                        const answer = {
                            id: message.id,
                            text: !message.error
                                ? message.answer
                                : (message.error?.message ??
                                  "The server had an error. Try again later"),
                            isLoading: false,
                            type: message.error
                                ? ChatBubbleType.error
                                : message.question
                                  ? ChatBubbleType.answer
                                  : ChatBubbleType.summary,
                            metadata: message.metadata
                        };

                        if (list.find(item => item.question?.id === message.id)) {
                            return list.map(item =>
                                item.question?.id === message.id
                                    ? {
                                          ...item,
                                          answer: answer
                                      }
                                    : item
                            );
                        } else {
                            list.push({ answer: answer });
                            return list;
                        }
                    }
                    return prevMessages;
                });
            }
        };

        eventSource.onerror = () => {
            console.log("~~~ error when listening to event source ~~~");
        };

        return () => {
            console.log("~~~ event source closed ~~~");
            eventSource.close();
        };
    }, [accessToken, user, chatId]);

    const handleSummarize = (summaryData: SummaryProps) => {
        if (user && chatId) {
            summarizeVideo(user.uid, chatId, summaryData).then(() => {
                availableScrollDownType.current = "smooth";
                setMessages(prevMessages => [...prevMessages, { answer: typingMessage() }]);
            });
        }
    };

    const handleLink = (url: string) => {
        window.open(url, "_blur");
    };

    const getReviewValue = (feedback?: FeedbackProps) => {
        return feedback?.answerRate === "1"
            ? Feedback.like
            : feedback?.answerRate === "-1"
              ? Feedback.dislike
              : Feedback.noReview;
    };

    const handleReview = (id: number, feedback: Feedback) => {
        switch (feedback) {
            case Feedback.like:
                submitReview("1", id, "");
                break;
            case Feedback.noReview:
                submitReview("0", id, "");
                break;
            case Feedback.dislike:
                submitReview("-1", id, "");
                openReview(id);
                break;
        }
    };

    const submitReview = (value: string, messageId?: number, comment?: string) => {
        if (!messageId || !chatId || !user?.uid) return;

        feedback(user.uid, chatId, {
            messageId: messageId,
            questionContext: "0",
            answerRate: value,
            answerRateComment: comment ?? ""
        });

        updateReview(messageId, value, comment ?? "");
    };

    const updateReview = (id: number, value: string, comment: string) => {
        setMessages(prevMessages =>
            prevMessages.map(item =>
                item.answer?.id === id
                    ? {
                          ...item,
                          answer: {
                              ...item.answer,
                              feedback: {
                                  questionContext: "0",
                                  answerRate: value,
                                  answerRateComment: comment
                              }
                          }
                      }
                    : item
            )
        );
    };

    const openReview = (id: number) => {
        // const chat = document.getElementById("chat-messages");
        // const scroll = document.getElementById("chat-scroll");
        // if (scroll && chat && scroll.clientHeight === chat.clientHeight) {
        //     chat.style.minHeight = "200%";
        //     scroll.scrollTop = chat.clientHeight / 2 - 359 - 32;
        // }

        setActiveMessage(id);
        setTimeout(() => {
            scrollToReviewMessage();
            setTimeout(() => {
                updateReviewScroll();
            }, 500);
        });
    };

    const closeReview = () => {
        setActiveMessage(undefined);
        setReviewScroll(false);

        // const chat = document.getElementById("chat-messages");
        // if (chat) {
        //     chat.style.minHeight = "100%";
        // }
    };

    const updateReviewScroll = () => {
        const activeQuestion = document.getElementById(activeQuestionId);
        const activeAnswer = document.getElementById(activeAnswerId);
        const scroll = document.getElementById("chat-scroll");
        if (!!activeQuestion && !!activeAnswer && !!scroll) {
            const reviewHeight = activeQuestion.clientHeight + activeAnswer.clientHeight + 32;
            setReviewScrollTop(activeQuestion.offsetTop - 16);
            setReviewHeight(reviewHeight);
            setReviewScroll(scroll.clientHeight < reviewHeight);
        } else {
            setReviewScroll(false);
        }
    };

    const handleReviewScroll = (event: UIEvent<HTMLElement>) => {
        if (!activeMessage) {
            const {
                scrollTop,
                scrollHeight,
                clientHeight
            }: { scrollTop: number; scrollHeight: number; clientHeight: number } =
                event.currentTarget;
            setScrolledDown(scrollHeight - scrollTop - clientHeight <= 500);
        } else if (activeMessage && reviewScroll) {
            const { scrollTop, clientHeight }: { scrollTop: number; clientHeight: number } =
                event.currentTarget;
            if (scrollTop < reviewScrollTop) {
                event.currentTarget.scrollTop = reviewScrollTop;
            } else if (scrollTop > reviewScrollTop + reviewHeight - clientHeight) {
                event.currentTarget.scrollTop = reviewScrollTop + reviewHeight - clientHeight;
            }
        }
    };

    const getMessageStyle = (index: number) => {
        if (messages.length - 1 !== index || !chatScrollRef.current) return {};

        return { minHeight: `${chatScrollRef.current.clientHeight - 70 - 16}px` };
    };

    const changeExpanded = (value: boolean, questionId?: number) => {
        if (!questionId) return;

        setMessages(prevMessages =>
            prevMessages.map(item =>
                item.question?.id === questionId
                    ? { ...item, question: { ...item.question, isExpanded: value } }
                    : item
            )
        );
    };

    return (
        <div className="chat-messages-content">
            <div
                ref={chatScrollRef}
                id="chat-scroll"
                className="chat-messages-scroll"
                style={{ overflowY: activeMessage && !reviewScroll ? "hidden" : "auto" }}
                onScroll={handleReviewScroll}
            >
                <div id="chat-messages" className="chat-messages">
                    {messages.length > 0 &&
                        messages.map((message, index) => (
                            <div
                                key={crypto.randomUUID()}
                                style={getMessageStyle(index)}
                                className="chat-bot-result-pair"
                            >
                                {message.question && (
                                    <div
                                        id={
                                            isActiveMessage(message.question)
                                                ? activeQuestionId
                                                : crypto.randomUUID()
                                        }
                                        className={`chat-bot-result__user ${isActiveMessage(message.question) ? "chat-bot-result__active" : ""}`}
                                    >
                                        <ChatBubble
                                            className="chat-bot-result-bubble"
                                            id={message.question.id}
                                            message={message.question.text}
                                            isLoading={message.question.isLoading}
                                            type={message.question.type}
                                            metadata={message.question.metadata}
                                            handleLink={handleLink}
                                            isActive={isActiveMessage(message.question)}
                                            hideAvatars={isMobileDevice()}
                                            isExpanded={message.question.isExpanded}
                                            setExpanded={(value: boolean) =>
                                                changeExpanded(value, message.question?.id)
                                            }
                                        />
                                    </div>
                                )}
                                {message.answer && (
                                    <div
                                        id={
                                            isActiveMessage(message.answer)
                                                ? activeAnswerId
                                                : crypto.randomUUID()
                                        }
                                        className={`chat-bot-result__bot ${isActiveMessage(message.answer) ? "chat-bot-result__active" : ""}`}
                                    >
                                        <ChatBubble
                                            className="chat-bot-result-bubble"
                                            id={message.answer.id}
                                            message={message.answer.text}
                                            isLoading={message.answer.isLoading}
                                            type={message.answer.type}
                                            metadata={message.answer.metadata}
                                            handleLink={handleLink}
                                            handleSummarize={handleSummarize}
                                            handleReview={handleReview}
                                            isActive={isActiveMessage(message.answer)}
                                            review={getReviewValue(message.answer.feedback)}
                                            showReview={isCometTheme}
                                            showSources={isCometTheme}
                                            hideAvatars={isMobileDevice()}
                                        />
                                        {isActiveMessage(message.answer) && (
                                            <div
                                                ref={reviewRef}
                                                className={
                                                    isMobileDevice()
                                                        ? "chat-review--mobile"
                                                        : "chat-review"
                                                }
                                            >
                                                <ChatMessageReview
                                                    closeAction={closeReview}
                                                    submitAction={(comment: string) =>
                                                        submitReview("-1", activeMessage, comment)
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    {messages.length > 0 && <div ref={messagesEndRef} />}
                    {messages.length === 0 && !showDashboard && (
                        <img src={placeholder} alt="" className="chat-placeholder" />
                    )}
                    {messages.length === 0 && showDashboard && <ChatBackground />}
                </div>
            </div>
            <IconButton
                className={`chat-messages-scroll-button${isScrolledDown ? " chat-messages-scroll-button--hidden" : ""}`}
                onClick={() => scrollToBottom("smooth")}
            >
                <SvgIcon component={ArrowExpandIcon} inheritViewBox />
            </IconButton>
            <LoaderView show={isLoading} />
            <ChatMessageReviewBackdrop
                onClick={closeReview}
                open={!!activeMessage}
            ></ChatMessageReviewBackdrop>
        </div>
    );
};
