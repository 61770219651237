import { APIHandler } from "../../base";

export const fetchPrompt = () => APIHandler.get("/admin/prompt");

export const patchPrompt = (value: string) =>
    APIHandler.patch("/admin/prompt", value, {
        headers: {
            "Content-Type": "text/plain"
        }
    });
