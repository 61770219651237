import { useEffect, useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "@mui/material";

import "../uploaded-files/style.scss";
import { FirebaseAuthContainer } from "../../../common/containers/auth/firebase-auth-container.util";
import { trialFileTypes } from "../../../common/utilities/constants/constants";
import {
    fetchProviders,
    fetchContentFolders,
    fetchLearningObjects,
    deleteLearningObjects
} from "../../../api/admin/content-providers/endpoints-referrers";
import { SortType } from "../../../common/enums/sort-type.enum";
import {
    TrialLearningObjectStatus,
    LearningObjectStatus
} from "../../../common/enums/learning-object-status.enum";
import { ProviderType } from "../../../common/enums/provider-type.enum";
import { Provider } from "../../../common/interfaces/admin/provider.interface";
import { LearningObject } from "../../../common/interfaces/admin/learning-object.interface";
import { MenuItem } from "../../../common/interfaces/comps/menu-item.interface";
import { LoaderView } from "../../../components/loader/loader.comp";
import {
    CheckboxMenuFilterT,
    CheckboxMenuFilter,
    SelectionFilter,
    ActionMenu
} from "../../../components/menu-filter/menu-filter.comp";
import TrialLearningObjects from "../../../components/admin/trial-learning-objects/trial-learning-objects.comp";
import ConfirmationPopup, {
    ConfirmationPopupType
} from "../../../components/confirmation-popup/confirmation-popup.comp";

const TrialKnowledgeBaseView = () => {
    const DEFAULT_PAGE_INDEX = 1;
    const DEFAULT_PAGE_SIZE = 50;

    const navigate = useNavigate();
    const { isTrial, user, knowledgeBases } = FirebaseAuthContainer.useContainer();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [providerId, setProviderId] = useState<number>();
    const [courseId, setCourseId] = useState<string>();
    const [learningObjects, setLearningObjects] = useState<LearningObject[]>([]);
    const [checkedLOs, setCheckedLOs] = useState<LearningObject[]>([]);
    const [deletableUids, setDeletableUids] = useState<string[]>([]);
    const [checkedTypes, setCheckedTypes] = useState<MenuItem<string>[]>([]);
    const [checkedStatuses, setCheckedStatuses] = useState<string[]>([]);
    const [selectedSort, setSelectedSort] = useState<string>(SortType.newestFirst);
    const [sortList] = useState<string[]>(Object.values(SortType));
    const [types] = useState<MenuItem<string>[]>(trialFileTypes);
    const [statuses] = useState<string[]>(Object.values(TrialLearningObjectStatus));
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);
    const [knowledgeBaseAvailable, setKnowledgeBaseAvailable] = useState<boolean>(false);

    useEffect(() => {
        if (!knowledgeBases.length) return;

        // TODO: get from page path params. User should choose it on Knowledge Bases page
        const knowledgeBase = knowledgeBases[0];
        setKnowledgeBaseAvailable(knowledgeBase.status === "active");
    }, [knowledgeBases]);

    useEffect(() => {
        if (!user) return;

        setIsLoading(true);
        fetchProviders(user.companyId).then(response => {
            const provider = response.data.find(
                (item: Provider) => item.name === ProviderType.fileUploader
            );
            if (provider) {
                setProviderId(provider.id);
            } else {
                setIsLoading(false);
            }
        });
    }, [user]);

    useEffect(() => {
        if (!user || !providerId) return;

        fetchContentFolders(user.companyId, providerId).then(response => {
            if (response.data.length && response.data[0].courses.length) {
                setCourseId(response.data[0].courses[0].courseId);
            } else {
                setIsLoading(false);
            }
        });
    }, [user, providerId]);

    useEffect(() => {
        if (user && courseId) {
            loadLearningObjects(
                user.companyId,
                courseId,
                createQuery(
                    DEFAULT_PAGE_INDEX,
                    DEFAULT_PAGE_SIZE,
                    checkedTypes,
                    checkedStatuses,
                    selectedSort
                )
            );
        } else {
            setLearningObjects([]);
        }
    }, [user, courseId, checkedTypes, checkedStatuses, selectedSort]);

    const createQuery = (
        page: number,
        pageSize: number,
        checkedTypes: MenuItem<string>[],
        checkedStatuses: string[],
        selectedSort: string
    ) => {
        const queryParts: string[] = [];

        queryParts.push(`page=${page}`);
        queryParts.push(`pageSize=${pageSize}`);

        if (checkedTypes.length > 0) {
            queryParts.push(`sourceType=${checkedTypes.map(type => type.value).join(",")}`);
        }

        if (checkedStatuses.length > 0) {
            queryParts.push(
                `status=${checkedStatuses
                    .map(status => {
                        switch (status) {
                            case TrialLearningObjectStatus.ready:
                                return `${LearningObjectStatus.inUse},${LearningObjectStatus.active}`;
                            case TrialLearningObjectStatus.waiting:
                                return `${LearningObjectStatus.idle},${LearningObjectStatus.recognizing},${LearningObjectStatus.transcribing},${LearningObjectStatus.waitingForDownload},${LearningObjectStatus.waitingForRecognition}`;
                            case TrialLearningObjectStatus.error:
                                return `${LearningObjectStatus.error}`;
                        }
                    })
                    .join(",")}`
            );
        }

        const sortMap = {
            [SortType.newestFirst]: "sortBy=createdAt&sortOrder=desc",
            [SortType.oldestFirst]: "sortBy=createdAt&sortOrder=asc",
            [SortType.az]: "sortBy=name&sortOrder=asc",
            [SortType.za]: "sortBy=name&sortOrder=desc"
        };

        if (selectedSort && sortMap[selectedSort as SortType]) {
            queryParts.push(sortMap[selectedSort as SortType]);
        }

        return `?${queryParts.join("&")}`;
    };

    const loadLearningObjects = (companyId: number, courseId: string, query?: string) => {
        fetchLearningObjects(companyId, courseId, query).then(response => {
            const {
                data: { count, items }
            } = response;
            setTotalCount(count);
            setLearningObjects(items);
            setPageIndex(DEFAULT_PAGE_INDEX);
            setIsLoading(false);
        });
    };

    const appendLearningObjects = (companyId: number, courseId: string, query?: string) => {
        fetchLearningObjects(companyId, courseId, query).then(response => {
            const {
                data: { count, items }
            } = response;
            setTotalCount(count);
            setLearningObjects(prevItems => [...prevItems, ...items]);
            setPageIndex(prevIndex => prevIndex + 1);
        });
    };

    const handleAppendLearningObjects = useCallback(() => {
        if (!user || !courseId) return;

        appendLearningObjects(
            user.companyId,
            courseId,
            createQuery(
                pageIndex + 1,
                DEFAULT_PAGE_SIZE,
                checkedTypes,
                checkedStatuses,
                selectedSort
            )
        );
    }, [user, courseId, pageIndex, checkedTypes, checkedStatuses, selectedSort]);

    const handleDeleteCheckedLearningObjects = () => {
        const uids = checkedLOs.map((item: LearningObject) => item.loItemId);
        if (uids.length > 0) {
            handleDeleteLearningObjects(uids);
        }
    };

    const handleDeleteLearningObjects = (uids: string[]) => {
        setDeletableUids(uids);
        setDeleteConfirmationOpen(true);
    };

    const handleDeleteConfirmationAction = () => {
        if (!user || !courseId || !deletableUids.length) return;

        deleteLearningObjects(user.companyId, courseId, deletableUids).then(() => {
            setLearningObjects(prevLOs =>
                prevLOs.filter(lo => !deletableUids.find(uid => uid === lo.loItemId))
            );
            setTotalCount(prevValue => prevValue - deletableUids.length);
            setCheckedLOs([]);
        });

        setDeleteConfirmationOpen(false);
    };

    const viewRecognitionResult = (uid: string) => {
        navigate(`/recognition-result/${providerId}/${uid}`);
    };

    const handleFilesUpload = () => {
        navigate("/files-upload");
    };

    const handleTryKnowledgeBase = () => {
        const host = window.location.host.replace("admin.", "");
        window.open(`${window.location.protocol}//${host}`, "_self");
    };

    return (
        <div className="uploaded-files-view">
            <div className="uploaded-files-header">
                <span className="uploaded-files-header-title">Uploaded Files</span>
                <div className="uploaded-files-header-trial">
                    {isTrial && (
                        <>
                            <span className="uploaded-files-header-trial__message">
                                You can upload up to 10 files in trial version.
                            </span>
                            <Link className="uploaded-files-header-trial__link" to="/feedback">
                                Upgrade to full version
                            </Link>
                        </>
                    )}
                </div>
                <Button
                    className="uploaded-files-footer-button"
                    variant="text"
                    onClick={handleFilesUpload}
                >
                    Upload Files
                </Button>
            </div>
            <div className="uploaded-files-filters">
                <div className="file-table-filter file-table-filter__actions">
                    <ActionMenu
                        label="Action"
                        disabled={checkedLOs.length === 0}
                        actions={[{ name: "Delete", action: handleDeleteCheckedLearningObjects }]}
                        minWidth={160}
                    />
                </div>
                <div className="file-table-filter file-table-filter__types">
                    <CheckboxMenuFilterT
                        label="Type"
                        list={types}
                        checkedList={checkedTypes}
                        setCheckedList={setCheckedTypes}
                        showCounter={true}
                        minWidth={116}
                    />
                </div>
                <div className="file-table-filter file-table-filter__status">
                    <CheckboxMenuFilter
                        label="Status"
                        list={statuses}
                        checkedList={checkedStatuses}
                        setCheckedList={setCheckedStatuses}
                        minWidth={100}
                    />
                </div>
                <div className="file-table-filter file-table-filter__sorting">
                    <SelectionFilter
                        list={sortList}
                        selectedItem={selectedSort}
                        setSelectedItem={setSelectedSort}
                        rightPosition={true}
                        minWidth={142}
                    />
                </div>
            </div>
            <div className="uploaded-files-table">
                <TrialLearningObjects
                    totalCount={totalCount}
                    learningObjects={learningObjects}
                    checkedLOs={checkedLOs}
                    setCheckedLOs={setCheckedLOs}
                    handleAppendLOs={handleAppendLearningObjects}
                    handleDelete={handleDeleteLearningObjects}
                    handleAIResult={viewRecognitionResult}
                />
                <LoaderView show={isLoading} />
            </div>
            <div className="uploaded-files-footer">
                {!knowledgeBaseAvailable && (
                    <span className="uploaded-files-footer-message">
                        It may take from 5 min. up to an hour for AI to process your files.
                    </span>
                )}
                <Button
                    className="uploaded-files-footer-button"
                    variant="text"
                    disabled={!knowledgeBaseAvailable}
                    onClick={handleTryKnowledgeBase}
                >
                    Try your Knowledge Base
                </Button>
            </div>
            <ConfirmationPopup
                open={deleteConfirmationOpen}
                type={ConfirmationPopupType.dangerous}
                title="Delete"
                description={`You are trying to delete ${deletableUids.length} file(s).
                              This can't be undone!`}
                actionName="Delete"
                handleAction={handleDeleteConfirmationAction}
                handleClose={() => setDeleteConfirmationOpen(false)}
            />
        </div>
    );
};

export default TrialKnowledgeBaseView;
