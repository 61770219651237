import { Fragment, useState, MouseEvent } from "react";
import { IconButton, SvgIcon, Menu } from "@mui/material";

import "./style.scss";
import { ReactComponent as GearIcon } from "../../assets/gearIcon.svg";
import { ReactComponent as MoreIcon } from "../../assets/moreIcon.svg";

export enum SettingItemType {
    default,
    dangerous
}

export enum SettingMenuType {
    default,
    more
}

export interface SettingItem {
    name: string;
    type: SettingItemType;
    handler: () => void;
}

export const SettingMenu = ({
    actions,
    type = SettingMenuType.default,
    className = ""
}: {
    actions: SettingItem[];
    type?: SettingMenuType;
    className?: string;
}) => {
    const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
    const menuOpen = Boolean(menuAnchor);

    const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleMenuItem = (handler: () => void) => {
        handler();
        handleMenuClose();
    };

    return (
        <Fragment>
            {type === SettingMenuType.more ? (
                <div className={`more-menu-icon-button ${className}`} onClick={handleMenuClick}>
                    <SvgIcon component={MoreIcon} inheritViewBox />
                </div>
            ) : (
                <IconButton
                    className={`setting-menu-icon-button ${className}`}
                    onClick={handleMenuClick}
                >
                    <SvgIcon component={GearIcon} inheritViewBox />
                </IconButton>
            )}

            <Menu
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        className: "setting-dropdown-menu"
                    }
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {actions.map(action => (
                    <div
                        key={action.name}
                        className={`setting-dropdown-menu-item${action.type == SettingItemType.dangerous ? " setting-dropdown-menu-item--dangerous" : ""}`}
                        onClick={() => handleMenuItem(action.handler)}
                    >
                        {action.name}
                    </div>
                ))}
            </Menu>
        </Fragment>
    );
};
