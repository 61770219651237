import { useState, useEffect, useCallback, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import MicIcon from "@mui/icons-material/MicNone";
import createSpeechServicesPonyfill from "web-speech-cognitive-services";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import "./style.scss";
import { ListeningIndicator } from "../listening-loader/listening-indicator.comp";
import { getAzureSpeachToken } from "../../api/auth/endpoints-referrers";
import { isChromium, isMobileDevice } from "../../common/utilities/platform/platform.util";

const useAdaptiveSpeechRecognition = ({
    mode,
    setListening,
    setTranscript,
    setRecognizedPhrase,
    handleErrorMessage
}: {
    mode: RecognitionMode;
    setListening: (arg1: boolean) => void;
    setTranscript: (arg1: string) => void;
    setRecognizedPhrase: (arg1: string) => void;
    handleErrorMessage?: (arg1: string) => void;
}) => {
    enum Action {
        START,
        STOP,
        AWAIT
    }

    const listeningRef = useRef<boolean>();
    const continuousSpeech = useRef<boolean>(true);
    const lastActionRef = useRef<Action>();
    const microphonePermissionsTimeout = useRef<number>();
    const latestTranscript = useRef<string>("");
    const { transcript, listening, resetTranscript, isMicrophoneAvailable } =
        useSpeechRecognition();

    useEffect(() => {
        latestTranscript.current = transcript;
        setTranscript(transcript);
    }, [transcript, setTranscript]);

    useEffect(() => {
        setListening(listening);
        listeningRef.current = listening;
        if (!listening && latestTranscript.current) {
            setRecognizedPhrase(latestTranscript.current);
            resetTranscript();
        }
    }, [listening, setListening, setRecognizedPhrase, resetTranscript]);

    function stopRecognizeSpeech() {
        if (lastActionRef.current == Action.AWAIT) return;

        lastActionRef.current = Action.AWAIT;
        setTimeout(() => {
            lastActionRef.current = Action.STOP;
            SpeechRecognition.stopListening();
        }, 1000);
    }

    function stopHoldToTalk() {
        if (lastActionRef.current == Action.AWAIT) return;

        lastActionRef.current = Action.AWAIT;
        setTimeout(() => {
            lastActionRef.current = Action.STOP;
            SpeechRecognition.abortListening().catch(error => {
                console.error(error);
                setTimeout(() => {
                    SpeechRecognition.abortListening();
                }, 1000);
            });
        }, 1000);
    }

    function stopCombinedRecognition(byMouseLeave: boolean) {
        if (listeningRef.current) {
            if (continuousSpeech.current) {
                stopHoldToTalk();
            } else {
                if (byMouseLeave) return;
                stopRecognizeSpeech();
            }
        } else {
            continuousSpeech.current = false;
        }
    }

    async function startRecognizeSpeech() {
        try {
            lastActionRef.current = Action.START;
            await navigator.mediaDevices.getUserMedia({ audio: true });
            startSpeechListening(false);
        } catch (refreshError) {
            if (handleErrorMessage) {
                handleErrorMessage("Please allow access to your microphone");
            }
        }
    }

    async function startHoldToTalk() {
        if (lastActionRef.current == Action.AWAIT) return;

        try {
            lastActionRef.current = Action.START;

            microphonePermissionsTimeout.current = window.setTimeout(() => {
                stopHoldToTalk();
            }, 500);

            await navigator.mediaDevices.getUserMedia({ audio: true });
            window.clearTimeout(microphonePermissionsTimeout.current);
            if (lastActionRef.current !== Action.START) {
                return;
            }
            startSpeechListening(true);
        } catch (refreshError) {
            if (handleErrorMessage) {
                handleErrorMessage("Please allow access to your microphone");
            }
        }
    }

    async function startCombinedRecognition() {
        if (lastActionRef.current == Action.AWAIT || listeningRef.current) return;

        try {
            continuousSpeech.current = true;
            lastActionRef.current = Action.START;

            microphonePermissionsTimeout.current = window.setTimeout(() => {
                continuousSpeech.current = false;
            }, 500);

            await navigator.mediaDevices.getUserMedia({ audio: true });
            window.clearTimeout(microphonePermissionsTimeout.current);

            setTimeout(() => {
                startSpeechListening(continuousSpeech.current);
            }, 100);
        } catch (refreshError) {
            if (handleErrorMessage) {
                handleErrorMessage("Please allow access to your microphone");
            }
        }
    }

    async function startSpeechListening(continuous: boolean) {
        if (!isChromium()) {
            const speechRegion = process.env.REACT_APP_AZURE_SPEECH_REGION;
            const { data: speechToken } = await getAzureSpeachToken();
            if (lastActionRef.current !== Action.START) {
                return;
            }
            const { SpeechRecognition: AzureSpeechRecognition } = createSpeechServicesPonyfill({
                credentials: {
                    region: speechRegion,
                    authorizationToken: speechToken
                }
            });
            SpeechRecognition.applyPolyfill(AzureSpeechRecognition);
        }
        SpeechRecognition.startListening({
            continuous: continuous,
            language: "en-US"
        });
    }

    const stop = (byMouseLeave = false) => {
        switch (mode as RecognitionMode) {
            case RecognitionMode.RECOGNIZE_SPEECH:
                stopRecognizeSpeech();
                break;
            case RecognitionMode.HOLD_TO_TALK:
                stopHoldToTalk();
                break;
            case RecognitionMode.COMBINED:
                stopCombinedRecognition(byMouseLeave);
                break;
        }
    };

    const start = async () => {
        if (isMicrophoneAvailable) {
            switch (mode as RecognitionMode) {
                case RecognitionMode.RECOGNIZE_SPEECH:
                    startRecognizeSpeech();
                    break;
                case RecognitionMode.HOLD_TO_TALK:
                    startHoldToTalk();
                    break;
                case RecognitionMode.COMBINED:
                    startCombinedRecognition();
                    break;
            }
        }
    };

    return { start, stop };
};

export enum RecognitionMode {
    COMBINED,
    HOLD_TO_TALK,
    RECOGNIZE_SPEECH
}

export const RecognitionButton = ({
    onRecognized,
    setSpeechListening,
    setSpeechTranscript,
    handleErrorMessage,
    size,
    className = "",
    mode = RecognitionMode.RECOGNIZE_SPEECH
}: {
    onRecognized: (arg1: string) => void;
    setSpeechListening: (arg1: boolean) => void;
    setSpeechTranscript: (arg1: string) => void;
    handleErrorMessage?: (arg1: string) => void;
    size: number;
    className: string;
    mode?: RecognitionMode;
}) => {
    const isHoldDownMode =
        mode === RecognitionMode.HOLD_TO_TALK || mode === RecognitionMode.COMBINED;
    const [isListening, setListening] = useState(false);
    const [transcript, setTranscript] = useState("");
    const [recognizedPhrase, setRecognizedPhrase] = useState("");
    const [speechRecognition] = useState(
        useAdaptiveSpeechRecognition({
            mode,
            setListening,
            setTranscript,
            setRecognizedPhrase,
            handleErrorMessage
        })
    );

    useEffect(() => {
        setSpeechTranscript(transcript);
    }, [setSpeechTranscript, transcript]);

    useEffect(() => {
        setSpeechListening(isListening);
    }, [setSpeechListening, isListening]);

    useEffect(() => {
        if (onRecognized && recognizedPhrase) {
            onRecognized(recognizedPhrase);
            setRecognizedPhrase("");
        }
    }, [recognizedPhrase, onRecognized]);

    const onClickStartRecognition = useCallback(() => {
        !isListening ? speechRecognition?.start() : speechRecognition?.stop();
    }, [isListening, speechRecognition]);

    const onHoldDownStart = useCallback(() => {
        speechRecognition?.start();
    }, [speechRecognition]);

    const onHoldDownStop = useCallback(
        (byMouseLeave: boolean) => {
            speechRecognition?.stop(byMouseLeave);
        },
        [speechRecognition]
    );

    return (
        <IconButton
            className={`${isListening ? "recognition-button " : ""}${className ?? ""}`}
            color="primary"
            onClick={!isHoldDownMode ? onClickStartRecognition : undefined}
            onMouseDown={isHoldDownMode && !isMobileDevice() ? onHoldDownStart : undefined}
            onMouseUp={
                isHoldDownMode && !isMobileDevice() ? () => onHoldDownStop(false) : undefined
            }
            onMouseLeave={
                isHoldDownMode && !isMobileDevice() && isListening
                    ? () => onHoldDownStop(true)
                    : undefined
            }
            onTouchStart={isHoldDownMode && isMobileDevice() ? onHoldDownStart : undefined}
            onTouchEnd={
                isHoldDownMode && isMobileDevice() ? () => onHoldDownStop(false) : undefined
            }
        >
            <ListeningIndicator size={size} className={!isListening ? "hide-icon" : ""} />
            <MicIcon className={isListening ? "hide-icon" : ""} />
        </IconButton>
    );
};
