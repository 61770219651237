export enum LearningObjectStatus {
    idle = "idle",
    inUse = "inUse",
    active = "active",
    recognizing = "recognizing",
    transcribing = "transcribing",
    waitingForDownload = "waitingForDownload",
    waitingForRecognition = "waitingForRecognition",
    error = "error"
}

export enum TrialLearningObjectStatus {
    ready = "Ready",
    waiting = "Please wait",
    error = "Error"
}
