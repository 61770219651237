import { useState, useEffect, useCallback, UIEvent } from "react";
import { Button, IconButton } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import Checkbox from "@mui/material/Checkbox";

import "../file-table/style.scss";
import TivaPlaceholder from "../../../assets/placeholderTiva.svg";
import { ReactComponent as CheckboxIcon } from "../../../assets/checkboxIcon.svg";
import { ReactComponent as CheckboxCheckedIcon } from "../../../assets/checkboxCheckedIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/deleteIcon.svg";
import {
    LearningObjectStatus,
    TrialLearningObjectStatus
} from "../../../common/enums/learning-object-status.enum";
import { LearningObject } from "../../../common/interfaces/admin/learning-object.interface";
import { RecognitionResultStatus } from "../../../common/enums/recognition-result-status.enum";
import { Loader } from "../../loader/loader.comp";

const TrialLearningObjects = ({
    totalCount,
    learningObjects,
    checkedLOs,
    setCheckedLOs,
    handleAppendLOs,
    handleDelete,
    handleAIResult
}: {
    totalCount?: number;
    learningObjects: LearningObject[];
    checkedLOs: LearningObject[];
    setCheckedLOs: (arg1: LearningObject[]) => void;
    handleAppendLOs?: () => void;
    handleDelete?: (arg1: string[]) => void;
    handleAIResult?: (arg1: string) => void;
}) => {
    const scrollDelata = 250;
    const [appendAvailable, setAppendAvailable] = useState<boolean>(true);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    useEffect(() => {
        setShowLoader(!!handleAppendLOs && !!totalCount && totalCount !== learningObjects.length);
    }, [learningObjects, totalCount, handleAppendLOs]);

    const handleChangeSelectAll = useCallback(() => {
        setCheckedLOs(learningObjects.length === checkedLOs.length ? [] : learningObjects);
    }, [learningObjects, checkedLOs, setCheckedLOs]);

    const handleChangeSelect = useCallback(
        (item: LearningObject) => {
            const newFiles = [...checkedLOs];
            const index = newFiles.indexOf(item);
            index !== -1 ? newFiles.splice(index, 1) : newFiles.push(item);
            setCheckedLOs(newFiles);
        },
        [checkedLOs, setCheckedLOs]
    );

    const handleAIResultClick = (item: LearningObject) => {
        if (handleAIResult && item.recognitionResults.length) {
            handleAIResult(item.recognitionResults[0].uid);
        }
    };

    const handleDeleteClick = (item: LearningObject) => {
        if (handleDelete) {
            handleDelete([item.loItemId]);
        }
    };

    const fileStatusColor = (status: string) => {
        switch (status as LearningObjectStatus) {
            case LearningObjectStatus.active:
            case LearningObjectStatus.inUse:
                return "green";
            case LearningObjectStatus.idle:
            case LearningObjectStatus.recognizing:
            case LearningObjectStatus.transcribing:
            case LearningObjectStatus.waitingForDownload:
            case LearningObjectStatus.waitingForRecognition:
                return "yellow";
            case LearningObjectStatus.error:
                return "red";
            default:
                return "default";
        }
    };

    const fileStatusName = (status: string) => {
        switch (status as LearningObjectStatus) {
            case LearningObjectStatus.active:
            case LearningObjectStatus.inUse:
                return TrialLearningObjectStatus.ready;
            case LearningObjectStatus.idle:
            case LearningObjectStatus.recognizing:
            case LearningObjectStatus.transcribing:
            case LearningObjectStatus.waitingForDownload:
            case LearningObjectStatus.waitingForRecognition:
                return TrialLearningObjectStatus.waiting;
            case LearningObjectStatus.error:
                return TrialLearningObjectStatus.error;
            default:
                return status;
        }
    };

    const isAIResultAvailable = (item: LearningObject) => {
        if (!item.recognitionResults.length) return false;

        const status = item.recognitionResults[0].status;

        return (
            status === RecognitionResultStatus.active ||
            status === RecognitionResultStatus.published ||
            status === RecognitionResultStatus.recognized ||
            status === RecognitionResultStatus.generatingSummary ||
            status === RecognitionResultStatus.waitingForSummary
        );
    };

    const handleListScroll = (event: UIEvent<HTMLElement>) => {
        if (!handleAppendLOs || (totalCount && totalCount === learningObjects.length)) return;

        const scrollBottom =
            event.currentTarget.scrollHeight -
            (event.currentTarget.scrollTop + event.currentTarget.offsetHeight);

        if (appendAvailable && scrollBottom < scrollDelata) {
            setAppendAvailable(false);
            handleAppendLOs();
        } else if (scrollBottom >= scrollDelata) {
            setAppendAvailable(true);
        }
    };

    return (
        <div className="file-table-view">
            {learningObjects.length > 0 ? (
                <div className="file-table">
                    <div className="file-table-header">
                        <div className="file-table-header-cell file-table-cell__select">
                            <Checkbox
                                checked={learningObjects.length === checkedLOs.length}
                                onChange={handleChangeSelectAll}
                                icon={<CheckboxIcon />}
                                checkedIcon={<CheckboxCheckedIcon />}
                            />
                        </div>
                        <div className="file-table-header-cell file-table-cell__name">
                            File Name
                        </div>
                        <div className="file-table-header-cell file-table-cell__status">Status</div>
                        <div className="file-table-header-cell file-table-cell__result"></div>
                        <div className="file-table-header-cell file-table-cell__delete"></div>
                    </div>
                    <div className="file-table-body" onScroll={handleListScroll}>
                        {learningObjects.map(item => (
                            <div key={item.loItemId} className="file-table-row">
                                <div className="file-table-cell file-table-cell__select">
                                    <Checkbox
                                        checked={checkedLOs.includes(item)}
                                        onChange={() => handleChangeSelect(item)}
                                        icon={<CheckboxIcon />}
                                        checkedIcon={<CheckboxCheckedIcon />}
                                    />
                                </div>
                                <div className="file-table-cell file-table-cell__name">
                                    <span className="table-file-name">{item.name}</span>
                                </div>
                                <div className="file-table-cell file-table-cell__status">
                                    <div
                                        className={`table-file-status table-file-status__${fileStatusColor(item.status)}`}
                                    >
                                        <div className="table-file-status__circle"></div>
                                        <span className="table-file-status__title">
                                            {fileStatusName(item.status)}
                                        </span>
                                    </div>
                                </div>
                                <div className="file-table-cell file-table-cell__result">
                                    <Button
                                        className="table-file__button-result"
                                        variant="outlined"
                                        disabled={!isAIResultAvailable(item)}
                                        onClick={() => handleAIResultClick(item)}
                                    >
                                        {isAIResultAvailable(item) ? "AI Result" : "Loading"}
                                    </Button>
                                </div>
                                <div className="file-table-cell file-table-cell__delete">
                                    <IconButton
                                        className="table-file__button-delete"
                                        onClick={() => handleDeleteClick(item)}
                                    >
                                        <SvgIcon component={DeleteIcon} inheritViewBox />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                        {showLoader && (
                            <div className="file-table-row file-table-row__loader">
                                <Loader size={24} borderWidth={4} />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="file-table-placeholder">
                    <img src={TivaPlaceholder} alt="" />
                </div>
            )}
        </div>
    );
};

export default TrialLearningObjects;
