import { useEffect, useState, ChangeEvent } from "react";
import { Link, Params, useParams, useNavigate } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";

import "./style.scss";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/arrowLeftIcon.svg";
import { FirebaseAuthContainer } from "../../../common/containers/auth/firebase-auth-container.util";
import {
    fetchRecognitionResult,
    saveRecognitionResult
} from "../../../api/admin/recognition-result/endpoints-referrers";
import { fetchLearningObject } from "../../../api/admin/content-providers/endpoints-referrers";
import { RecognitionResult } from "../../../common/interfaces/admin/recognition-result.interface";
import { LoaderView } from "../../../components/loader/loader.comp";
import { RRSelectionFilter } from "../../../components/admin/rr-selection-filter/rr-selection-filter.comp";

const RecognitionResultView = () => {
    const navigate = useNavigate();
    const { providerId, uid } = useParams<Params<string>>();
    const { isTrial, user } = FirebaseAuthContainer.useContainer();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [recognitionResult, setRecognitionResult] = useState<RecognitionResult>();
    const [rrText, setRrText] = useState<string>();
    const [rrList, setRrList] = useState<RecognitionResult[]>([]);

    useEffect(() => {
        setIsLoading(true);

        if (!user || !uid) return;

        fetchRecognitionResult(user.companyId, uid).then(response => {
            setRecognitionResult(response.data);
        });
    }, [user, uid]);

    useEffect(() => {
        if (isTrial) {
            setIsLoading(false);
            return;
        }
        if (!user || !recognitionResult) return;

        fetchLearningObject(user.companyId, recognitionResult.courseId, recognitionResult.loItemId)
            .then(response => {
                setRrList(response.data.recognitionResults);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [isTrial, user, recognitionResult]);

    useEffect(() => {
        setRrText(recognitionResult?.recognitionResultData?.loItemText ?? "");
    }, [recognitionResult]);

    const handleBackClick = () => {
        if (recognitionResult) {
            const link = !isTrial
                ? `/knowledge-base/${providerId}/${recognitionResult.courseId}`
                : `/uploaded-files`;
            navigate(link);
        }
    };

    const handleChangeSelectedRR = (rr: RecognitionResult) => {
        if (!providerId) return;
        navigate(`/recognition-result/${providerId}/${rr.uid}`);
    };

    const handleCompareClick = () => {
        if (!recognitionResult || !providerId) return;
        navigate(`/compare-recognition-result/${providerId}/${recognitionResult.uid}`);
    };

    const handleSaveClick = () => {
        if (!user || !uid) return;

        setIsLoading(true);
        saveRecognitionResult(user.companyId, uid, {
            loItemText: rrText ?? "",
            summary: recognitionResult?.recognitionResultData?.summary ?? "",
            isPublish: true
        })
            .then(response => {
                const {
                    data: { uid }
                } = response;
                navigate(`/recognition-result/${providerId}/${uid}`);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setRrText(event.target.value);
    };

    return (
        <div className="recognition-result-view">
            <div className="recognition-result-header">
                <IconButton
                    className="recognition-result-header__button-back"
                    onClick={handleBackClick}
                >
                    <SvgIcon component={ArrowLeftIcon} inheritViewBox />
                </IconButton>
                <div className="recognition-result-header-title">
                    <span className="recognition-result-header-title__text-name">Review RR</span>
                </div>
            </div>
            {!isTrial && (
                <div className="recognition-result-filter">
                    <div className="recognition-result-filter__select">
                        <RRSelectionFilter
                            list={rrList}
                            selectedItem={recognitionResult}
                            setSelectedItem={handleChangeSelectedRR}
                            rightPosition={true}
                            maxWidth={600}
                        />
                    </div>
                </div>
            )}
            <div className="recognition-result-content">
                <textarea
                    className={`recognition-result-content__textarea${isTrial ? " recognition-result-content__textarea--trial" : ""}`}
                    onChange={handleTextChange}
                    value={rrText}
                />
            </div>
            <div
                className={`recognition-result-footer${isTrial ? " recognition-result-footer--trial" : ""}`}
            >
                {!isTrial ? (
                    <>
                        <Button
                            className="recognition-result-footer__button recognition-result-footer__button--compare"
                            variant="outlined"
                            disabled={rrList.length < 2}
                            onClick={handleCompareClick}
                        >
                            Compare
                        </Button>
                        <Button
                            className="recognition-result-footer__button recognition-result-footer__button--save"
                            variant="outlined"
                            disabled={
                                recognitionResult?.recognitionResultData?.loItemText === rrText
                            }
                            onClick={handleSaveClick}
                        >
                            Save & Publish
                        </Button>
                    </>
                ) : (
                    <>
                        <span className="recognition-result-footer__message">
                            You can’t edit results in trial version.
                        </span>
                        <Link className="recognition-result-footer__link" to="/feedback">
                            Upgrade to full version
                        </Link>
                    </>
                )}
            </div>
            <LoaderView show={isLoading} />
        </div>
    );
};

export default RecognitionResultView;
