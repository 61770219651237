import { useEffect, useState, useCallback } from "react";
import { Params, useParams, useNavigate } from "react-router-dom";

import "./style.scss";
import CometPlaceholder from "../../../assets/placeholderComet.svg";
import TivaPlaceholder from "../../../assets/placeholderTiva.svg";
import { ThemeContainer } from "../../../common/containers/theme/theme.container";
import { FirebaseAuthContainer } from "../../../common/containers/auth/firebase-auth-container.util";
import {
    fetchProviders,
    fetchContentFolders,
    fetchLearningObjects,
    deleteLearningObjects,
    deleteContentItemProvider
} from "../../../api/admin/content-providers/endpoints-referrers";
import { publishRecognitionResult } from "../../../api/admin/recognition-result/endpoints-referrers";
import { Theme } from "../../../common/enums/theme.enum";
import { ProviderType } from "../../../common/enums/provider-type.enum";
import { Provider } from "../../../common/interfaces/admin/provider.interface";
import { Folder } from "../../../common/interfaces/admin/folder.interface";
import { RecognitionResult } from "../../../common/interfaces/admin/recognition-result.interface";
import {
    TableLearningObject,
    LearningObject
} from "../../../common/interfaces/admin/learning-object.interface";
import { LoaderView } from "../../../components/loader/loader.comp";
import { ActionMenu } from "../../../components/menu-filter/menu-filter.comp";
import {
    SettingMenu,
    SettingItem,
    SettingItemType
} from "../../../components/setting-menu/setting-menu.comp";
import MenuPanel from "../../../components/admin/menu-panel/menu-panel.comp";
import CoursesTree from "../../../components/admin/courses-tree/courses-tree.comp";
import LearningObjects from "../../../components/admin/learning-objects/learning-objects.comp";
import ConfirmationPopup, {
    ConfirmationPopupType
} from "../../../components/confirmation-popup/confirmation-popup.comp";

const KnowledgeBaseElements = () => {
    const DEFAULT_PAGE_INDEX = 1;
    const DEFAULT_PAGE_SIZE = 50;

    const navigate = useNavigate();
    const { providerId, courseId } = useParams<Params<string>>();
    const { user } = FirebaseAuthContainer.useContainer();
    const { theme } = ThemeContainer.useContainer();
    const [isCometTheme] = useState<boolean>(theme === Theme.comet);
    const [isDefaultAdminTheme] = useState<boolean>(theme === Theme.tivaAdmin);
    const [currentProvider, setCurrentProvider] = useState<Provider>();
    const [providers, setProviders] = useState<Provider[]>([]);
    const [settings, setSettings] = useState<SettingItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [folderTree, setFolderTree] = useState<Folder[]>([]);
    const [learningObjects, setLearningObjects] = useState<TableLearningObject[]>([]);
    const [checkedLOs, setCheckedLOs] = useState<TableLearningObject[]>([]);
    const [selectionMode] = useState<boolean>(false);
    const [deletableUids, setDeletableUids] = useState<string[]>([]);
    const [loTotalCount, setLoTotalCount] = useState<number>(0);
    const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
    const [editableRR, setEditableRR] = useState<RecognitionResult>();
    const [deleteLOsConfirmationOpen, setDeleteLOsConfirmationOpen] = useState<boolean>(false);
    const [deleteDriveConfirmationOpen, setDeleteDriveConfirmationOpen] = useState<boolean>(false);
    const [publishConfirmationOpen, setPublishConfirmationOpen] = useState<boolean>(false);
    const [unpublishConfirmationOpen, setUnpublishConfirmationOpen] = useState<boolean>(false);
    const [publishSubtitle, setPublishSubtitle] = useState<string>();
    const publishDescription = `When you publish one version of the document,
    all other versions will be unpublished. Knowledge Base will use published
    version for answering questions regarding this document.`;
    const unpublishDescription = `When you unpublish all documents, Knowledge Base will no longer be
    able to answer questions regarding this document.`;

    useEffect(() => {
        if (!user) return;

        fetchProviders(user.companyId).then(response => {
            setProviders(response.data);
        });
    }, [user]);

    useEffect(() => {
        if (!providers.length || providerId) return;

        const provider = providers.find(
            provider =>
                provider.name === (isCometTheme ? ProviderType.docebo : ProviderType.fileUploader)
        );
        if (provider) {
            navigate(`/knowledge-base/${provider.id}`);
        }
    }, [navigate, isCometTheme, providers, providerId]);

    useEffect(() => {
        const currentProviderId = Number(providerId);
        if (!user || isNaN(currentProviderId)) return;

        fetchContentFolders(user.companyId, currentProviderId).then(response => {
            // TODO response type will be changed
            setFolderTree(response.data.length ? response.data[0].courses : []);
            const provider: Provider = response.data.length ? response.data[0] : undefined;
            if (provider) {
                setCurrentProvider(provider);
                if (provider.name == ProviderType.googleDrive) {
                    setSettings([
                        {
                            name: "Delete",
                            type: SettingItemType.dangerous,
                            handler: () => {
                                setDeleteDriveConfirmationOpen(true);
                            }
                        }
                    ]);
                }
            }
        });
    }, [user, providerId]);

    useEffect(() => {
        if (user && courseId) {
            loadLearningObjects(
                user.companyId,
                courseId,
                createQuery(DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, courseId === "0") // TODO temporary solution to sort by date
            );
        } else {
            setLearningObjects([]);
        }
    }, [user, courseId]);

    const createQuery = (page: number, pageSize: number, sortByDate: boolean) => {
        const queryParts: string[] = [];

        queryParts.push(`page=${page}`);
        queryParts.push(`pageSize=${pageSize}`);

        // TODO temporary solution to sort by date
        queryParts.push(`sortBy=${sortByDate ? "createdAt" : "name"}`);
        queryParts.push(`sortOrder=${sortByDate ? "desc" : "asc"}`);

        return `?${queryParts.join("&")}`;
    };

    const loadLearningObjects = (companyId: number, courseId: string, query?: string) => {
        setIsLoading(true);
        fetchLearningObjects(companyId, courseId, query).then(response => {
            const {
                data: { count, items }
            } = response;
            setLoTotalCount(count);
            setLearningObjects(items.map((item: LearningObject) => ({ ...item, showRR: false })));
            setPageIndex(DEFAULT_PAGE_INDEX);
            setIsLoading(false);
        });
    };

    const appendLearningObjects = (companyId: number, courseId: string, query?: string) => {
        fetchLearningObjects(companyId, courseId, query).then(response => {
            const {
                data: { count, items }
            } = response;
            setLoTotalCount(count);
            setLearningObjects(prevItems => [
                ...prevItems,
                ...items.map((item: LearningObject) => ({ ...item, showRR: false }))
            ]);
            setPageIndex(prevIndex => prevIndex + 1);
        });
    };

    const handleAppendLearningObjects = useCallback(() => {
        if (!user || !courseId) return;

        appendLearningObjects(
            user.companyId,
            courseId,
            createQuery(pageIndex + 1, DEFAULT_PAGE_SIZE, courseId === "0") // TODO temporary solution to sort by date
        );
    }, [user, courseId, pageIndex]);

    const handleChangePublishStatus = (rr: RecognitionResult, loName: string) => {
        setEditableRR(rr);
        setPublishSubtitle(`${loName} : ${rr.name} v.${rr.version}`);
        rr.isPublish ? setUnpublishConfirmationOpen(true) : setPublishConfirmationOpen(true);
    };

    const handleDeleteCheckedLearningObjects = () => {
        const uids = checkedLOs.map((item: LearningObject) => item.loItemId);
        if (uids.length > 0) {
            handleDeleteLearningObjects(uids);
        }
    };

    const handleDeleteLearningObjects = (uids: string[]) => {
        setDeletableUids(uids);
        setDeleteLOsConfirmationOpen(true);
    };

    const handleDeleteLOsConfirmationAction = () => {
        if (!user || !courseId || !deletableUids.length) return;

        deleteLearningObjects(user.companyId, courseId, deletableUids).then(() => {
            setLearningObjects(prevLOs =>
                prevLOs.filter(lo => !deletableUids.find(uid => uid === lo.loItemId))
            );
            setLoTotalCount(prevValue => prevValue - deletableUids.length);
            setCheckedLOs([]);
        });

        setDeleteLOsConfirmationOpen(false);
    };

    const handleDeleteDriveConfirmationAction = () => {
        if (!user || !currentProvider) return;

        deleteContentItemProvider(user.companyId, currentProvider.id).then(() => {
            setProviders(prevProviders =>
                prevProviders.filter(provider => provider.id !== currentProvider.id)
            );
            const provider = providers.find(provider => provider.name === ProviderType.docebo);
            if (provider) {
                navigate(`/knowledge-base/${provider.id}`);
            }
        });
        setDeleteDriveConfirmationOpen(false);
    };

    const handlePublishConfirmationAction = () => {
        if (!user || !editableRR) return;

        publishRecognitionResult(user.companyId, editableRR.uid, true).then(() => {
            setLearningObjects(loList =>
                loList.map(lo =>
                    lo.loItemId === editableRR.loItemId
                        ? {
                              ...lo,
                              recognitionResults: lo.recognitionResults.map(rr => ({
                                  ...rr,
                                  isPublish: rr.uid === editableRR.uid
                              }))
                          }
                        : lo
                )
            );
        });
        setPublishConfirmationOpen(false);
    };

    const handleUnpublishConfirmationAction = () => {
        if (!user || !editableRR) return;

        publishRecognitionResult(user.companyId, editableRR.uid, false).then(() => {
            setLearningObjects(loList =>
                loList.map(lo =>
                    lo.loItemId === editableRR.loItemId
                        ? {
                              ...lo,
                              recognitionResults: lo.recognitionResults.map(rr =>
                                  rr.uid === editableRR.uid ? { ...rr, isPublish: false } : rr
                              )
                          }
                        : lo
                )
            );
        });
        setUnpublishConfirmationOpen(false);
    };

    const selectCourse = (courseId: string) => {
        navigate(`/knowledge-base/${providerId}/${courseId}`);
    };

    const viewRecognitionResult = (uid: string) => {
        navigate(`/recognition-result/${providerId}/${uid}`);
    };

    return (
        <div className="knowledge-base">
            <div className="knowledge-base-list">
                <div className="knowledge-base-list-header">
                    <MenuPanel
                        providers={providers}
                        showPromptTuning={isDefaultAdminTheme}
                        className="knowledge-base-list-header__menu"
                    />
                    {currentProvider?.name === ProviderType.googleDrive && (
                        <>
                            <SettingMenu
                                className="knowledge-base-list-header__setting"
                                actions={settings}
                            />
                            <ConfirmationPopup
                                open={deleteDriveConfirmationOpen}
                                type={ConfirmationPopupType.dangerous}
                                title="Disconnect Drive"
                                description={`Disconnecting "${currentProvider.displayName}" will remove access to all the data associated with it.
                                TIVA Assistant won't be able to answer questions on those topics.
                                This can't be undone!`}
                                actionName="Delete"
                                handleAction={handleDeleteDriveConfirmationAction}
                                handleClose={() => setDeleteDriveConfirmationOpen(false)}
                            />
                        </>
                    )}
                </div>
                <CoursesTree
                    className="knowledge-base-list-tree"
                    list={folderTree}
                    selectedCourse={courseId}
                    selectCourse={selectCourse}
                />
            </div>
            <div className="knowledge-base-item-content">
                {!!learningObjects.length && (
                    <div className="knowledge-base-item-filters">
                        {selectionMode && (
                            <div className="knowledge-base-item-filter knowledge-base-item-filter__actions">
                                <ActionMenu
                                    label="Action"
                                    disabled={checkedLOs.length === 0}
                                    actions={[
                                        {
                                            name: "Delete",
                                            action: handleDeleteCheckedLearningObjects
                                        }
                                    ]}
                                    minWidth={160}
                                />
                            </div>
                        )}
                    </div>
                )}
                <div className="knowledge-base-table">
                    <LearningObjects
                        totalCount={loTotalCount}
                        items={learningObjects}
                        setItems={setLearningObjects}
                        checkedItems={checkedLOs}
                        setCheckedItems={setCheckedLOs}
                        selectionMode={selectionMode}
                        placeholder={isCometTheme ? CometPlaceholder : TivaPlaceholder}
                        handleAppendFiles={handleAppendLearningObjects}
                        handleChangePublishStatus={handleChangePublishStatus}
                        handleAIResult={viewRecognitionResult}
                    />
                </div>
                <LoaderView show={isLoading} />
            </div>
            <ConfirmationPopup
                open={deleteLOsConfirmationOpen}
                type={ConfirmationPopupType.dangerous}
                title="Delete"
                description={`You are trying to delete ${deletableUids.length} learning object(s).
                              This can't be undone!`}
                actionName="Delete"
                handleAction={handleDeleteLOsConfirmationAction}
                handleClose={() => setDeleteLOsConfirmationOpen(false)}
            />
            <ConfirmationPopup
                open={publishConfirmationOpen}
                title="Publish"
                subtitle={publishSubtitle}
                description={publishDescription}
                actionName="Publish"
                showCancelButton={true}
                handleAction={handlePublishConfirmationAction}
                handleClose={() => setPublishConfirmationOpen(false)}
            />
            <ConfirmationPopup
                open={unpublishConfirmationOpen}
                title="Unpublish"
                subtitle={publishSubtitle}
                description={unpublishDescription}
                actionName="Unpublish"
                showCancelButton={true}
                handleAction={handleUnpublishConfirmationAction}
                handleClose={() => setUnpublishConfirmationOpen(false)}
            />
        </div>
    );
};

export default KnowledgeBaseElements;
