import { useState, useEffect, useCallback, UIEvent } from "react";
import { Button, IconButton } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import Checkbox from "@mui/material/Checkbox";

import "./style.scss";
import { ReactComponent as CheckboxIcon } from "../../../assets/checkboxIcon.svg";
import { ReactComponent as CheckboxCheckedIcon } from "../../../assets/checkboxCheckedIcon.svg";
import { ReactComponent as ChevronRightIcon } from "../../../assets/chevronRightIcon.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/chevronDownIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/deleteIcon.svg";
import { RecognitionResult } from "../../../common/interfaces/admin/recognition-result.interface";
import { TableLearningObject } from "../../../common/interfaces/admin/learning-object.interface";
import { RecognitionResultStatus } from "../../../common/enums/recognition-result-status.enum";
import { LearningObjectStatus } from "../../../common/enums/learning-object-status.enum";
import { formatStringDate } from "../../../common/utilities/dates/date-formater.util";
import { Loader } from "../../../components/loader/loader.comp";
import { SelectionFilter } from "../../../components/menu-filter/menu-filter.comp";

const LearningObjects = ({
    totalCount,
    items,
    setItems,
    checkedItems,
    setCheckedItems,
    selectionMode = false,
    placeholder,
    handleAppendFiles,
    handleChangePublishStatus,
    handleAIResult,
    handleDelete
}: {
    totalCount?: number;
    items: TableLearningObject[];
    setItems: (arg1: TableLearningObject[]) => void;
    checkedItems: TableLearningObject[];
    setCheckedItems: (arg1: TableLearningObject[]) => void;
    selectionMode?: boolean;
    placeholder: string;
    handleAppendFiles?: () => void;
    handleChangePublishStatus: (arg1: RecognitionResult, arg2: string) => void;
    handleAIResult: (arg1: string) => void;
    handleDelete?: (arg1: TableLearningObject) => void;
}) => {
    const scrollDelata = 250;
    const [appendAvailable, setAppendAvailable] = useState<boolean>(true);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [rrStatusList] = useState<string[]>(["Off", "Published"]);

    useEffect(() => {
        setShowLoader(!!handleAppendFiles && !!totalCount && totalCount !== items.length);
    }, [items, totalCount, handleAppendFiles]);

    const handleChangeSelectAll = useCallback(() => {
        setCheckedItems(items.length === checkedItems.length ? [] : items);
    }, [items, checkedItems, setCheckedItems]);

    const handleChangeSelect = useCallback(
        (item: TableLearningObject) => {
            const newFiles = [...checkedItems];
            const index = newFiles.indexOf(item);
            index !== -1 ? newFiles.splice(index, 1) : newFiles.push(item);
            setCheckedItems(newFiles);
        },
        [checkedItems, setCheckedItems]
    );

    const isSelectedItem = useCallback(
        (item: TableLearningObject) => {
            return checkedItems.map(item => item.loItemId).includes(item.loItemId);
        },
        [checkedItems]
    );

    const loStatusColor = (status: string) => {
        switch (status as LearningObjectStatus) {
            case LearningObjectStatus.active:
            case LearningObjectStatus.inUse:
                return "green";
            case LearningObjectStatus.idle:
            case LearningObjectStatus.recognizing:
            case LearningObjectStatus.transcribing:
                return "yellow";
            case LearningObjectStatus.error:
            case LearningObjectStatus.waitingForDownload:
            case LearningObjectStatus.waitingForRecognition:
                return "red";
            default:
                return "default";
        }
    };

    const loStatusName = (status: string) => {
        switch (status as LearningObjectStatus) {
            case LearningObjectStatus.active:
            case LearningObjectStatus.inUse:
                return "In Use";
            case LearningObjectStatus.idle:
                return "Idle";
            case LearningObjectStatus.recognizing:
                return "Recognizing";
            case LearningObjectStatus.transcribing:
                return "Transcribing";
            case LearningObjectStatus.error:
                return "Error";
            case LearningObjectStatus.waitingForDownload:
                return "Waiting for download";
            case LearningObjectStatus.waitingForRecognition:
                return "Waiting for recognition";
            default:
                return status;
        }
    };

    const rrName = (rr: RecognitionResult) => {
        return `${rr.name} v.${rr.version}${rr.hasUpdates ? " (Edited)" : ""}`;
    };

    const isAIResultAvailable = (status: string) => {
        return (
            status === RecognitionResultStatus.active ||
            status === RecognitionResultStatus.published ||
            status === RecognitionResultStatus.recognized ||
            status === RecognitionResultStatus.generatingSummary ||
            status === RecognitionResultStatus.waitingForSummary
        );
    };

    const handleLOClick = useCallback(
        (item: TableLearningObject) => {
            setItems(
                items.map(lo =>
                    lo.loItemId === item.loItemId ? { ...lo, showRR: !lo.showRR } : lo
                )
            );
        },
        [items, setItems]
    );

    const changePublishStatus = (status: string, loName: string, rr: RecognitionResult) => {
        if (rr.isPublish !== (status === "Published")) {
            handleChangePublishStatus(rr, loName);
        }
    };

    const handleAIResultClick = (uid: string) => {
        handleAIResult(uid);
    };

    const handleDeleteClick = (item: TableLearningObject) => {
        if (handleDelete) {
            handleDelete(item);
        }
    };

    const handleListScroll = (event: UIEvent<HTMLElement>) => {
        if (!handleAppendFiles || (totalCount && totalCount === items.length)) return;

        const scrollBottom =
            event.currentTarget.scrollHeight -
            (event.currentTarget.scrollTop + event.currentTarget.offsetHeight);

        if (appendAvailable && scrollBottom < scrollDelata) {
            setAppendAvailable(false);
            handleAppendFiles();
        } else if (scrollBottom >= scrollDelata) {
            setAppendAvailable(true);
        }
    };

    return (
        <div className="lo-table-view">
            {items.length ? (
                <div className="lo-table">
                    <div className="lo-table-header">
                        {selectionMode && (
                            <div className="lo-table-header-cell lo-table-cell__select">
                                <Checkbox
                                    checked={items.length === checkedItems.length}
                                    onChange={handleChangeSelectAll}
                                    icon={<CheckboxIcon />}
                                    checkedIcon={<CheckboxCheckedIcon />}
                                />
                            </div>
                        )}
                        <div className="lo-table-header-cell lo-table-cell__name">File Name</div>
                        <div className="lo-table-header-cell lo-table-cell__editor">Editor</div>
                        <div className="lo-table-header-cell lo-table-cell__date">Date</div>
                        <div className="lo-table-header-cell lo-table-cell__status">Status</div>
                        <div className="lo-table-header-cell lo-table-cell__action"></div>
                    </div>
                    <div className="lo-table-body" onScroll={handleListScroll}>
                        {items.map(item => (
                            <div key={item.loItemId} className="lo-table-row-block">
                                <div className="lo-table-row">
                                    {selectionMode && (
                                        <div className="lo-table-cell lo-table-cell__select">
                                            <Checkbox
                                                checked={isSelectedItem(item)}
                                                onChange={() => handleChangeSelect(item)}
                                                icon={<CheckboxIcon />}
                                                checkedIcon={<CheckboxCheckedIcon />}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className={`lo-table-cell lo-table-cell__name${!selectionMode ? " lo-table-cell__name--first" : ""}`}
                                    >
                                        <div
                                            className="lo-name"
                                            onClick={() => handleLOClick(item)}
                                        >
                                            <SvgIcon
                                                className="lo-name__icon"
                                                component={
                                                    item.showRR ? ChevronDownIcon : ChevronRightIcon
                                                }
                                                inheritViewBox
                                            />
                                            <span className="lo-name__text">{item.name}</span>
                                        </div>
                                    </div>
                                    <div className="lo-table-cell lo-table-cell__editor"></div>
                                    <div className="lo-table-cell lo-table-cell__date">
                                        <span className="lo-date">
                                            {formatStringDate(item.createdAt, "MM.dd.yyyy")}
                                        </span>
                                    </div>
                                    <div className="lo-table-cell lo-table-cell__status">
                                        <div
                                            className={`lo-status lo-status--${loStatusColor(item.status)}`}
                                        >
                                            <div className="lo-status__circle"></div>
                                            <span className="lo-status__title">
                                                {loStatusName(item.status)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="lo-table-cell lo-table-cell__action">
                                        {handleDelete && (
                                            <IconButton
                                                className="lo-action__button-delete"
                                                onClick={() => handleDeleteClick(item)}
                                            >
                                                <SvgIcon component={DeleteIcon} inheritViewBox />
                                            </IconButton>
                                        )}
                                    </div>
                                </div>
                                {!!item.recognitionResults.length &&
                                    item.recognitionResults.map(rr => (
                                        <div
                                            key={rr.uid}
                                            className={`lo-table-row lo-table-row--rr${!item.showRR ? " lo-table-row--rr-hidden" : ""}`}
                                        >
                                            {selectionMode && (
                                                <div className="lo-table-cell lo-table-cell__select"></div>
                                            )}
                                            <div
                                                className={`lo-table-cell lo-table-cell__name${!selectionMode ? " lo-table-cell__name--first" : ""}`}
                                            >
                                                <div className="lo-name">
                                                    <span className="lo-name__text">
                                                        {rrName(rr)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="lo-table-cell lo-table-cell__editor">
                                                <span className="lo-editor">
                                                    {rr.publisherFullName ?? "Auto"}
                                                </span>
                                            </div>
                                            <div className="lo-table-cell lo-table-cell__date">
                                                <span className="lo-date">
                                                    {formatStringDate(
                                                        rr.createdAt,
                                                        "MM.dd.yyyy — hh:mm tt"
                                                    )}
                                                </span>
                                            </div>
                                            <div className="lo-table-cell lo-table-cell__status">
                                                <SelectionFilter
                                                    list={rrStatusList}
                                                    selectedItem={
                                                        rr.isPublish ? "Published" : "Off"
                                                    }
                                                    setSelectedItem={status =>
                                                        changePublishStatus(status, item.name, rr)
                                                    }
                                                    rightPosition={true}
                                                />
                                            </div>
                                            <div className="lo-table-cell lo-table-cell__action">
                                                <Button
                                                    className="lo-action__button-result"
                                                    variant="text"
                                                    disabled={!isAIResultAvailable(rr.status)}
                                                    onClick={() => handleAIResultClick(rr.uid)}
                                                >
                                                    {isAIResultAvailable(rr.status)
                                                        ? "AI Result"
                                                        : "Loading"}
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        ))}
                        {showLoader && (
                            <div className="lo-table-row lo-table-row__loader">
                                <Loader size={24} borderWidth={4} />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="lo-table-placeholder">
                    <img src={placeholder} alt="" />
                </div>
            )}
        </div>
    );
};

export default LearningObjects;
