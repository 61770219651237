import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeContainer } from "./common/containers/theme/theme.container";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS
});

root.render(
    <BrowserRouter>
        <Sentry.ErrorBoundary fallback={<>An error has occurred</>}>
            <ThemeContainer.Provider>
                <App />
            </ThemeContainer.Provider>
        </Sentry.ErrorBoundary>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
