import { styled } from "@mui/system";

import "./style.scss";

export const Loader = ({
    size,
    borderWidth,
    className
}: {
    size: number;
    borderWidth: number;
    className?: string;
}) => {
    const StyledLoader = styled("div")({
        width: size,
        height: size,
        "&:after": {
            width: size - borderWidth * 2,
            height: size - borderWidth * 2,
            borderWidth: borderWidth
        }
    });

    return <StyledLoader className={`laoder-dual-ring ${className ?? ""}`}></StyledLoader>;
};

export const LoaderView = ({
    show,
    size = 80,
    borderWidth = 10
}: {
    show: boolean;
    size?: number;
    borderWidth?: number;
}) => {
    return (
        <div className={`laoder-view${show ? "" : " laoder-view-hidden"}`}>
            <Loader size={size} borderWidth={borderWidth} />
        </div>
    );
};

export const Loading = () => {
    return (
        <div className="loading">
            <div className="loading-item"></div>
            <div className="loading-item"></div>
            <div className="loading-item"></div>
            <div className="loading-item"></div>
        </div>
    );
};

export const DotsTyping = () => {
    return (
        <span className="dots-typing">
            <span className="dots-typing-item">.</span>
            <span className="dots-typing-item">.</span>
            <span className="dots-typing-item">.</span>
        </span>
    );
};
