import { APPS } from "../constants/constants";

const getSubdomain = (location: string) => {
    const locationParts = location.split(".");
    const sliceTill = locationParts.slice(-1)[0] === "localhost" ? -1 : -2;
    return locationParts.slice(0, sliceTill).join(".");
};

const getApp = () => {
    const main = APPS.find(app => app.main);
    if (!main) throw new Error("Must have main app");

    const currentSubdomain = getSubdomain(window.location.hostname);

    return (
        APPS.find(
            app => app.subdomains.find(subdomain => subdomain === currentSubdomain) != null
        ) ?? main
    );
};

export const getAppRoutes = () => {
    return getApp().routes;
};

export const getAuthContainer = () => {
    return getApp().auth;
};

export const getAppTheme = () => {
    return getApp().theme;
};

export const isAdminPanel = getApp().adminPanel;
