import { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { setDataToLocalStorage } from "../../../../common/utilities/localstorage-dealer/localstorage-setter.util";
import { AuthEvents } from "../../../../common/enums/auth-events.enum";

const EmailConfirmed = () => {
    useEffect(() => {
        setDataToLocalStorage(AuthEvents.EMAIL_CONFIRMED_TIME, new Date().toISOString());
    });

    return <Navigate to="/" />;
};

export default EmailConfirmed;
