import { ReactNode } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import "./style.scss";

export const ModalTemplate = ({
    children,
    width,
    height,
    showClose = true,
    handleClose
}: {
    children: ReactNode;
    width?: number;
    height?: number;
    showClose?: boolean;
    handleClose?: () => void;
}) => {
    const style = { ...(width ? { maxWidth: width } : {}), ...(height ? { height: height } : {}) };

    const handleCloseClick = () => {
        if (handleClose) {
            handleClose();
        }
    };

    return (
        <div className="background-template-view">
            <div className="modal-template-view" style={style}>
                {showClose && (
                    <IconButton className="modal-template__button-close" onClick={handleCloseClick}>
                        <CloseIcon />
                    </IconButton>
                )}
                {children}
            </div>
        </div>
    );
};
