import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import "../chat-messages/style.scss";
import { ChatBubble, ChatBubbleType } from "../../components/chat-bubble/chat-bubble.comp";
import { ChatBackground } from "../../components/chat-background/chat-background.comp";
import { ChatMessagePair } from "../chat-messages/chat-messages";
import { User } from "../../common/interfaces/comps/user.interface";
import { Chat } from "../../common/interfaces/chats/chat.interface";
import { createRoom } from "../../api/chats/endpoints-referrers";
import { AuthContainer } from "../../common/containers/auth/auth-container.util";
import { isMobileDevice } from "../../common/utilities/platform/platform.util";
import { setDataToLocalStorage } from "../../common/utilities/localstorage-dealer/localstorage-setter.util";

const CreateChatMessages = ({
    newMessage,
    setNewChat,
    placeholder,
    showDashboard = false
}: {
    newMessage: string | null;
    setNewChat: (arg1: Chat | null) => void;
    placeholder: string;
    showDashboard?: boolean;
}) => {
    const navigate = useNavigate();
    const chatScrollRef = useRef<HTMLDivElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [messages, setMessages] = useState<ChatMessagePair[]>([]);
    const { user }: { isTrial: boolean; user: User | null } = AuthContainer.useContainer();

    useEffect(() => {
        if (newMessage && user) {
            const chatId = crypto.randomUUID();
            setNewChat({
                roomId: chatId,
                title: newMessage,
                userId: user.uid,
                createdAt: new Date().toString()
            });
            setMessages(prevMessages => [
                ...prevMessages,
                {
                    question: {
                        text: newMessage,
                        isLoading: true,
                        type: ChatBubbleType.question,
                        metadata: null
                    }
                }
            ]);
            createRoom(user.uid, { roomId: chatId, title: newMessage }).then(() => {
                setNewChat(null);
                setDataToLocalStorage("createNewChatKey", true);
                navigate(`/chat/${chatId}`, {
                    state: {
                        isNew: true
                    }
                });
            });
        }
    }, [newMessage, navigate, user, setNewChat]);

    const getMessageStyle = (index: number) => {
        if (messages.length - 1 !== index || !chatScrollRef.current) return {};

        return { minHeight: `${chatScrollRef.current.clientHeight - 70 - 16}px` };
    };

    return (
        <div className="chat-messages-content">
            <div ref={chatScrollRef} className="chat-messages-scroll">
                <div className="chat-messages">
                    {messages.length > 0 &&
                        messages.map((message, index) => (
                            <div
                                key={crypto.randomUUID()}
                                style={getMessageStyle(index)}
                                className="chat-bot-result-pair"
                            >
                                {message.question && (
                                    <div className="chat-bot-result__user">
                                        <ChatBubble
                                            className="chat-bot-result-bubble"
                                            id={message.question.id}
                                            message={message.question.text}
                                            isLoading={message.question.isLoading}
                                            type={message.question.type}
                                            metadata={message.question.metadata}
                                            handleLink={null}
                                            isActive={false}
                                            hideAvatars={isMobileDevice()}
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                    {messages.length > 0 && <div ref={messagesEndRef} />}
                    {messages.length === 0 && !showDashboard && (
                        <img src={placeholder} alt="" className="chat-placeholder" />
                    )}
                    {messages.length === 0 && showDashboard && <ChatBackground />}
                </div>
            </div>
        </div>
    );
};

export default CreateChatMessages;
