import { Button, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import "./style.scss";

export enum ConfirmationPopupType {
    default,
    dangerous
}

const ConfirmationPopup = ({
    open,
    type = ConfirmationPopupType.default,
    title,
    subtitle,
    description,
    actionName,
    showCancelButton = false,
    handleAction,
    handleClose
}: {
    open: boolean;
    type?: ConfirmationPopupType;
    title: string;
    subtitle?: string;
    description: string;
    actionName: string;
    showCancelButton?: boolean;
    handleAction: () => void;
    handleClose: () => void;
}) => {
    return (
        <Modal
            style={{ display: "flex" }}
            className="confirmation-area"
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div
                className={`confirmation-window${type === ConfirmationPopupType.dangerous ? " confirmation-window--dangerous" : ""}`}
            >
                <IconButton className="confirmation-window__button-close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <div className="confirmation-window__title">{title}</div>
                {subtitle && <div className="confirmation-window__subtitle">{subtitle}</div>}
                <div className="confirmation-window__description">{description}</div>
                <div className="confirmation-window-actions">
                    {showCancelButton && (
                        <Button
                            className="confirmation-window-actions__button confirmation-window-actions__button--cancel confirmation-window-actions__button--half"
                            variant="text"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        className={`confirmation-window-actions__button confirmation-window-actions__button--action${showCancelButton ? " confirmation-window-actions__button--half" : ""}`}
                        variant="text"
                        onClick={handleAction}
                    >
                        {actionName}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationPopup;
