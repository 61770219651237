import { useEffect, useState } from "react";
import { Params, useParams, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import { StringDiff, DiffMethod } from "react-string-diff";

import "./style.scss";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/arrowLeftIcon.svg";
import { FirebaseAuthContainer } from "../../../common/containers/auth/firebase-auth-container.util";
import { fetchRecognitionResult } from "../../../api/admin/recognition-result/endpoints-referrers";
import { fetchLearningObject } from "../../../api/admin/content-providers/endpoints-referrers";
import { RecognitionResult } from "../../../common/interfaces/admin/recognition-result.interface";
import { LoaderView } from "../../../components/loader/loader.comp";
import { RRSelectionFilter } from "../../../components/admin/rr-selection-filter/rr-selection-filter.comp";

const removedColorStyle = {
    added: {
        display: "none"
    },
    removed: {
        backgroundColor: "rgba(255, 45, 0, .42)"
    },
    default: {}
};

const addedColorStyle = {
    added: {
        backgroundColor: "rgba(0, 127, 82, .21)"
    },
    removed: {
        display: "none"
    },
    default: {}
};

const addedStyle = {
    added: {},
    removed: {
        display: "none"
    },
    default: {}
};

const CompareRecognitionResultView = () => {
    const navigate = useNavigate();
    const { providerId, uid } = useParams<Params<string>>();
    const { user } = FirebaseAuthContainer.useContainer();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [recognitionResultLeft, setRecognitionResultLeft] = useState<RecognitionResult>();
    const [recognitionResultRight, setRecognitionResultRight] = useState<RecognitionResult>();
    const [rrLeftText, setRrLeftText] = useState<string>();
    const [rrRightText, setRrRightText] = useState<string>();
    const [rrList, setRrList] = useState<RecognitionResult[]>([]);

    useEffect(() => {
        setIsLoading(true);

        if (!user || !uid) return;

        fetchRecognitionResult(user.companyId, uid).then(response => {
            setRecognitionResultLeft(response.data);
        });
    }, [user, uid]);

    useEffect(() => {
        if (!user || !recognitionResultLeft) return;

        if (rrList.length) {
            setIsLoading(false);
            return;
        }

        fetchLearningObject(
            user.companyId,
            recognitionResultLeft.courseId,
            recognitionResultLeft.loItemId
        )
            .then(response => {
                setRrList(response.data.recognitionResults);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [user, recognitionResultLeft, rrList]);

    useEffect(() => {
        setRrLeftText(recognitionResultLeft?.recognitionResultData?.loItemText ?? "");
    }, [recognitionResultLeft]);

    useEffect(() => {
        setRrRightText(recognitionResultRight?.recognitionResultData?.loItemText ?? "");
    }, [recognitionResultRight]);

    const handleBackClick = () => {
        navigate(`/recognition-result/${providerId}/${uid}`);
    };

    const handleChangeSelectedLeftRR = (rr: RecognitionResult) => {
        setIsLoading(true);

        if (!user) return;

        fetchRecognitionResult(user.companyId, rr.uid)
            .then(response => {
                setRecognitionResultLeft(response.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleChangeSelectedRightRR = (rr: RecognitionResult) => {
        setIsLoading(true);

        if (!user) return;

        fetchRecognitionResult(user.companyId, rr.uid)
            .then(response => {
                setRecognitionResultRight(response.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="compare-recognition-results-view">
            <div className="compare-recognition-results-header">
                <IconButton
                    className="compare-recognition-results-header__button-back"
                    onClick={handleBackClick}
                >
                    <SvgIcon component={ArrowLeftIcon} inheritViewBox />
                </IconButton>
                <div className="compare-recognition-results-header-title">
                    <span className="compare-recognition-results-header-title__text-name">
                        Compare RR versions
                    </span>
                </div>
            </div>
            <div className="compare-recognition-results-filter">
                <div className="compare-recognition-results-filter__select">
                    <RRSelectionFilter
                        list={rrList.filter(item => item.uid !== recognitionResultRight?.uid)}
                        selectedItem={recognitionResultLeft}
                        setSelectedItem={handleChangeSelectedLeftRR}
                        rightPosition={true}
                    />
                </div>
                <div className="compare-recognition-results-filter__select">
                    <RRSelectionFilter
                        list={rrList.filter(item => item.uid !== recognitionResultLeft?.uid)}
                        selectedItem={recognitionResultRight}
                        setSelectedItem={handleChangeSelectedRightRR}
                        rightPosition={true}
                    />
                </div>
            </div>
            <div className="compare-recognition-results-content">
                <div className="compare-recognition-result-content compare-recognition-result-content--left">
                    <StringDiff
                        className="compare-recognition-result-content__text"
                        oldValue={rrRightText ?? ""}
                        newValue={rrLeftText ?? ""}
                        styles={rrRightText ? addedColorStyle : addedStyle}
                        method={DiffMethod.Words}
                    />
                </div>
                <div className="compare-recognition-result-content compare-recognition-result-content--right">
                    <StringDiff
                        className="compare-recognition-result-content__text"
                        oldValue={rrRightText ?? ""}
                        newValue={rrLeftText ?? ""}
                        styles={removedColorStyle}
                        method={DiffMethod.Words}
                    />
                </div>
            </div>
            <LoaderView show={isLoading} />
        </div>
    );
};

export default CompareRecognitionResultView;
