import axios from "axios";
import { EventSourcePolyfill } from "event-source-polyfill";

import { stringGetter } from "../common/utilities/localstorage-dealer/localstorage-getters.util";

const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";

const baseUrl: string = process.env.REACT_APP_API_ENDPOINT || "";

const APIHandler = axios.create({ baseURL: baseUrl });
const UnauthedAPIHandler = axios.create({ baseURL: baseUrl });
const EventSourceHandler = (url: string) =>
    new EventSourcePolyfill(`${baseUrl}${url}`, {
        headers: {
            Authorization: `Bearer ${stringGetter(ACCESS_TOKEN_KEY)}`
        }
    });

APIHandler.interceptors.request.use(req => {
    if (stringGetter(ACCESS_TOKEN_KEY)) {
        req.headers.Authorization = `Bearer ${stringGetter(ACCESS_TOKEN_KEY)}`;
    }
    return req;
});

export { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, APIHandler, UnauthedAPIHandler, EventSourceHandler };
