import { Navigate, useLocation } from "react-router-dom";

import { getAuthContainer } from "../../common/utilities/routes/routes.util";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const { isAuthenticated } = getAuthContainer().useContainer();
    const location = useLocation();

    return isAuthenticated() ? children : <Navigate to="/login" state={{ from: location }} />;
};

export default ProtectedRoute;
