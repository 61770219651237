import { useEffect, useState } from "react";
import { Params, useParams, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";

import "./style.scss";
import { ReactComponent as MenuIcon } from "../../assets/menuIcon.svg";
import TivaPlaceholder from "../../assets/placeholderTiva.svg";
import CometPlaceholder from "../../assets/placeholderComet.svg";
import Footer from "../../components/footer/footer.comp";
import ChatList from "../../components/chat-list/chat-list.comp";
import CreateChatMessages from "../create-chat-messages/create-chat-messages";
import { ChatMessages } from "../chat-messages/chat-messages";
import { ThemeContainer } from "../../common/containers/theme/theme.container";
import { AuthContainer } from "../../common/containers/auth/auth-container.util";
import { Theme } from "../../common/enums/theme.enum";
import { User } from "../../common/interfaces/comps/user.interface";
import { Chat } from "../../common/interfaces/chats/chat.interface";
import { fetchRooms, archiveRoom, archiveAllRooms } from "../../api/chats/endpoints-referrers";
import { SettingItem, SettingItemType } from "../../components/setting-menu/setting-menu.comp";
import ConfirmationPopup, {
    ConfirmationPopupType
} from "../../components/confirmation-popup/confirmation-popup.comp";

const ChatBot = ({ isCreatingNewChat }: { isCreatingNewChat: boolean }) => {
    const navigate = useNavigate();
    const { chatId } = useParams<Params<string>>();
    const { user }: { user: User | null } = AuthContainer.useContainer();
    const { theme } = ThemeContainer.useContainer();
    const [isCometTheme] = useState<boolean>(theme === Theme.comet);
    const [settings, setSettings] = useState<SettingItem[]>([]);
    const [chats, setChats] = useState<Chat[]>([]);
    const [settingChat, setSettingChat] = useState<Chat>();
    const [newChat, setNewChat] = useState<Chat | null>(null);
    const [newMessage, setNewMessage] = useState<string | null>(null);
    const [hasChatList, setHasChatList] = useState<boolean>(false);
    const [isOpenChatList, setIsOpenChatList] = useState<boolean>(false);
    const [removeChatConfirmationOpen, setRemoveChatConfirmationOpen] = useState<boolean>(false);
    const [clearHistoryConfirmationOpen, setClearHistoryConfirmationOpen] =
        useState<boolean>(false);

    useEffect(() => {
        setSettings([
            {
                name: "Clear history",
                type: SettingItemType.default,
                handler: () => {
                    setClearHistoryConfirmationOpen(true);
                }
            }
        ]);
    }, []);

    useEffect(() => {
        if (newChat) {
            setChats(prevChats => [newChat, ...prevChats]);
        }
    }, [newChat]);

    useEffect(() => {
        setHasChatList(chats.length > 0);
    }, [chats]);

    useEffect(() => {
        if (user) {
            fetchRooms(user.uid).then(response => {
                const {
                    data: { items }
                } = response;
                setChats(items.reverse());

                if (chatId && !items.find((item: Chat) => item.roomId === chatId)) {
                    navigate("/new-chat");
                }
            });
        } else {
            setNewChat(null);
            setNewMessage(null);
            setIsOpenChatList(false);
        }
    }, [user, chatId, navigate]);

    const toggleChatList = (open: boolean) => {
        setIsOpenChatList(open);
    };

    const handleOpenChatList = () => {
        toggleChatList(true);
    };

    const handleChatCreating = () => {
        navigate("/new-chat");
    };

    const handleChatDeleting = () => {
        setRemoveChatConfirmationOpen(false);

        if (!user || !settingChat) return;

        archiveRoom(user.uid, settingChat.roomId).then(() => {
            setChats(prevChats => prevChats.filter(chat => chat.roomId !== settingChat.roomId));
            setSettingChat(undefined);
            if (chatId === settingChat.roomId) {
                handleChatCreating();
            }
        });
    };

    const handleDeleteChatConfirmationAction = (chat: Chat) => {
        setSettingChat(chat);
        setRemoveChatConfirmationOpen(true);
    };

    const handleClearHistoryConfirmationAction = () => {
        if (!user) return;

        archiveAllRooms(user.uid).then(() => {
            setChats([]);
            setIsOpenChatList(false);
            handleChatCreating();
        });
        setClearHistoryConfirmationOpen(false);
    };

    return (
        <div className="chat-bot">
            {hasChatList && (
                <ChatList
                    className={`chat-list-popup ${isOpenChatList ? "chat-list-shown" : "chat-list-hidden"}`}
                    chats={chats}
                    settings={settings}
                    selectedChatId={chatId}
                    popupMode={true}
                    toggleChatList={toggleChatList}
                    handleChatCreating={handleChatCreating}
                    handleChatDeleting={handleDeleteChatConfirmationAction}
                />
            )}
            <div className="chat-bot-body">
                {hasChatList && (
                    <>
                        <ChatList
                            className="chat-list-component"
                            chats={chats}
                            settings={settings}
                            selectedChatId={chatId}
                            handleChatCreating={handleChatCreating}
                            handleChatDeleting={handleDeleteChatConfirmationAction}
                        />
                        <IconButton className="chat-list-menu__button" onClick={handleOpenChatList}>
                            <SvgIcon component={MenuIcon} inheritViewBox />
                        </IconButton>
                    </>
                )}
                {isCreatingNewChat ? (
                    <CreateChatMessages
                        newMessage={newMessage}
                        setNewChat={setNewChat}
                        placeholder={isCometTheme ? CometPlaceholder : TivaPlaceholder}
                        showDashboard={!isCometTheme}
                    />
                ) : (
                    <ChatMessages
                        newMessage={newMessage}
                        setNewMessage={setNewMessage}
                        placeholder={isCometTheme ? CometPlaceholder : TivaPlaceholder}
                        showDashboard={!isCometTheme}
                    />
                )}
            </div>
            <ConfirmationPopup
                open={clearHistoryConfirmationOpen}
                type={ConfirmationPopupType.dangerous}
                title="Clear history"
                description="This will delete all your chats!"
                actionName="Delete"
                handleAction={handleClearHistoryConfirmationAction}
                handleClose={() => setClearHistoryConfirmationOpen(false)}
            />
            <ConfirmationPopup
                open={removeChatConfirmationOpen}
                type={ConfirmationPopupType.dangerous}
                title="Delete chat"
                description={`This will delete "${settingChat?.title}"`}
                actionName="Delete"
                handleAction={handleChatDeleting}
                handleClose={() => setRemoveChatConfirmationOpen(false)}
            />
            <Footer setNewMessage={setNewMessage} />
        </div>
    );
};

export default ChatBot;
