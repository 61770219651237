import { useState, useCallback } from "react";
import { Button, IconButton } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import "./style.scss";
import { ReactComponent as PlayIcon } from "../../assets/playIcon.svg";
import { ReactComponent as PdfIcon } from "../../assets/pdfIcon.svg";
import { ReactComponent as TxtIcon } from "../../assets/txtIcon.svg";
import { ReactComponent as ArrowExpandIcon } from "../../assets/arrowExpandIcon.svg";
import { ReactComponent as ArrowCollapseIcon } from "../../assets/arrowCollapseIcon.svg";
import { ReactComponent as ThumbsUpIcon } from "../../assets/thumbsUpIcon.svg";
import { ReactComponent as ThumbsDownIcon } from "../../assets/thumbsDownIcon.svg";
import Background1 from "../../assets/backgrounds/background1.svg";
import TivaAvatar from "../../assets/tivaAvatar.svg";
import { AuthContainer } from "../../common/containers/auth/auth-container.util";
import { User } from "../../common/interfaces/comps/user.interface";
import { SummaryProps } from "../../common/interfaces/chats/summary.interface";
import { Loader, DotsTyping } from "../../components/loader/loader.comp";

export enum ChatBubbleType {
    question,
    answer,
    summary,
    typing,
    error
}

export enum Feedback {
    like = "1",
    dislike = "-1",
    noReview = "0"
}

export const ChatBubble = ({
    className,
    id,
    message,
    isLoading,
    type,
    metadata,
    handleLink = null,
    handleSummarize,
    handleReview,
    isActive = false,
    review,
    showReview = false,
    showSources = false,
    hideAvatars,
    isExpanded = false,
    setExpanded
}: {
    className?: string;
    id?: number;
    message: string;
    isLoading: boolean;
    type: ChatBubbleType;
    metadata: any;
    handleLink: ((arg1: string) => void) | null;
    handleSummarize?: ((summaryData: SummaryProps) => void) | null;
    handleReview?: (arg1: number, arg2: Feedback) => void;
    isActive: boolean;
    review?: Feedback;
    showReview?: boolean;
    showSources?: boolean;
    hideAvatars?: boolean;
    isExpanded?: boolean;
    setExpanded?: (arg1: boolean) => void;
}) => {
    const { user }: { user: User | null } = AuthContainer.useContainer();
    const [messageElement, setMessageElement] = useState<HTMLElement>();
    const [isQuestion] = useState<boolean>(type === ChatBubbleType.question);
    const [isError] = useState<boolean>(type === ChatBubbleType.error);
    const classes = `chat-bubble-content chat-bubble-content--${isQuestion ? "user" : isError ? "error" : "bot"} ${isActive ? "chat-bubble-content--active" : ""} ${className ?? ""}`;
    const sources = metadata
        ? metadata.filter((item: any) => {
              return (
                  item.id_course !== undefined &&
                  item.id_course !== null &&
                  item.id_course !== "" &&
                  item.lo_item_id !== undefined &&
                  item.lo_item_id !== null &&
                  item.lo_item_id !== ""
              );
          })
        : [];

    const messageRef = useCallback((node: HTMLElement) => {
        setMessageElement(node);
    }, []);

    const isClamped = () => {
        return (
            type === ChatBubbleType.question &&
            id &&
            messageElement &&
            (messageElement.scrollHeight > messageElement.clientHeight ||
                messageElement.clientHeight > 80)
        );
    };

    const handleExpandCollapseClick = () => {
        if (setExpanded) {
            setExpanded(!isExpanded);
        }
    };

    const handleLinkClick = (lo_metadata: any) => {
        if (handleLink) {
            handleLink(lo_metadata.play_url);
        }
    };

    const handleSummaryClick = (lo_metadata: any) => {
        if (handleSummarize) {
            handleSummarize({
                courseId: lo_metadata.id_course,
                loItemId: lo_metadata.lo_item_id
            });
        }
    };

    const handleReviewClick = (feedback: Feedback) => {
        if (id && handleReview) {
            handleReview(id, feedback);
        }
    };

    const sourceName = (item: any) => {
        return item.source
            ? item.course_name
            : item.source_type === "video"
              ? "Video link"
              : item.source_type === "pdf"
                ? "PDF link"
                : "TXT link";
    };

    const videoInitials = (item: any) => {
        if (!item.course_name) return "VL";
        const words = item.course_name.split(" ");
        return `${words[0].toUpperCase().at(0)}${words.length > 1 ? words[1].toUpperCase().at(0) : ""}`;
    };

    const generateBackgroundIndex = (item: any) => {
        const index = ((item.lo_item_id >= 0 ? item.lo_item_id : -item.lo_item_id) % 12) + 1;
        if (index) {
            return require(`../../assets/backgrounds/background${index}.svg`) || Background1;
        }
        return Background1;
    };

    return (
        <div
            className="chat-bubble"
            style={{ justifyContent: isQuestion ? "flex-end" : "flex-start" }}
        >
            {isQuestion && isLoading && (
                <Loader className="chat-bubble-loader" size={24} borderWidth={4} />
            )}
            {!hideAvatars && type !== ChatBubbleType.question && (
                <div className="chat-bubble-avatar chat-bubble-avatar--bot">
                    <img src={TivaAvatar} alt="" />
                </div>
            )}
            <div className={classes}>
                <span
                    ref={messageRef}
                    className={`chat-bubble-content__text${isExpanded ? " chat-bubble-content__text--expanded" : ""}`}
                >
                    <Markdown remarkPlugins={[remarkGfm]}>{message}</Markdown>
                </span>
                {!isQuestion && isLoading && <DotsTyping />}
                {showSources && sources.length > 0 && (
                    <div className="chat-bubble-metadata">
                        <div className="chat-bubble-metadata__title">Relevant resources:</div>
                        <div className="chat-bubble-metadata__resources">
                            {sources.map((item: any) => (
                                <div key={crypto.randomUUID()} className="metadata-resource">
                                    <div
                                        className="metadata-resource-thumbnail"
                                        style={{
                                            backgroundImage: `url("${generateBackgroundIndex(item)}")`
                                        }}
                                        onClick={() => handleLinkClick(item)}
                                    >
                                        {item.source_type === "video" && (
                                            <div className="metadata-resource-thumbnail__video">
                                                <span className="metadata-resource-thumbnail__video-text">
                                                    {videoInitials(item)}
                                                </span>
                                                <div className="metadata-resource-thumbnail__video-icon">
                                                    <SvgIcon component={PlayIcon} inheritViewBox />
                                                </div>
                                            </div>
                                        )}
                                        {item.source_type !== "video" && (
                                            <div className="metadata-resource-thumbnail__document">
                                                <div className="metadata-resource-thumbnail__document-icon">
                                                    <SvgIcon
                                                        component={
                                                            item.source_type === "pdf"
                                                                ? PdfIcon
                                                                : TxtIcon
                                                        }
                                                        inheritViewBox
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="metadata-resource__document">
                                        <a
                                            className="metadata-resource__document-name"
                                            onClick={() => handleLinkClick(item)}
                                        >
                                            {sourceName(item)}
                                        </a>
                                    </div>
                                    <Button
                                        className="metadata-resource__button-summary"
                                        variant="text"
                                        onClick={() => handleSummaryClick(item)}
                                    >
                                        Summarize
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {showReview && type === ChatBubbleType.answer && (
                    <div className="chat-bubble-actions">
                        <IconButton
                            className={`chat-bubble-actions__button-icon${review === Feedback.like ? " chat-bubble-actions__button-icon--fill" : ""}`}
                            onClick={() =>
                                handleReviewClick(
                                    review === Feedback.like ? Feedback.noReview : Feedback.like
                                )
                            }
                        >
                            <SvgIcon component={ThumbsUpIcon} inheritViewBox />
                        </IconButton>
                        <IconButton
                            className={`chat-bubble-actions__button-icon${review === Feedback.dislike ? " chat-bubble-actions__button-icon--fill" : ""}`}
                            onClick={() =>
                                handleReviewClick(
                                    review === Feedback.dislike
                                        ? Feedback.noReview
                                        : Feedback.dislike
                                )
                            }
                        >
                            <SvgIcon component={ThumbsDownIcon} inheritViewBox />
                        </IconButton>
                    </div>
                )}
                {isClamped() && (
                    <div
                        className={`chat-bubble-collapse${isExpanded ? " chat-bubble-collapse--hidden" : ""}`}
                    >
                        <div className="chat-bubble-collapse__gradient"></div>
                        <div className="chat-bubble-collapse__button">
                            <IconButton onClick={() => handleExpandCollapseClick()}>
                                <SvgIcon
                                    component={isExpanded ? ArrowCollapseIcon : ArrowExpandIcon}
                                    inheritViewBox
                                />
                            </IconButton>
                        </div>
                    </div>
                )}
            </div>
            {!hideAvatars && user && type === ChatBubbleType.question && (
                <div className="chat-bubble-avatar chat-bubble-avatar--user">
                    {user.avatar ? (
                        <img src={user.avatar} alt="" />
                    ) : (
                        <div>
                            {user.firstName?.toUpperCase().at(0) ?? user.email.toUpperCase().at(0)}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
