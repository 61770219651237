import "./style.scss";

export const ChatBackground = () => {
    return (
        <div className="chat-background">
            <span className="chat-background-title">How can I help you?</span>
            <span className="chat-background-description">
                You can ask Knowledge Base by typing or saying a prompt below.
            </span>
        </div>
    );
};
