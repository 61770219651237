import { useCallback, useEffect } from "react";

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, APIHandler } from "../../../api/base";
import { stringGetter } from "../../utilities/localstorage-dealer/localstorage-getters.util";
import {
    setDataToLocalStorage,
    removeDataToLocalStorage
} from "../../utilities/localstorage-dealer/localstorage-setter.util";
import { TokenResponse } from "../../interfaces/auth/token-response.interface";

export function useFirebaseToken(onTokenInvalid: () => void) {
    const getAccessToken = useCallback(() => {
        return stringGetter(ACCESS_TOKEN_KEY);
    }, []);

    const getRefreshToken = useCallback(() => {
        return stringGetter(REFRESH_TOKEN_KEY);
    }, []);

    const isAuthenticated = useCallback(() => {
        return !!getAccessToken() && !!getRefreshToken();
    }, [getAccessToken, getRefreshToken]);

    const setTokens = useCallback(({ accessToken, refreshToken }: TokenResponse) => {
        setDataToLocalStorage(ACCESS_TOKEN_KEY, accessToken);
        setDataToLocalStorage(REFRESH_TOKEN_KEY, refreshToken);
    }, []);

    const clearToken = useCallback(() => {
        removeDataToLocalStorage(ACCESS_TOKEN_KEY);
        removeDataToLocalStorage(REFRESH_TOKEN_KEY);
        onTokenInvalid();
    }, [onTokenInvalid]);

    useEffect(() => {
        APIHandler.interceptors.response.use(
            response => response,
            async error => {
                if (error.response && error.response.status === 401 && isAuthenticated()) {
                    clearToken();
                }

                return Promise.reject(error);
            }
        );
    }, [isAuthenticated, clearToken, onTokenInvalid]);

    return {
        isAuthenticated,
        setTokens,
        clearToken
    };
}
