import { Button } from "@mui/material";

import "./style.scss";
import { FirebaseAuthContainer } from "../../../../common/containers/auth/firebase-auth-container.util";
import { AuthTemplate } from "../../../../components/admin/auth-template/auth-template.comp";

const EmailConfirm = () => {
    const { email, sendVerificationWaitingTime, logout, sendVerification } =
        FirebaseAuthContainer.useContainer();

    const handleCancelClick = () => {
        logout();
    };

    const handleSendAgainClick = () => {
        sendVerification();
    };

    return (
        <AuthTemplate height={266} showClose={true} handleClose={handleCancelClick}>
            <div className="email-confirm">
                <div className="email-confirm-title">Confirm email</div>
                <div className="email-confirm-description">
                    <div className="admin-signin-description-line">
                        <span className="admin-signin-description-line__text">
                            Confirmation link was sent to
                        </span>
                        <span className="admin-signin-description-line__email">{email}</span>
                    </div>
                    <div className="admin-signin-description-line">
                        <span className="admin-signin-description-line__text">
                            Click on the link to complete the sign up proccess.
                        </span>
                    </div>
                </div>
                <div className="email-confirm-send-again">
                    <div className="email-confirm-send-again-line">
                        <span className="email-confirm-send-again-line__text">
                            Didn&apos;t recieve it? Check spam or
                        </span>
                        <Button
                            className="email-confirm-send-again-line__link"
                            onClick={handleSendAgainClick}
                            disabled={!!sendVerificationWaitingTime}
                        >
                            Send again
                        </Button>
                    </div>
                    <div className="email-confirm-send-again-line">
                        <span className="email-confirm-send-again-line__text">
                            {sendVerificationWaitingTime
                                ? `(in ${sendVerificationWaitingTime}s)`
                                : ""}
                        </span>
                    </div>
                </div>
            </div>
        </AuthTemplate>
    );
};

export default EmailConfirm;
