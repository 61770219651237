import "./style.scss";

const NotFound = () => {
    return (
        <div className="not-found">
            <h3>Not Found</h3>
        </div>
    );
};

export default NotFound;
