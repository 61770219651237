import { Fragment, useState, MouseEvent } from "react";
import { Button, Menu } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import "./style.scss";
import { RecognitionResult } from "../../../common/interfaces/admin/recognition-result.interface";
import { formatStringDate } from "../../../common/utilities/dates/date-formater.util";

const RRDisplayTitle = ({ item }: { item?: RecognitionResult }) => {
    return (
        <span className="rr-display-title">
            {item ? (
                <>
                    <span className="rr-display-title__name">
                        {item.name} v.{item.version}
                    </span>
                    <span className="rr-display-title__author">
                        {item.publisherFullName ?? "Auto"}
                    </span>
                    <span className="rr-display-title__date">
                        {formatStringDate(item.createdAt, "MM.dd.yyyy — hh:mm")}
                    </span>
                </>
            ) : (
                <span className="rr-display-title__name">Select</span>
            )}
        </span>
    );
};

export const RRSelectionFilter = ({
    list,
    selectedItem,
    setSelectedItem,
    rightPosition = false,
    maxWidth
}: {
    list: RecognitionResult[];
    selectedItem?: RecognitionResult;
    setSelectedItem: (arg1: RecognitionResult) => void;
    rightPosition?: boolean;
    maxWidth?: number;
}) => {
    const menuStyles = maxWidth ? { width: "100%", maxWidth: `${maxWidth}px` } : { width: "auto" };
    const [selectAnchor, setSelectAnchor] = useState<Element | null>(null);
    const selectOpen = Boolean(selectAnchor);

    const handleSelectClick = (event: MouseEvent<HTMLElement>) => {
        setSelectAnchor(event.currentTarget);
    };

    const handleSelectClose = () => {
        setSelectAnchor(null);
    };

    const handleSelectItem = (item: RecognitionResult) => {
        setSelectedItem(item);
        handleSelectClose();
    };

    return (
        <Fragment>
            <Button
                className="rr-menu-button"
                variant="text"
                endIcon={
                    <SvgIcon
                        component={selectOpen ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
                        inheritViewBox
                    />
                }
                onClick={handleSelectClick}
            >
                <span className="rr-menu-button__label">
                    <RRDisplayTitle item={selectedItem} />
                </span>
            </Button>
            <Menu
                anchorEl={selectAnchor}
                open={selectOpen}
                onClose={handleSelectClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        className: "rr-dropdown-menu",
                        sx: menuStyles
                    }
                }}
                transformOrigin={{ horizontal: rightPosition ? "right" : "left", vertical: "top" }}
                anchorOrigin={{ horizontal: rightPosition ? "right" : "left", vertical: "bottom" }}
            >
                {list.map(item => (
                    <div
                        key={item.uid}
                        className={`rr-dropdown-menu-item${item.uid === selectedItem?.uid ? " rr-dropdown-menu-item__selected" : ""}`}
                        onClick={() => handleSelectItem(item)}
                    >
                        <RRDisplayTitle item={item} />
                    </div>
                ))}
            </Menu>
        </Fragment>
    );
};
