import { useEffect, useState, useCallback, MouseEvent, FC, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton, SvgIcon, Menu } from "@mui/material";

import "./style.scss";
import { ReactComponent as MenuIcon } from "../../assets/menuIcon.svg";
import { ReactComponent as TivaIcon } from "../../assets/tivaIcon.svg";
import { ReactComponent as HomeIcon } from "../../assets/homeIcon.svg";
import { ReactComponent as CloudUploadIcon } from "../../assets/cloudUploadIcon.svg";
import { ReactComponent as GearIcon } from "../../assets/gearIcon.svg";
import { ReactComponent as RocketIcon } from "../../assets/rocketGradientIcon.svg";
import { isAdminPanel } from "../../common/utilities/routes/routes.util";
import { FirebaseAuthContainer } from "../../common/containers/auth/firebase-auth-container.util";

enum MenuItemType {
    stroke = "stroke",
    fill = "fill",
    gradient = "gradient"
}

interface MenuItem {
    type: MenuItemType;
    icon: FC<React.SVGProps<SVGSVGElement>>;
    title: string;
    link?: string;
    isAdmin: boolean;
    disabled: boolean;
    handleClick: (arg1: string, arg2: boolean) => void;
}

const MenuLink = ({
    item,
    isSelected = false,
    isDisabled = false,
    handleClick
}: {
    item: MenuItem;
    isSelected?: boolean;
    isDisabled?: boolean;
    handleClick: (arg1: MenuItem) => void;
}) => {
    return (
        <div
            className={`trial-menu-action trial-menu-action--${item.type}${isSelected ? " trial-menu-action--selected" : ""}${isDisabled ? " trial-menu-action--disabled" : ""}`}
            onClick={() => handleClick(item)}
        >
            <SvgIcon className="trial-menu-action__icon" component={item.icon} inheritViewBox />
            <span className="trial-menu-action__title">{item.title}</span>
        </div>
    );
};

const TrialMenu = ({
    className = "",
    actionPageList,
    providerList
}: {
    className?: string;
    actionPageList: MenuItem[];
    providerList: MenuItem[];
}) => {
    const location = useLocation();
    const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
    const menuOpen = Boolean(menuAnchor);

    const isCurrentMenu = (item: MenuItem) => {
        return (
            (!isAdminPanel && !item.isAdmin) ||
            (isAdminPanel && item.isAdmin && location.pathname === item.link)
        );
    };

    const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleMenuItemClick = (item: MenuItem) => {
        if (!item.disabled && item.link) {
            item.handleClick(item.link, item.isAdmin);
            handleMenuClose();
        }
    };

    return (
        <>
            <IconButton
                edge="end"
                aria-label="close"
                className={`trial-menu-icon ${className}`}
                onClick={handleMenuClick}
            >
                <SvgIcon component={MenuIcon} inheritViewBox />
            </IconButton>
            <Menu
                id="trial-menu-panel"
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        className: "trial-menu"
                    }
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
                <div className="trial-menu-actions">
                    {actionPageList.map(item => (
                        <Fragment key={crypto.randomUUID()}>
                            <MenuLink
                                item={item}
                                isSelected={isCurrentMenu(item)}
                                isDisabled={item.disabled}
                                handleClick={handleMenuItemClick}
                            />
                        </Fragment>
                    ))}
                    <div className="trial-menu-separator"></div>
                    {providerList.map(item => (
                        <Fragment key={crypto.randomUUID()}>
                            <MenuLink
                                item={item}
                                isSelected={isCurrentMenu(item)}
                                isDisabled={true}
                                handleClick={handleMenuItemClick}
                            />
                        </Fragment>
                    ))}
                </div>
            </Menu>
        </>
    );
};

export const AdminTrialMenu = ({ className = "" }: { className?: string }) => {
    const navigate = useNavigate();
    const { knowledgeBases } = FirebaseAuthContainer.useContainer();
    const [knowledgeBaseAvailable, setKnowledgeBaseAvailable] = useState<boolean>(false);
    const [actionPageList, setActionPageList] = useState<MenuItem[]>([]);
    const [providerList, setProviderList] = useState<MenuItem[]>([]);

    const handleMenuItemClick = useCallback(
        (link: string, isAdmin: boolean) => {
            if (isAdmin) {
                navigate(link);
            } else {
                const host = window.location.host.replace("admin.", "");
                window.open(`${window.location.protocol}//${host}${link}`, "_self");
            }
        },
        [navigate]
    );

    useEffect(() => {
        if (!knowledgeBases.length) return;

        // TODO: get from page path params. User should choose it on Knowledge Bases page
        const knowledgeBase = knowledgeBases[0];
        setKnowledgeBaseAvailable(knowledgeBase.status === "active");
    }, [knowledgeBases]);

    useEffect(() => {
        setActionPageList([
            {
                type: MenuItemType.stroke,
                icon: HomeIcon,
                title: "Home",
                link: "/",
                isAdmin: true,
                disabled: false,
                handleClick: handleMenuItemClick
            },
            {
                type: MenuItemType.stroke,
                icon: CloudUploadIcon,
                title: "Upload Files",
                link: "/uploaded-files",
                isAdmin: true,
                disabled: false,
                handleClick: handleMenuItemClick
            },
            {
                type: MenuItemType.fill,
                icon: TivaIcon,
                title: "Knowledge Base",
                link: "/",
                isAdmin: false,
                disabled: !knowledgeBaseAvailable,
                handleClick: handleMenuItemClick
            },
            {
                type: MenuItemType.stroke,
                icon: GearIcon,
                title: "Customize",
                isAdmin: true,
                disabled: true,
                handleClick: handleMenuItemClick
            },
            {
                type: MenuItemType.gradient,
                icon: RocketIcon,
                title: "Upgrade to full version",
                link: "/feedback",
                isAdmin: true,
                disabled: false,
                handleClick: handleMenuItemClick
            }
        ]);
    }, [knowledgeBaseAvailable, handleMenuItemClick]);

    useEffect(() => {
        setProviderList([
            {
                type: MenuItemType.stroke,
                icon: CloudUploadIcon,
                title: "Google Drive (in Full Version)",
                isAdmin: true,
                disabled: true,
                handleClick: handleMenuItemClick
            },
            {
                type: MenuItemType.stroke,
                icon: CloudUploadIcon,
                title: "One Drive (in Full Version)",
                isAdmin: true,
                disabled: true,
                handleClick: handleMenuItemClick
            }
        ]);
    }, [handleMenuItemClick]);

    return (
        <TrialMenu
            className={className}
            actionPageList={actionPageList}
            providerList={providerList}
        />
    );
};

export const AssistantTrialMenu = ({ className = "" }: { className?: string }) => {
    const navigate = useNavigate();
    const [actionPageList, setActionPageList] = useState<MenuItem[]>([]);
    const [providerList, setProviderList] = useState<MenuItem[]>([]);

    const handleMenuItemClick = useCallback(
        (link: string, isAdmin: boolean) => {
            if (!isAdmin) {
                navigate(link);
            } else {
                const host = `admin.${window.location.host}`;
                window.open(`${window.location.protocol}//${host}${link}`, "_self");
            }
        },
        [navigate]
    );

    useEffect(() => {
        setActionPageList([
            {
                type: MenuItemType.stroke,
                icon: HomeIcon,
                title: "Home",
                link: "/",
                isAdmin: true,
                disabled: false,
                handleClick: handleMenuItemClick
            },
            {
                type: MenuItemType.stroke,
                icon: CloudUploadIcon,
                title: "Upload Files",
                link: "/uploaded-files",
                isAdmin: true,
                disabled: false,
                handleClick: handleMenuItemClick
            },
            {
                type: MenuItemType.fill,
                icon: TivaIcon,
                title: "Knowledge Base",
                link: "/",
                isAdmin: false,
                disabled: false,
                handleClick: handleMenuItemClick
            },
            {
                type: MenuItemType.stroke,
                icon: GearIcon,
                title: "Customize",
                isAdmin: true,
                disabled: true,
                handleClick: handleMenuItemClick
            },
            {
                type: MenuItemType.gradient,
                icon: RocketIcon,
                title: "Upgrade to full version",
                link: "/feedback",
                isAdmin: true,
                disabled: false,
                handleClick: handleMenuItemClick
            }
        ]);
    }, [handleMenuItemClick]);

    useEffect(() => {
        setProviderList([
            {
                type: MenuItemType.stroke,
                icon: CloudUploadIcon,
                title: "Google Drive (in Full Version)",
                isAdmin: true,
                disabled: true,
                handleClick: handleMenuItemClick
            },
            {
                type: MenuItemType.stroke,
                icon: CloudUploadIcon,
                title: "One Drive (in Full Version)",
                isAdmin: true,
                disabled: true,
                handleClick: handleMenuItemClick
            }
        ]);
    }, [handleMenuItemClick]);

    return (
        <TrialMenu
            className={className}
            actionPageList={actionPageList}
            providerList={providerList}
        />
    );
};
