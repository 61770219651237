import { useEffect, useState } from "react";

import { fetchKnowledgeBaseInvites } from "../../../api/admin/knowledge-base/endpoints-referrers";
import { FirebaseAuthContainer } from "../../../common/containers/auth/firebase-auth-container.util";

const InviteCodesView = () => {
    const { user, knowledgeBases } = FirebaseAuthContainer.useContainer();
    const [invites, setInvites] = useState<string[]>([]);

    useEffect(() => {
        if (!user || !knowledgeBases.length) return;

        fetchKnowledgeBaseInvites(user.companyId, knowledgeBases[0].uid).then(response => {
            const {
                data: { items }
            } = response;
            setInvites(items.map((item: any) => item.code));
        });
    }, [user, knowledgeBases]);

    return (
        <div>
            {invites.map(invite => (
                <div key={invite}>
                    {`${window.location.protocol}//${window.location.host.replace("admin.", "")}/login?invite=${invite}`}
                </div>
            ))}
        </div>
    );
};

export default InviteCodesView;
