import { useCallback, useEffect, useState, useRef } from "react";
import { createContainer } from "unstated-next";

import { useToken } from "./use-token.util";
import { ACCESS_TOKEN_KEY } from "../../../api/base";
import { login, register } from "../../../api/auth/endpoints-referrers";
import { fetchUser } from "../../../api/users/endpoints-referrers";
import { User } from "../../interfaces/comps/user.interface";
import { CompanyType } from "../../enums/company-type.enum";
import { AuthEvents } from "../../enums/auth-events.enum";
import { setDataToLocalStorage } from "../../utilities/localstorage-dealer/localstorage-setter.util";

function useAuth() {
    const [user, setUser] = useState<User | null>(null);
    const [isTrial, setIsTrial] = useState<boolean>(true);
    const {
        accessToken,
        isAuthenticated,
        updateAccessToken,
        updateTokenExpiration,
        setTokens,
        clearToken,
        isTokenRefreshRelevant,
        refreshTokensData
    } = useToken(
        useCallback(clearUser, []),
        useCallback(() => startRefreshTokens.current(), [])
    );

    const getUser = useCallback(async () => {
        const {
            data: { ...user }
        } = await fetchUser();
        setUser(user);
    }, [setUser]);

    useEffect(() => {
        setIsTrial(user?.companyType === CompanyType.trial);
    }, [user]);

    useEffect(() => {
        if (isAuthenticated() && !isTokenRefreshRelevant()) {
            updateTokenExpiration();
            getUser();
        } else if (isAuthenticated()) {
            startRefreshTokens.current();
        }
    }, [getUser, isAuthenticated, updateTokenExpiration, isTokenRefreshRelevant]);

    useEffect(() => {
        const handleStorageEventListener = async (event: WindowEventMap["storage"]) => {
            console.info("storage", event.key);
            if (event.key === AuthEvents.LOGOUT) {
                setUser(null);
                clearToken(false);
            } else if (event.key === AuthEvents.LOGIN && isAuthenticated()) {
                getUser();
            } else if (event.key === ACCESS_TOKEN_KEY && isAuthenticated()) {
                updateAccessToken();
                updateTokenExpiration();
            }
        };

        window.addEventListener("storage", handleStorageEventListener);

        return () => {
            window.removeEventListener("storage", handleStorageEventListener);
        };
    }, [getUser, isAuthenticated, updateAccessToken, updateTokenExpiration, clearToken]);

    useEffect(() => {
        window.authenticateCallback = async ({
            accessToken,
            refreshToken
        }: {
            accessToken: string;
            refreshToken: string;
        }) => {
            setTokens({ accessToken, refreshToken });
            await getUser();
            /* TODO redirect takes place in VerifiedSignInRoute, the code below is not relevant */
            // navigate(location.state?.from || "/new-chat");

            setDataToLocalStorage(AuthEvents.LOGIN, new Date().toISOString());
        };
    }, [getUser, setTokens]);

    const signIn = async (email: string, password: string) => {
        try {
            return login(email, password).then(async response => {
                const {
                    data: { accessToken, refreshToken, ...currentUser }
                } = response;
                setTokens({ accessToken, refreshToken });
                setUser(currentUser);
                return Promise.resolve(response);
            });
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const signUp = async (email: string, password: string, invite: string | null) => {
        const signupData = {
            email,
            password,
            from: invite
        };
        try {
            return register(signupData).then(async response => {
                const {
                    data: { accessToken, refreshToken, ...currentUser }
                } = response;
                setTokens({ accessToken, refreshToken });
                setUser(currentUser);
                return Promise.resolve(response);
            });
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const logout = useCallback(() => {
        clearToken().then(() => {
            clearUser();
        });
    }, [clearToken]);

    function clearUser() {
        setUser(null);
        setDataToLocalStorage(AuthEvents.LOGOUT, new Date().toISOString());
    }

    const refreshUser = useCallback((currentUser: User) => {
        setUser(prevUser =>
            JSON.stringify({ ...prevUser, googleAccount: null }) !==
            JSON.stringify({ ...currentUser, googleAccount: null })
                ? currentUser
                : prevUser
        );
    }, []);

    const startRefreshTokens = useRef(() => {
        refreshTokensData()
            .then(user => {
                if (!user) return;
                refreshUser(user);
            })
            .catch(() => {
                setTimeout(async () => {
                    if (!isTokenRefreshRelevant()) return;
                    refreshTokensData()
                        .then(user => {
                            if (!user) return;
                            refreshUser(user);
                        })
                        .catch(() => {
                            logout();
                        });
                }, 3000);
            });
    });

    return {
        isAuthenticated,
        accessToken,
        isTrial,
        user,
        setUser,
        signIn,
        signUp,
        logout
    };
}

export const AuthContainer = createContainer(useAuth);
