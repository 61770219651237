export const openCenteredPopup = (url: string, target: string, width: number, height: number) => {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const w = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width;
    const h = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height;

    const systemZoom = w / window.screen.availWidth;
    const left = (w - width) / 2 / systemZoom + dualScreenLeft;
    const top = (h - height) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
        url,
        target,
        `width=${width},height=${height},top=${top},left=${left}`
    );

    newWindow?.focus();
};
