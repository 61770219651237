import { useCallback, useEffect, useState } from "react";
import { AxiosRequestConfig } from "axios";

import { useTokenExpiration } from "./use-token-expiration.util";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, APIHandler } from "../../../api/base";
import { refreshTokens } from "../../../api/auth/endpoints-referrers";
import { logout } from "../../../api/auth/endpoints-referrers";
import { AuthEvents } from "../../enums/auth-events.enum";
import { stringGetter } from "../../utilities/localstorage-dealer/localstorage-getters.util";
import {
    setDataToLocalStorage,
    removeDataToLocalStorage
} from "../../utilities/localstorage-dealer/localstorage-setter.util";
import { TokenResponse } from "../../interfaces/auth/token-response.interface";

interface RetryQueueItem {
    resolve: (value?: any) => void;
    reject: (error?: any) => void;
    config: AxiosRequestConfig;
}

export function useToken(onTokenInvalid: () => void, onRefreshRequired: () => void) {
    const tokenExpiration = Number(process.env.REACT_APP_TOKEN_EXPIRATION) || 1500000;
    const [accessToken, setAccessToken] = useState<string>();
    const { clearAutomaticTokenRefresh, setTokenExpiration } =
        useTokenExpiration(onRefreshRequired);

    const getAccessToken = useCallback(() => {
        return stringGetter(ACCESS_TOKEN_KEY);
    }, []);

    const getRefreshToken = useCallback(() => {
        return stringGetter(REFRESH_TOKEN_KEY);
    }, []);

    const isAuthenticated = useCallback(() => {
        return !!getAccessToken() && !!getRefreshToken();
    }, [getAccessToken, getRefreshToken]);

    const getTokenExpiration = useCallback(() => {
        const now = new Date().getTime();
        const tokenRefreshDate = stringGetter(AuthEvents.TOKEN_REFRESH_DATE);
        const elapsedTime = now - (tokenRefreshDate ? new Date(tokenRefreshDate).getTime() : now);
        return tokenExpiration > elapsedTime ? tokenExpiration - elapsedTime : tokenExpiration;
    }, [tokenExpiration]);

    const updateTokenExpiration = useCallback(() => {
        setTokenExpiration(getTokenExpiration());
    }, [setTokenExpiration, getTokenExpiration]);

    const updateAccessToken = useCallback(() => {
        setAccessToken(getAccessToken() ?? undefined);
    }, [getAccessToken]);

    const setTokens = useCallback(
        ({ accessToken, refreshToken }: TokenResponse) => {
            setDataToLocalStorage(AuthEvents.TOKEN_REFRESH_DATE, new Date().toISOString());
            updateTokenExpiration();
            setDataToLocalStorage(ACCESS_TOKEN_KEY, accessToken);
            setDataToLocalStorage(REFRESH_TOKEN_KEY, refreshToken);
            setAccessToken(accessToken);
        },
        [updateTokenExpiration]
    );

    const clearToken = useCallback(
        async (shouldClearCookie = true) => {
            const refreshToken = getRefreshToken();
            const clearRefreshTokenCookie =
                shouldClearCookie && refreshToken && isAuthenticated()
                    ? logout({ refreshToken })
                    : Promise.resolve();

            try {
                return await clearRefreshTokenCookie;
            } finally {
                removeDataToLocalStorage(ACCESS_TOKEN_KEY);
                removeDataToLocalStorage(REFRESH_TOKEN_KEY);
                setAccessToken("");
                clearAutomaticTokenRefresh();
            }
        },
        [clearAutomaticTokenRefresh, isAuthenticated, getRefreshToken]
    );

    const isTokenRefreshRelevant = useCallback(() => {
        const tokenRefreshDate = stringGetter(AuthEvents.TOKEN_REFRESH_DATE);
        return (
            !tokenRefreshDate ||
            new Date().getTime() - new Date(tokenRefreshDate).getTime() > tokenExpiration
        );
    }, [tokenExpiration]);

    const refreshTokensData = useCallback(async () => {
        const refreshToken = getRefreshToken();

        if (!isAuthenticated() || !refreshToken) return Promise.resolve(undefined);

        return refreshTokens({ refreshToken })
            .then(response => {
                const {
                    data: { accessToken, refreshToken, ...currentUser }
                } = response;

                setTokens({ accessToken, refreshToken });

                return Promise.resolve(currentUser);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }, [getRefreshToken, isAuthenticated, setTokens]);

    useEffect(() => {
        updateAccessToken();
    }, [updateAccessToken]);

    useEffect(() => {
        const retryQueueList: RetryQueueItem[] = [];
        let isRefreshing = false;

        APIHandler.interceptors.response.use(
            response => response,
            async error => {
                const currentRefreshToken = getRefreshToken();
                if (
                    error.response &&
                    error.response.status === 401 &&
                    currentRefreshToken &&
                    isAuthenticated()
                ) {
                    const originalRequest: AxiosRequestConfig = error.config;
                    if (!isRefreshing) {
                        isRefreshing = true;
                        try {
                            const {
                                data: { accessToken, refreshToken }
                            } = await refreshTokens({
                                refreshToken: currentRefreshToken
                            });
                            setTokens({ accessToken, refreshToken });

                            retryQueueList.forEach(({ config, resolve, reject }) => {
                                APIHandler.request(config)
                                    .then(response => resolve(response))
                                    .catch(error => reject(error));
                            });

                            retryQueueList.length = 0;

                            return APIHandler(originalRequest);
                        } catch (refreshError) {
                            clearToken(false);
                            onTokenInvalid();
                            throw refreshError;
                        } finally {
                            isRefreshing = false;
                        }
                    }
                    return new Promise<void>((resolve, reject) => {
                        retryQueueList.push({
                            config: originalRequest,
                            resolve,
                            reject
                        });
                    });
                }

                return Promise.reject(error);
            }
        );
    }, [onTokenInvalid, isAuthenticated, getRefreshToken, clearToken, setTokens]);

    return {
        accessToken,
        isAuthenticated,
        getRefreshToken,
        updateAccessToken,
        updateTokenExpiration,
        setTokens,
        clearToken,
        isTokenRefreshRelevant,
        refreshTokensData
    };
}
