import { useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";

import "./style.scss";
import LogoTiva from "../../assets/logoTiva.svg";
import LogoComet from "../../assets/logoComet.svg";
import LogoCometAdmin from "../../assets/logoCometAdmin.svg";
import { ReactComponent as HomeIcon } from "../../assets/homeIcon.svg";
import { Theme } from "../../common/enums/theme.enum";
import { getAuthContainer, isAdminPanel } from "../../common/utilities/routes/routes.util";
import { ThemeContainer } from "../../common/containers/theme/theme.container";
import { isMobileDevice } from "../../common/utilities/platform/platform.util";
import { AdminTrialMenu, AssistantTrialMenu } from "../trial-menu/trial-menu.comp";

const Header = () => {
    const navigate = useNavigate();
    const { isTrial, user, logout } = getAuthContainer().useContainer();
    const { theme } = ThemeContainer.useContainer();
    const [isCometTheme] = useState<boolean>(theme === Theme.comet);
    const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
    const menuOpen = Boolean(menuAnchor);

    const handleEntryPointClick = () => {
        navigate("/");
    };

    const handleProfileMenuClick = (event: MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleSignOut = () => {
        handleProfileMenuClose();
        logout();
    };

    return (
        <div className="header">
            {user &&
                (!isTrial ? (
                    isCometTheme || isAdminPanel ? (
                        <IconButton
                            edge="end"
                            aria-label="close"
                            className={`header-entry-point-button${isMobileDevice() ? " header-entry-point-button--mobile" : ""}`}
                            onClick={handleEntryPointClick}
                        >
                            <SvgIcon component={HomeIcon} inheritViewBox />
                        </IconButton>
                    ) : (
                        <></>
                    )
                ) : isAdminPanel ? (
                    <AdminTrialMenu className="header-menu-button" />
                ) : user.role !== "user" ? (
                    <AssistantTrialMenu className="header-menu-button" />
                ) : (
                    <></>
                ))}
            <img
                src={isCometTheme ? (isAdminPanel ? LogoCometAdmin : LogoComet) : LogoTiva}
                className="header-logo"
                alt=""
            />
            {user && (
                <div
                    className={`header-profile${isMobileDevice() ? " header-profile__mobile" : ""}`}
                >
                    <div className="header-profile-avatar" onClick={handleProfileMenuClick}>
                        {user.avatar ? (
                            <img
                                src={user.avatar}
                                alt=""
                                referrerPolicy="no-referrer"
                                className="header-profile-avatar_img"
                            ></img>
                        ) : (
                            <div className="header-profile-avatar_label">
                                {user.firstName?.toUpperCase().at(0) ??
                                    user.email.toUpperCase().at(0)}
                            </div>
                        )}
                    </div>
                    <Menu
                        id="basic-menu"
                        anchorEl={menuAnchor}
                        open={menuOpen}
                        onClose={handleProfileMenuClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button"
                        }}
                        slotProps={{
                            paper: {
                                elevation: 0,
                                className: "header-menu"
                            }
                        }}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                        <div className="header-menu-content">
                            <div className="header-menu-email">{user.email}</div>
                            <div className="header-menu-actions">
                                <Button
                                    className="logout-button"
                                    variant="outlined"
                                    onClick={handleSignOut}
                                >
                                    Sign Out
                                </Button>
                            </div>
                        </div>
                    </Menu>
                </div>
            )}
        </div>
    );
};

export default Header;
