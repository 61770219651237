import { APIHandler, EventSourceHandler } from "../base";
import { CreateRoomProps } from "../../common/interfaces/chats/create-room.interface";
import { AddQuestionProps } from "../../common/interfaces/chats/add-question.interface";
import { SummaryProps } from "../../common/interfaces/chats/summary.interface";
import { FeedbackProps } from "../../common/interfaces/chats/feedback.interface";

export const fetchRooms = (userId: string) =>
    APIHandler.get(`/chats/${userId}/rooms?pageSize=100&sortBy=createdAt&sortOrder=asc`);

export const createRoom = (userId: string, roomData: CreateRoomProps) =>
    APIHandler.post(`/chats/${userId}/room`, roomData);

export const archiveRoom = (userId: string, roomId: string) =>
    APIHandler.put(`/chats/${userId}/rooms/${roomId}/archivate`);

export const archiveAllRooms = (userId: string) =>
    APIHandler.put(`/chats/${userId}/rooms/archivateAll`);

export const fetchChatHistory = (userId: string, roomId: string) =>
    APIHandler.get(`/chats/${userId}/${roomId}/history?pageSize=100`);

export const addQuestion = (userId: string, roomId: string, questionData: AddQuestionProps) =>
    APIHandler.post(`/chats/sse/${userId}/${roomId}/question`, questionData);

export const summarizeVideo = (userId: string, roomId: string, summaryData: SummaryProps) =>
    APIHandler.post(`/chats/sse/${userId}/${roomId}/summarizeVideo`, summaryData);

export const listenAnswers = (userId: string, roomId: string) =>
    EventSourceHandler(`/chats/sse/${userId}/${roomId}/answers`);

export const feedback = (userId: string, roomId: string, feedbackData: FeedbackProps) =>
    APIHandler.post(`/chats/${userId}/${roomId}/feedback`, feedbackData);
