import { useState, ReactNode } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import "./style.scss";
import LogoTiva from "../../../assets/logoTiva.svg";
import LogoComet from "../../../assets/logoComet.svg";
import { Theme } from "../../../common/enums/theme.enum";
import { ThemeContainer } from "../../../common/containers/theme/theme.container";

export const AuthTemplate = ({
    children,
    width,
    height,
    title = "",
    description = "",
    showClose = false,
    handleClose
}: {
    children: ReactNode;
    width?: number;
    height?: number;
    title?: string;
    description?: string;
    showClose?: boolean;
    handleClose?: () => void;
}) => {
    const style = { ...(width ? { maxWidth: width } : {}), ...(height ? { height: height } : {}) };
    const { theme } = ThemeContainer.useContainer();
    const [isCometTheme] = useState<boolean>(theme === Theme.comet);

    return (
        <div className="auth-template">
            <div className="auth-template-header">
                {isCometTheme ? (
                    <img src={LogoComet} className="auth-template-header__logo" alt="" />
                ) : (
                    <>
                        <span className="auth-template-header__title">{title}</span>
                        <span className="auth-template-header__description">{description}</span>
                    </>
                )}
            </div>
            <div className="auth-template-body" style={style}>
                {showClose && (
                    <IconButton className="auth-template-body__button-close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                )}
                {children}
            </div>
            <div className="auth-template-footer">
                {!isCometTheme && (
                    <img src={LogoTiva} className="auth-template-footer__logo" alt="" />
                )}
            </div>
        </div>
    );
};
