import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./style.scss";
import { addEaseOutElastic } from "../../common/utilities/html-effects/html-effects";

const EntryPoint = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const knowledgebase = document.getElementById("knowledgebase");
        const tivaData = document.getElementById("tiva-data");
        const circle1 = document.getElementById("circle1");
        const circle2 = document.getElementById("circle2");
        const circle3 = document.getElementById("circle3");
        const circle4 = document.getElementById("circle4");
        const circle5 = document.getElementById("circle5");
        const circle6 = document.getElementById("circle6");
        if (knowledgebase) {
            addEaseOutElastic(knowledgebase, 10);
        }
        if (tivaData) {
            addEaseOutElastic(tivaData, 10);
        }
        if (circle1) {
            addEaseOutElastic(circle1, 3);
        }
        if (circle2) {
            addEaseOutElastic(circle2, 2);
        }
        if (circle3) {
            addEaseOutElastic(circle3, 5);
        }
        if (circle4) {
            addEaseOutElastic(circle4, 5);
        }
        if (circle5) {
            addEaseOutElastic(circle5, 5);
        }
        if (circle6) {
            addEaseOutElastic(circle6, 3);
        }
    }, []);

    const handleKnowledgebase = () => {
        navigate("/new-chat");
    };

    const handleDataAccess = () => {
        if (process.env.REACT_APP_TIVA_URL) {
            window.open(process.env.REACT_APP_TIVA_URL, "_self");
        }
    };

    return (
        <div className="entry-point">
            <div className="entry-point-content">
                <div
                    id="knowledgebase"
                    className="entry-point-circle__knowledgebase"
                    onClick={handleKnowledgebase}
                >
                    <span className="knowledgebase-title">TIVA Knowledge Base</span>
                    <span className="knowledgebase-description">
                        AI Assistance with Learning Management System
                    </span>
                </div>
                <div
                    id="tiva-data"
                    className="entry-point-circle__tiva-data"
                    onClick={handleDataAccess}
                >
                    <span className="tiva-data-title">TIVA Data</span>
                    <span className="tiva-data-description">
                        AI Assistance with PowerSchool Data
                    </span>
                </div>
                <div id="circle1" className="entry-point-circle__yellow"></div>
                <div id="circle2" className="entry-point-circle__green"></div>
                <div id="circle3" className="entry-point-circle__blue"></div>
                <div id="circle4" className="entry-point-circle__dark-blue"></div>
                <div id="circle5" className="entry-point-circle__grey-blue"></div>
                <div id="circle6" className="entry-point-circle__light-blue"></div>
            </div>
        </div>
    );
};

export default EntryPoint;
