import { Navigate, useLocation } from "react-router-dom";

import { FirebaseAuthContainer } from "../../../common/containers/auth/firebase-auth-container.util";
import { LoaderView } from "../../../components/loader/loader.comp";

const VerifiedProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const { isAuthenticated, emailVerified } = FirebaseAuthContainer.useContainer();
    const location = useLocation();

    return isAuthenticated() ? (
        emailVerified ? (
            children
        ) : emailVerified != null ? (
            <Navigate to="/email-confirm" state={{ from: location }} />
        ) : (
            <LoaderView show={true} />
        )
    ) : (
        <Navigate to="/login" state={{ from: location }} />
    );
};

export default VerifiedProtectedRoute;
